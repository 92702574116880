import React, { useMemo } from 'react'
import { Card, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useQuery } from 'react-query'
import bbitApi from '../../../../api/make-request'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import EmployeeEducationRepository from '@api/repositories/employee-education-repository'
import { RootState } from '../../../../store/store'
import { useParams } from 'react-router'
import { IOrganizationPosition } from '@app/interfaces/employee/organization-position'
import useCache from '@app/hooks/useCache'
import { ApiKeys } from '@app/constants/api-keys'

interface DataType {
  key: React.Key
  employeeEducationDictionaryId: number
  employeePositionDictionaryId: number
  employeeCount: number
  gender: 'MALE' | 'FEMALE'
}

const EmployeeStaffCard: React.FC = () => {
  const [t] = useTranslation()
  const languageId = useSelector((state: RootState) => state.langs.languageId)
  const { id: routeOrgId } = useParams()
  const { data: organizationPositions } = useCache<IOrganizationPosition>(
    ApiKeys.organizationPosition,
  )

  const { organizationId } = useSelector((store: any) => ({
    organizationId:
      store.userInfo.userInfo.organizations?.at(0)?.organizationId,
  }))

  const { data: employeesData, isLoading } = useQuery(
    'routes.indicators.components.employee-staff-card.employee-staff-card-employee-positions',
    async ({ signal }) => {
      const [{ data: empEducationDic }, { data: empPosDic }] =
        await Promise.all([
          EmployeeEducationRepository.getAll({}),
          bbitApi.get(
            `/employee/api/v1/employee/dashboard/${
              organizationId || routeOrgId
            }`,
            {
              signal,
            },
          ),
        ])

      return empPosDic.map((emp, idx) => ({
        key: idx,
        employeeCount: emp.employeeCount,
        gender: emp.gender,
        education: empEducationDic.find(
          (edu) => edu.id === emp.employeeEducationDictionaryId,
        )?.data,
        position: organizationPositions?.find(
          (empPos) =>
            empPos.employeePositionDictionaryId ===
            emp.employeePositionDictionaryId,
        )?.employeePositionDictionary.data,
      }))
    },
    {
      enabled: [
        !!organizationId || !!routeOrgId,
        !!organizationPositions,
      ].every((item) => item),
      retry: false,
    },
  )

  const columns = useMemo(
    (): ColumnsType<DataType> => [
      {
        title: t('employee.position'),
        width: 50,
        key: 'position',
        dataIndex: 'position',
        render: (locales) => (
          <>{locales?.find((l) => l.languageId === languageId)?.name}</>
        ),
      },
      {
        title: t('employeeFields.gender'),
        width: 50,
        key: 'gender',
        dataIndex: 'gender',
        render: (gender) => t(`pupils.${gender.toLowerCase()}`),
      },
      {
        title: t('employeeFields.education'),
        width: 50,
        key: 'education',
        dataIndex: 'education',
        render: (locales) => (
          <>{locales?.find((d) => d.languageId === languageId)?.name}</>
        ),
      },

      {
        title: t('general.totalCount'),
        width: 50,
        key: 'employeeCount',
        dataIndex: 'employeeCount',
      },
    ],
    [t],
  )

  return (
    <Card title={t('employee.title')} bordered={false}>
      <Table
        pagination={false}
        columns={columns}
        dataSource={employeesData}
        bordered
        size="middle"
        scroll={{ y: 240 }}
        loading={isLoading}
      />
    </Card>
  )
}

export default EmployeeStaffCard
