import bbitApi from '@app/api/make-request'
import { INeighborhood } from '@app/interfaces/neighborhood/neighborhood.types'
import FormList from '@app/pages/reference-book-page-details/components/form-list/form-list'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import useRegionDestricts from '@app/shared/use-region-districts/use-region-districts'

import { Button, Form, Input, InputNumber, Modal, Spin, Switch } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

interface props {
  neighborhood?: INeighborhood
  edit?: boolean
}

const NeighborhoodForm: React.FC<props> = ({ neighborhood }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { District, Region } = useRegionDestricts()
  const { t } = useTranslation()
  const { isLoading, mutate } = useMutation((values: INeighborhood) =>
    neighborhood
      ? bbitApi.put(
          `/dictionary/api/v1/neighborhood/${neighborhood!.id}`,
          values,
        )
      : bbitApi.post('/dictionary/api/v1/neighborhood', values),
  )

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const onFinish = (values: INeighborhood) => {
    const newValues = {
      id: neighborhood?.id,
      sorting: values.sorting,
      isRuralArea: !!values.isRuralArea,
      districtId: values.districtId || neighborhood?.districtId,
      status: values.status,
      data: values?.data.filter((language) => language && language.name),
    } as INeighborhood
    mutate(newValues, {
      onSuccess() {
        hideModal()
        openNotificationWithIcon(
          'success',
          t(neighborhood ? 'success.updated' : 'success.created'),
        )
      },
      onError() {
        openNotificationWithIcon(
          'error',
          t(
            neighborhood
              ? 'neighborhood.editNeighborhood'
              : 'neighborhood.addNeighborhood',
          ),
        )
      },
    })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} type={'primary'}>
        {neighborhood ? (
          <i className="far fa-edit"> {t('general.edit')} </i>
        ) : (
          t('general.add')
        )}
      </Button>

      <Modal
        open={visible}
        title={
          neighborhood
            ? t('district.editDistrict')
            : t('district.addDistrict')
        }
        onCancel={hideModal}
        destroyOnClose={true}
        confirmLoading={isLoading}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={isLoading}
              edit={!!neighborhood}
              onCancel={hideModal}
              onSubmit={form.submit}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              id: neighborhood?.id,
              sorting: neighborhood?.sorting || 0,
              isRuralArea: neighborhood?.isRuralArea,
              status: neighborhood ? neighborhood?.status : true,
              districtId: neighborhood?.districtId,
            }}
            layout={'vertical'}
          >
            {!neighborhood && (
              <>
                <Form.Item name={'regionId'} label={t('region.region')}>
                  {Region}
                </Form.Item>
                <Form.Item name={'districtId'} label={t('district.title')}>
                  {District}
                </Form.Item>
              </>
            )}
            <Form.Item hidden={true} name={'id'}>
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: 0,
                  max: 32768,
                  message: t('errors.minPriorityMessage'),
                },
              ]}
              label={t('general.sort')}
              name={'sorting'}
            >
              <InputNumber disabled={isLoading} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name={'isRuralArea'}
              label={t('general.isRuralArea')}
              valuePropName="checked"
            >
              <Switch disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name={'status'}
              label={t('general.status')}
              valuePropName="checked"
            >
              <Switch disabled={isLoading} />
            </Form.Item>
            <FormList confirmLoading={isLoading} object={neighborhood} />
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}

export default NeighborhoodForm
