import moment from 'moment'

export const formatString = (value: string | number | undefined | null) =>
  ['string', 'number'].includes(typeof value) ? value : ''

export function splitTimeAndDate(value: string | null): {
  time: string
  date: string
} {
  if (!value) {
    return {
      date: '',
      time: '',
    }
  } else {
    try {
      const date = moment(value.split('T')[0])
      return {
        date: date.format('DD.MM.YYYY'),
        time: value.split('T')[1],
      }
    } catch (error) {
      return {
        date: '',
        time: '',
      }
    }
  }
}
