import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import usePagination from '@app/hooks/usePagination'
import OrganizationsFilterForm from '@app/pages/organizations/components/organization-filter-form/organization-filter-form'
import Pagination from '@app/shared/pagination/pagination'
import { clearOrganization } from '@app/store/actions/organization-actions'
import { useGetOrganizationsQuery } from '@app/store/rtk/api/organization/organization-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Space, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CheckboxAction } from './components/organization-gen-status'
import './organizations.less'

const permissions = [
  AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
  AUTHORITIES.ADMIN,
]

const DEFAULT_QUERY = `page=0&size=10&typeId.equals=2&statusId.equals=1`

const OrganizationsPage: React.FC = () => {
  const { searchParams, requestFunction, handleTableChange } =
    usePagination(DEFAULT_QUERY)
  const { data: organizations, isLoading } = useGetOrganizationsQuery(
    searchParams.toString(),
  )
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { localizeData } = useLocalization()
  setDocumentTitle(t('organization.title'))

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text, record: { id: any }) => (
        <Link
          className={'ant-btn ant-btn-link'}
          to={`/organization/${record.id}/page`}
        >
          {text}
        </Link>
      ),
    },

    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record: { type: string; id: any }) => (
        <>
          <small>
            <sup>
              {record.type === 'GOVERNMENT_AGENCY'
                ? t('organization.government-agency')
                : t('organization.doo')}
            </sup>
          </small>
          <Link
            className={'ant-btn ant-btn-link'}
            to={`/organization/${record.id}/indicators`}
          >
            {text}
          </Link>
        </>
      ),
    },
    {
      title: t('institutionType.institutionType'),
      dataIndex: 'institutionType',
      key: 'institutionType',
    },
    {
      title: t('institutionSubType.title'),
      dataIndex: 'institutionSubType',
      key: 'institutionSubType',
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('region.region'),
      dataIndex: 'region',
      key: 'region',
      sorter: true,
    },
    {
      title: t('district.district'),
      dataIndex: 'district',
      key: 'district',
      sorter: true,
    },
    {
      title: t('general.edit'),
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Link
            className={'ant-btn ant-btn-link'}
            to={`/organization/${record.id}/edit`}
          >
            <EditOutlined />
            <span>{t('general.edit')}</span>
          </Link>
        </Space>
      ),
    },
    {
      title: t('general.auto_status'),
      key: 'at',
      render: (record: any) => (
        <CheckboxAction id={record.id} emisApiStatus={record?.emisApiStatus} />
      ),
    },
  ]

  const data = organizations?.response?.length
    ? organizations.response.map((organization) => {
        return {
          key: organization.id,
          id: organization.id,
          name: organization.name,
          type: organization.type.type,
          region: localizeData(organization.region?.data)?.name,
          district: localizeData(organization.district?.data)?.name,
          institutionType: localizeData(
            organization.passport?.institutionType?.data,
          )?.name,
          institutionSubType: localizeData(
            organization.institutionSubType?.data,
          )?.name,
          status: localizeData(organization.status?.data)?.name,
          emisApiStatus: organization?.emisApiStatus,
        }
      })
    : []
  return (
    <>
      <div className="header-form">
        <OrganizationsFilterForm
          request={requestFunction}
          searchParams={searchParams}
        />
      </div>
      <Card
        title={t('organization.title')}
        extra={
          <>
            {rolesChecker(permissions) ? (
              <Link
                to={`../institution/add`}
                className={' ant-btn ant-btn-default'}
                type={'primary'}
                replace={true}
                onClick={() => {
                  dispatch(clearOrganization())
                }}
              >
                <PlusOutlined />
                {t('organization.addInst')}
              </Link>
            ) : null}
            {rolesChecker(permissions) ? (
              <Link
                to={`../gov-agency/add`}
                className={'ant-btn ant-btn-default'}
                style={{ marginLeft: '15px' }}
                type={'primary'}
                replace={true}
                onClick={() => {
                  dispatch(clearOrganization())
                }}
              >
                <PlusOutlined />
                {t('organization.addGAgency')}
              </Link>
            ) : null}
          </>
        }
      >
        <Table
          pagination={false}
          onChange={handleTableChange}
          dataSource={data}
          loading={isLoading}
          rowClassName="table-row"
          columns={columns}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={organizations?.meta['x-total-count'] ?? 0}
              searchParams={searchParams}
              isLegacy={false}
            />
          )}
        />
      </Card>
    </>
  )
}

export default OrganizationsPage
