import organizationMobileUsersRepository from '@api/repositories/organization-mobile-users-repository'
import { optimisticUpdateMobileUsersState } from '@app/store/actions/organization-mobile-users-actions'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface AlertRemoveMobileUserProps {
  visible: boolean
  onCancel: () => void
  userId: string
}

export const AlertRemoveMobileUser: React.FC<AlertRemoveMobileUserProps> = ({
  visible,
  onCancel,
  userId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSuccess = () => {
    dispatch(optimisticUpdateMobileUsersState('delete', userId))
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)
      await organizationMobileUsersRepository.remove({ data: userId })
      onSuccess()
      onCancel()
    } catch (error) {
      console.error('Error removing mobile user:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      title={t('general.delete')}
      onCancel={onCancel}
      onOk={handleConfirm}
      okText={t('general.delete')}
      cancelText={t('general.cancel')}
      confirmLoading={loading}>
      <p>{t('mobileUsers.deleteConfirm')}</p>
    </Modal>
  )
}
