import organizationMobileUsersRepository from '@api/repositories/organization-mobile-users-repository'
import { ICreateOrganizationMobileUserBody } from '@app/interfaces/organization-mobile-users'
import { optimisticUpdateMobileUsersState } from '@app/store/actions/organization-mobile-users-actions'
import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface AddMobileUserProps {
  visible: boolean
  onCancel: () => void
  currentOrganizationId: number
}

export const AddMobileUser: React.FC<AddMobileUserProps> = ({
  visible,
  onCancel,
  currentOrganizationId,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSuccess = (newUserId: string) => {
    dispatch(
      optimisticUpdateMobileUsersState('add', {
        fullName: form.getFieldValue('fullName'),
        username: form.getFieldValue('username'),
        organizationId: currentOrganizationId,
        id: newUserId,
      }),
    )
  }

  const handleSubmit = async (values: ICreateOrganizationMobileUserBody) => {
    try {
      setLoading(true)
      const response = await organizationMobileUsersRepository.create({
        ...values,
        organizationId: currentOrganizationId,
      })
      onSuccess(response.data.data.id)
      form.resetFields()
      onCancel()
    } catch (error) {
      console.error('Error creating mobile user:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      title={t('mobileUsers.add_user')}
      onCancel={onCancel}
      footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="fullName"
          label={t('mobileUsers.fullName')}
          rules={[{ required: true, message: t('errors.requiredMessage') }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="username"
          label={t('mobileUsers.login')}
          rules={[
            { required: true, message: t('errors.requiredMessage') },
            {
              min: 4,
              max: 255,
              message: t('errors.minMaxMessage'),
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label={t('mobileUsers.password')}
          rules={[
            { required: true, message: t('errors.requiredMessage') },
            {
              min: 4,
              max: 255,
              message: t('errors.minMaxMessage'),
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item className="text-right">
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            {t('general.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('general.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
