import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import useCache from '@app/hooks/useCache'
import useCreateSelector from '@app/hooks/useCreateSelector'
import { useGetAllQuarters } from '@app/hooks/useGetAllQuarters'
import useLocalization from '@app/hooks/useLocalization'
import { IGrowthSelectItem, TGender } from '@app/interfaces/organization-growth'
import { IDistrict } from '@app/interfaces/organization/districts'
import { IRegion } from '@app/interfaces/organization/region'
import { useGetEducationGroupsQuery } from '@app/store/rtk/api/education/education-group-api'
import { useGetOrganizationPupilsQuery } from '@app/store/rtk/api/organization-pupil-api'
import { useGetOrganizationsQuery } from '@app/store/rtk/api/organization/organization-api'
import { findById } from '@app/utils/find-by-id/find-by-id'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, FormInstance, Select, Spin } from 'antd'
import i18next from 'i18next'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

interface IProps {
  form: FormInstance<any>
  setGender: Dispatch<SetStateAction<TGender>>
}

const genders: IGrowthSelectItem[] = [
  {
    id: 1,
    title: i18next.t('general.male'),
    query: 'MALE',
  },
  {
    id: 2,
    title: i18next.t('general.female'),
    query: 'FEMALE',
  },
]

const GrowthStatisticsFilters: React.FC<IProps> = ({ form, setGender }) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const [searchParams, setSearchParams] = useSearchParams('')
  const [regionId, setRegionId] = useState<number>(0)
  const [organizationId, setOrganizationId] = useState(null)
  const [groupId, setGroupId] = useState<number>()
  const { selectors } = useCreateSelector()
  const userInfo = useSelector(selectors.userInfo.userInfo)
  const { allQuarters } = useGetAllQuarters()
  const { data: regions } = useCache<IRegion>(ApiKeys.region)
  const { data: districts, isLoading } = useCache<IDistrict>(ApiKeys.district)
  const { data: organizations } = useGetOrganizationsQuery(
    searchParams.toString(),
    { skip: !searchParams.has('districtId.equals') },
  )
  const { data: groups } = useGetEducationGroupsQuery(
    `page=0&size=100&organizationId.equals=${organizationId}&status.equals=OPEN`,
    {
      skip: !organizationId,
    },
  )
  const { data: pupils } = useGetOrganizationPupilsQuery(
    `page=0&size=200&educationGroupId.equals=${groupId}&status.in=APPROVED`,
    {
      skip: !groupId,
    },
  )

  useEffect(() => {
    rolesChecker([
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ]) &&
      !isEmpty(userInfo.organizations) &&
      setRegionId(userInfo.organizations[0].regionId)
    if (rolesChecker([AUTHORITIES.ROLE_DISTRICT_IKT])) {
      setSearchParams(
        `page=0&size=500&regionId.equals=${
          userInfo.organizations[0].regionId
        }&districtId.equals=${
          userInfo.organizations[0].districtId
        }&statusId.equals=${1}`,
      )
    }
  }, [])

  const onRegionChangeHandler = (value) => {
    setRegionId(value)
    form.setFieldsValue({ districtId: undefined })
    form.setFieldsValue({ organizationId: undefined })
    form.setFieldsValue({ groupId: undefined })
    form.setFieldsValue({ pupilId: undefined })
  }

  const onDistrictChangeHandler = (value) => {
    setSearchParams(
      `page=0&size=500&regionId.equals=${regionId}&districtId.equals=${value}&statusId.equals=${1}`,
    )
    form.setFieldsValue({ organizationId: undefined })
    form.setFieldsValue({ groupId: undefined })
    form.setFieldsValue({ pupilId: undefined })
  }

  const onOrganizationChangeHandler = (value) => {
    setOrganizationId(value)
    form.setFieldsValue({ groupId: undefined })
    form.setFieldsValue({ pupilId: undefined })
  }

  const onGroupChangeHandler = (value) => {
    setGroupId(value)
    form.setFieldsValue({ pupilId: undefined })
  }

  const getRegionName = (regionId) => {
    return findById(regions, regionId)?.data.name
  }

  const findDistricts = (id) => {
    return isEmpty(districts)
      ? []
      : districts.filter((district) => district.regionId === id)
  }

  const onGenderChange = (value) => {
    setGender(value)
  }
  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout={'vertical'}>
        <div className="warehouse__filters--row">
          <Form.Item
            name={'regionId'}
            label={t('region.region')}
            style={{ width: 100 }}
            initialValue={
              rolesChecker([
                AUTHORITIES.ROLE_REGION_IKT,
                AUTHORITIES.ROLE_DISTRICT_IKT,
              ]) &&
              !isEmpty(userInfo.organizations) &&
              userInfo.organizations[0].regionId
            }
          >
            <Select
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_ALL_VIEW,
                ])
              }
              showSearch={true}
              onChange={onRegionChangeHandler}
            >
              {sortByName(localize(regions))?.map(({ data: { name }, id }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'districtId'}
            label={t('district.district')}
            initialValue={
              rolesChecker([AUTHORITIES.ROLE_DISTRICT_IKT]) &&
              !isEmpty(userInfo.organizations) &&
              userInfo.organizations[0].districtId
            }
          >
            <Select
              showSearch={true}
              disabled={
                !rolesChecker([
                  AUTHORITIES.ADMIN,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_ALL_VIEW,
                  AUTHORITIES.ROLE_MONITOR_ATTENDANCE_REGION_VIEW,
                ]) || !form.getFieldValue('regionId')
              }
              onChange={onDistrictChangeHandler}
            >
              {sortByName(localize(findDistricts(regionId)))
                ?.filter((district) => {
                  return district.data.name !== getRegionName(regionId)
                })
                .map((district) => {
                  return (
                    <Select.Option key={district.id} value={district.id}>
                      {district.data.name}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'organizationId'}
            label={t('organization.title')}
          >
            <Select
              showSearch={true}
              onChange={onOrganizationChangeHandler}
              disabled={!form.getFieldValue('districtId')}
            >
              {organizations?.response?.map((organization) => {
                return (
                  <Select.Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'groupId'}
            label={t('educationGroup.title')}
          >
            <Select
              onChange={onGroupChangeHandler}
              showSearch={true}
              disabled={!form.getFieldValue('organizationId')}
            >
              {groups?.response?.map((group) => {
                return (
                  <Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'asdasdasdasd'}
            label={t('pupils.pupil')}
          >
            <Select showSearch={true} disabled={!form.getFieldValue('groupId')}>
              {pupils?.response?.map(({ pupil }) => {
                return (
                  <Select.Option key={pupil.id} value={pupil.id}>
                    {pupil.fullName}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'gender'}
            initialValue={genders[0].query}
            label={t('employeeFields.gender')}
          >
            <Select showSearch={true} onChange={onGenderChange}>
              {genders.map((gender) => {
                return (
                  <Select.Option key={gender.id} value={gender.query}>
                    {gender.title}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 100 }}
            name={'quarterId'}
            label={t('general.quarter')}
          >
            <Select>
              {localize(allQuarters || []).map(({ id, data: { name } }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  )
}

export default GrowthStatisticsFilters
