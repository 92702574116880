import Employee from '@app/api/repositories/employee-repository'
import { DATE_FORMAT } from '@app/constants/date'
import useLocalization from '@app/hooks/useLocalization'
import { IEducationDegree } from '@app/interfaces/education/education-degree'
import {
  EducationDictionary,
  EmployeeEducation,
  IEmployee,
} from '@app/interfaces/employee/employee'
import { IEmployeeEducation } from '@app/interfaces/employee/employee-education'
import { IOrganizationPosition } from '@app/interfaces/employee/organization-position'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { getEducationDegrees } from '@app/store/actions/education-degree-actions'
import { fetchEmployeeSuccess } from '@app/store/actions/employee-actions'
import { getEmployeeEducations } from '@app/store/actions/employee-education-actions'
import { getOrganizationPosition } from '@app/store/actions/organization-position-actions'
import { EmployeeType } from '@app/store/reducers/employee-reducer'
import { RootState } from '@app/store/store'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { preLoadFile } from '@app/store/actions/files-actions'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { useForm } from 'antd/lib/form/Form'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { uploadFile } from '@app/api/rest/upload-file'
import AddEmployeePhotoItem from './add-employee-photo-item'
import { getFile } from '@app/api/rest/getFile'

interface params {
  id?: string
  posId?: string
}

interface props {
  _educationDegrees: IEducationDegree[]
  _employeeEducations: IEmployeeEducation[]
  _organizationPosition: IOrganizationPosition
  employeeType: EmployeeType
  reset: () => void
  params: params
  pin: string
  pending: boolean
}

const AddEmployeeWithPositionForm: React.FC<props> = ({
  _educationDegrees,
  _employeeEducations,
  _organizationPosition,
  employeeType,
  params,
  pin,
  reset,
  pending,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { localize, localizeData } = useLocalization()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [form] = useForm()
  const employee =
    employeeType === EmployeeType.EMPLOYEE_MVD
      ? useSelector(
          (state: RootState) => state.organizationEmployee.employeeMvd,
        )
      : useSelector(
          (state: RootState) => state.organizationEmployee.employeeUser,
        )

  useEffect(() => {
    if (isEmpty(_educationDegrees))
      dispatch(getEducationDegrees({}, t('educationLanguage.error')))
    if (isEmpty(_employeeEducations))
      dispatch(
        getEmployeeEducations({}, t('errors.dictionary.employeeEducation')),
      )
    dispatch(
      getOrganizationPosition(
        { data: Number(params!.posId) },
        t('employeeEducation.error'),
      ),
    )
  }, [])

  const onFinish = (values: any) => {
    const degreeEducation = _educationDegrees.length
      ? _educationDegrees.find((degree) => degree.id === values.degreeEducation)
      : undefined
    const newValues: IEmployee = {
      ...values,
      degreeEducation: degreeEducation,
      id: null,
      orderDate: values['orderDate'].format(DATE_FORMAT),
      organizationPositionId: Number(params.posId),
      education: null,
    }
    if (values.educationId != null && values.educationId !== '') {
      const education = new EmployeeEducation()
      education.educationDictionary = new EducationDictionary(
        values.educationId,
      )
      education.specialty = values.specialty
      education.placeStudy = values.placeStudy
      newValues.education = education
    }

    setConfirmLoading(true)
    Employee.createEmployeeWithOrganizationPosition(newValues)
      .then((response) => {
        dispatch(fetchEmployeeSuccess(response.data))
        setConfirmLoading(false)
        navigate(
          `/organization/${params.id}/employees/employee/${response.data.id}`,
        )
        openNotificationWithIcon('success', t('employee.successAssign'))
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  const genders = [
    {
      key: 'MALE',
      value: t('general.male'),
    },
    {
      key: 'FEMALE',
      value: t('general.female'),
    },
  ]

  const position = !isEmpty(_organizationPosition)
    ? localizeData(_organizationPosition.employeePositionDictionary.data)?.name
    : ''

  const initialValues = useMemo(() => {
    // TODO need to optimize later
    return {
      ...(employee.firstName ? { firstName: employee.firstName } : {}),
      ...(employee.lastName ? { lastName: employee.lastName } : {}),
      ...(employee.middleName ? { middleName: employee.middleName } : {}),
      ...(employee.passportNumber
        ? { passportNumber: employee.passportNumber }
        : {}),
      ...(employee.tin ? { tin: employee.tin } : {}),
      ...(employee.phone ? { phone: employee.phone } : {}),
      ...(employee.email ? { email: employee.email } : {}),
      ...(employee.address ? { address: employee.address } : {}),
      ...(employee.id ? { id: employee.id } : {}),
      ...(employee.gender
        ? {
            gender: employee.gender ?? undefined,
          }
        : {}),

      pin: pin,
    }
  }, [employee])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [initialValues])

  function base64ToFile(
    base64String: string,
    filename: string,
    mimeType: string,
  ) {
    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new File([byteArray], filename, { type: mimeType })
  }

  useEffect(() => {
    if (employeeType === EmployeeType.EMPLOYEE_MVD && employee.photo) {
      // eslint-disable-next-line no-inner-declarations
      async function fn() {
        const file = base64ToFile(employee.photo, 'photo.jpeg', 'image/jpeg')
        uploadFile(file).then((res) => {
          form.setFieldValue('fileId', res.data.id)
          dispatch(preLoadFile(res.data))
        })
      }
      fn()
    } else if (employee.fileId) {
      // eslint-disable-next-line no-inner-declarations
      async function fn() {
        const res = await getFile(employee.fileId)
        form.setFieldValue('fileId', res.data.id)
        dispatch(preLoadFile(res.data))
      }
      fn()
    }
  }, [employee, employeeType])

  const onUploadImage = (id) => {
    form.setFieldValue('fileId', id)
  }

  return (
    <>
      <Card
        title={`${t(
          'employeePosition.assignAnEmployeeToPosition',
        )} ${position}`}>
        <Spin spinning={confirmLoading || pending}>
          <Form
            className={'employee-add-form'}
            onFinish={onFinish}
            layout={'vertical'}
            form={form}
            initialValues={initialValues}>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item label={t('users.avatar')} name={'fileId'}>
                  <AddEmployeePhotoItem
                    upload
                    onSubmit={onUploadImage}
                    isLoading={setConfirmLoading}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('employeeFields.firstName')}
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  name={'firstName'}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.lastName')}
                  name={'lastName'}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.middleName')}
                  name={'middleName'}>
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.passportNumber')}
                  name={'passportNumber'}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.pin')}
                  name={'pin'}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.tin')}
                  name={'tin'}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.phone')}
                  name={'phone'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.email')}
                  name={'email'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.address')}
                  name={'address'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('employeeEducation.employeeEducation')}
                  name={'educationId'}>
                  <Select allowClear={true}>
                    {sortByName(localize(_employeeEducations)).map((e) =>
                      e.status ? (
                        <Select.Option key={e.id} value={e.id}>
                          {e.data?.name}
                        </Select.Option>
                      ) : null,
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.placeStudy')}
                  name={'placeStudy'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                    },
                  ]}
                  label={t('employeeFields.specialty')}
                  name={'specialty'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t('educationDegree.educationDegree')}
                  name={'degreeEducation'}>
                  <Select allowClear={true}>
                    {sortByName(localize(_educationDegrees)).map((degree) =>
                      degree.status ? (
                        <Select.Option key={degree.id} value={degree.id}>
                          {degree.data?.name}
                        </Select.Option>
                      ) : null,
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      transform: (value) =>
                        typeof value === 'string' ? value.trim() : undefined,
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.orderNo')}
                  name={'orderNo'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.orderDate')}
                  name={'orderDate'}>
                  <DatePicker
                    style={{ width: '100%' }}
                    locale={locale}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('errors.requiredMessage'),
                    },
                  ]}
                  label={t('employeeFields.gender')}
                  name={'gender'}>
                  <Radio.Group>
                    {genders.map((gender, index) => (
                      <Radio key={index} value={gender.key}>
                        {gender.value}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type={'primary'}
                disabled={confirmLoading}
                htmlType={'submit'}>
                {t('employee.add')}
              </Button>
              <Button
                type={'primary'}
                onClick={reset}
                style={{ marginLeft: '10px' }}
                disabled={confirmLoading}>
                {t('general.cancel')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    _employeeEducations: state.employeeEducation.employeeEducations,
    _educationDegrees: state.educationDegrees.educationDegrees,
    _organizationPosition: state.organizationPositions.organizationPosition,
  }
}

export default connect(mapStateToProps)(AddEmployeeWithPositionForm)
