import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  IRepository,
  numberArgs,
} from '@api/repositories/i-repository'
import {
  IOrganizationSector,
  IOrganizationSectorUpdate,
} from '@app/interfaces/organization/organization-sector'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import {
  IOrganizationSectorCreate,
  IOrganizationSectorPartialUpdate,
} from '../../interfaces/organization/organization-sector'

class OrganizationSectorRepository implements IRepository<IOrganizationSector> {
  create(
    data: IOrganizationSectorCreate,
  ): Promise<AxiosResponse<IOrganizationSector>> {
    return bbitApi.post('/dictionary/api/v1/organization-sector', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IOrganizationSector[]>> {
    return bbitApi.get('/dictionary/api/v1/organization-sector', {
      cancelToken: args.source?.token,
      params: resolveParams(args),
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationSector>> {
    return bbitApi.get(`/dictionary/api/v1/organization-sector/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(
    data: IOrganizationSectorUpdate,
  ): Promise<AxiosResponse<IOrganizationSector>> {
    return bbitApi.put(
      `/dictionary/api/v1/organization-sector/${data.id}`,
      data,
    )
  }

  partialUpdate(
    data: IOrganizationSectorPartialUpdate,
  ): Promise<AxiosResponse<IOrganizationSector>> {
    return bbitApi.put(
      `/dictionary/api/v1/organization-sector/${data.id}`,
      data,
    )
  }
}

export default new OrganizationSectorRepository()
