import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './reference-book-page.less'

const ReferenceBookPage: React.FC = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('general.referencePageTitle'))

  const onLinkClickHandler = () => {
    window.scrollTo(0, 0)
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (text, record) => <div style={{ width: '50px' }}>{text}</div>,
    },
    {
      title: t('general.name'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Link
          onClick={onLinkClickHandler}
          className={'reference__link'}
          to={record.path}
        >
          {text}
        </Link>
      ),
    },
  ]
  const organizationDetails = [
    {
      id: 1,
      title: t('region.region'),
      path: '/reference_book/regions',
    },
    {
      id: 2,
      title: t('district.title'),
      path: '/reference_book/districts',
    },
    {
      id: 3,
      title: t('neighborhood.title'),
      path: '/reference_book/neighborhood',
    },
    {
      id: 4,
      title: t('organizationType.title'),
      path: '/reference_book/organization_types',
    },
    {
      id: 5,
      title: t('institutionType.title'),
      path: '/reference_book/institutions-types',
    },
    {
      id: 6,
      title: t('ownership.title'),
      path: '/reference_book/ownerships',
    },
    {
      id: 7,
      title: t('organizationStatus.title'),
      path: '/reference_book/organization-statuses',
    },
    {
      id: 8,
      title: t('organizationLocality.title'),
      path: '/reference_book/organization-locality',
    },
  ]

  const groupDetails = [
    {
      id: 1,
      title: t('educationGrade.title'),
      path: '/reference_book/education-grades',
    },
    {
      id: 2,
      title: t('institutionSchedule.title'),
      path: '/reference_book/institution-schedules',
    },
    {
      id: 3,
      title: t('childrenStayDuration.title'),
      path: '/reference_book/stay-duration',
    },
  ]

  const employeeDetails = [
    {
      id: 1,
      title: t('employeePosition.title'),
      path: '/reference_book/employee-positions',
    },
    {
      id: 2,
      title: t('employeeRate.title'),
      path: '/reference_book/employee-rates',
    },
    {
      id: 3,
      title: t('employeeStatus.title'),
      path: '/reference_book/employee-statuses',
    },
    {
      id: 4,
      title: t('employeeEducation.title'),
      path: '/reference_book/employee-educations',
    },
    {
      id: 5,
      title: t('educationDegree.title'),
      path: '/reference_book/education_degree',
    },
  ]

  const otherDetails = [
    {
      id: 1,
      title: t('language.title'),
      path: '/reference_book/languages',
    },
    {
      id: 2,
      title: t('educationLanguage.title'),
      path: '/reference_book/education-languages',
    },
    {
      id: 3,
      title: t('nationality.title'),
      path: '/reference_book/nationalities',
    },
    {
      id: 4,
      title: t('holiday.title'),
      path: '/reference_book/holidays',
    },
    {
      id: 5,
      title: t('reason.title'),
      path: '/reference_book/reasons',
    },
    {
      id: 6,
      title: t('pupils.benefitType'),
      path: '/reference_book/benefit_type',
    },
  ]

  // const institutionPassportReferences = [
  //   {
  //     id: 4,
  //     title: t('medicalStaffAvailability.title'),
  //     path: '/reference_book/medical-staff',
  //   },
  //   {
  //     id: 5,
  //     title: t('drinkingWaterSource.title'),
  //     path: '/reference_book/water-sources',
  //   },
  //   {
  //     id: 6,
  //     title: t('drinkingWaterAvailable.title'),
  //     path: '/reference_book/drinking-water-available-during-year',
  //   },
  //   {
  //     id: 7,
  //     title: t('drinkingWaterTreatment.title'),
  //     path: '/reference_book/water-cleaning-methods',
  //   },
  //   {
  //     id: 8,
  //     title: t('toiletType.title'),
  //     path: '/reference_book/toilet-types',
  //   },
  //   {
  //     id: 9,
  //     title: t('toiletLocation.title'),
  //     path: '/reference_book/toilet-locations',
  //   },
  //   {
  //     id: 10,
  //     title: t('toiletCleaning.title'),
  //     path: '/reference_book/toilet-cleanings',
  //   },
  //   {
  //     id: 11,
  //     title: t('solidWasteRemoving.title'),
  //     path: '/reference_book/solid-waste-removings',
  //   },
  //   {
  //     id: 12,
  //     title: t('hotWaterSource.title'),
  //     path: '/reference_book/hot-water-sources',
  //   },
  //   {
  //     id: 13,
  //     title: t('heatingSystemSource.title'),
  //     path: '/reference_book/heating-system-sources',
  //   },
  //   {
  //     id: 14,
  //     title: t('electricity.title'),
  //     path: '/reference_book/electricitys',
  //   },
  //   {
  //     id: 15,
  //     title: t('foodPreparingPlace.title'),
  //     path: '/reference_book/food-preparing-places',
  //   },
  // ]

  const organizationData = organizationDetails.map((data) => {
    return {
      key: data.id,
      id: data.id,
      title: data.title,
      path: data.path,
    }
  })
  const groupData = groupDetails.map((data) => {
    return {
      key: data.id,
      id: data.id,
      title: data.title,
      path: data.path,
    }
  })

  const employeeData = employeeDetails.map((data) => {
    return {
      key: data.id,
      id: data.id,
      title: data.title,
      path: data.path,
    }
  })

  const otherData = otherDetails.map((data) => {
    return {
      key: data.id,
      id: data.id,
      title: data.title,
      path: data.path,
    }
  })

  return (
    <>
      <Card title={t('organization.title')}>
        <Table
          columns={columns}
          pagination={false}
          dataSource={organizationData}
        />
      </Card>
      <Card style={{ marginTop: '20px' }} title={t('educationGroup.title')}>
        <Table columns={columns} pagination={false} dataSource={groupData} />
      </Card>
      <Card style={{ marginTop: '20px' }} title={t('employee.title')}>
        <Table columns={columns} pagination={false} dataSource={employeeData} />
      </Card>
      <Card style={{ marginTop: '20px' }} title={t('general.other')}>
        <Table columns={columns} pagination={false} dataSource={otherData} />
      </Card>
    </>
  )
}

export default ReferenceBookPage
