import { Card, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import bbitApi from '../../../../api/make-request'

interface DataType {
  key: React.Key
  birthDate: number
  approvedMaleCount: number
  approvedFemaleCount: number
  waitingMaleCount: number
  waitingFemaleCount: number
}

const DashboardPupils: React.FC = () => {
  const [t] = useTranslation()
  const { id: routeOrgId } = useParams()
  const { data, isLoading } = useQuery<DataType[]>(
    'indicators-pupels',
    async () => {
      const res = await bbitApi.get(
        `education/api/v1/pupil-education/dashboard/${routeOrgId}`,
      )
      return res.data.map((d: any) => ({ ...d, key: d.birthDate }))
    },
    { enabled: !!routeOrgId, retry: false },
  )

  const columns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: t('admin.pupilReject.birthDate'),
        width: 50,
        key: 'birthDate',
        dataIndex: 'birthDate',
      },
      {
        title: t('reports.approved'),
        children: [
          {
            title: t('organizationAttendance.table.boys'),
            dataIndex: 'approvedMaleCount',
            key: 'approvedMaleCount',
            width: 50,
            sorter: (a, b) => a.approvedMaleCount - b.approvedMaleCount,
          },
          {
            title: t('organizationAttendance.table.girls'),
            dataIndex: 'approvedFemaleCount',
            key: 'approvedFemaleCount',
            width: 50,
            sorter: (a, b) => a.approvedFemaleCount - b.approvedFemaleCount,
          },
        ],
      },
      {
        title: t('reports.waiting'),
        children: [
          {
            title: t('general.male'),
            dataIndex: 'waitingMaleCount',
            key: 'waitingMaleCount',
            width: 50,
            sorter: (a, b) => a.waitingMaleCount - b.waitingMaleCount,
          },
          {
            title: t('general.female'),
            dataIndex: 'waitingFemaleCount',
            key: 'waitingFemaleCount',
            width: 50,
            sorter: (a, b) => a.waitingFemaleCount - b.waitingFemaleCount,
          },
        ],
      },
    ],
    [t],
  )

  return (
    <Card title={t('pupils.title')} bordered={false}>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        bordered
        size="middle"
        scroll={{ y: 240 }}
      />
    </Card>
  )
}

export default DashboardPupils
