import { EditOutlined } from '@ant-design/icons'
import { AUTHORITIES } from '@app/constants/authorities'
import useLocalization from '@app/hooks/useLocalization'
import { IOrganizationPassport } from '@app/interfaces/organization-passport/organization-passport'
import { IPublicDictionaries } from '@app/interfaces/public-dictionaries'
import { edit } from '@app/store/actions/form-state'
import { getInstitutionPassport } from '@app/store/actions/Institution-passport-actions'
import { getPublicDictionaries } from '@app/store/actions/public-dictionaries-actions'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Descriptions } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

interface props {
  _dictionaries?: IPublicDictionaries
  _organizationPassport?: IOrganizationPassport
}

interface params {
  id?: string
}

const InstitutionPassport: React.FC<props> = ({
  _dictionaries,
  _organizationPassport,
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const params: params = useParams()
  const { localize } = useLocalization()
  setDocumentTitle(t('institutionPassport.title'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getPublicDictionaries(
        { source: source },
        t('institutionPassport.dictionariesError'),
      ),
    )
    if (
      isEmpty(_organizationPassport) ||
      _organizationPassport?.organization?.id !== Number(params.id)
    )
      dispatch(
        getInstitutionPassport(
          { data: Number(params.id), source },
          t('institutionPassport.error'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [dispatch, params.id, t])

  const permissions = [
    AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
    AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
    AUTHORITIES.ADMIN,
  ]

  return (
    <Card
      extra={
        rolesChecker(permissions) ? (
          <Link
            to={
              _organizationPassport?.id
                ? `/organization/${params.id}/passport/${_organizationPassport?.id}/edit`
                : `/organization/${params.id}/passport/add`
            }
            className="ant-btn ant-btn-default"
            onClick={edit}
            type={'primary'}
          >
            <EditOutlined />
            {t('general.edit')}
          </Link>
        ) : null
      }
      title={t('institutionPassport.title')}
    >
      <Descriptions size={'small'} column={1} bordered>
        <Descriptions.Item label={t('institutionPassport.id')}>
          {_organizationPassport?.id || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.cadastralNumber')}>
          {_organizationPassport?.cadastralNumber || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.khokimiyatOrder')}>
          {_organizationPassport?.khokimiyatOrder || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.khokimiyatOrderDate')}>
          {_organizationPassport?.khokimiyatOrderDate ? (
            <Moment format={'DD.MM.YYYY'}>
              {_organizationPassport?.khokimiyatOrderDate}
            </Moment>
          ) : (
            ''
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.workSchedule')}>
          {_organizationPassport?.workSchedule || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.openDate')}>
          {_organizationPassport?.openDate || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.organization')}>
          {_organizationPassport?.organization?.id || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.queuePercentage')}>
          {`${_organizationPassport?.queuePercentage}%` || ''}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.hoursWaterAvailabilityLastTwoWeek')}
        >
          {_organizationPassport?.hoursWaterAvailabilityLastTwoWeek || ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.branch')}>
          {_organizationPassport?.branch ? t('general.yes') : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionType.institutionType')}>
          {
            localize(_dictionaries?.institutionType as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.institutionType?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('ownership.ownership')}>
          {
            localize(_dictionaries?.ownership as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.ownership?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item
          label={t('childrenStayDuration.childrenStayDuration')}
        >
          {
            localize(_dictionaries?.childrenStayDuration as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.childrenStayDuration?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('medicalStaffAvailability.title')}>
          {
            localize(_dictionaries?.medicalStaffAvailability as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.medicalStaffAvailability?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('drinkingWaterSource.waterSource')}>
          {
            localize(_dictionaries?.drinkingWaterSource as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.drinkingWaterSource?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item
          label={t('drinkingWaterAvailable.drinkingWaterAvailable')}
        >
          {
            localize(_dictionaries?.drinkingWaterAvailable as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.drinkingWaterAvailable?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item
          label={t('drinkingWaterTreatment.drinkingWaterTreatment')}
        >
          {
            localize(_dictionaries?.drinkingWaterTreatment as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.drinkingWaterTreatment?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('toiletType.toiletType')}>
          {
            localize(_dictionaries?.toiletType as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.toiletType?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('toiletLocation.toiletLocation')}>
          {
            localize(_dictionaries?.toiletLocation as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.toiletLocation?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('toiletCleaning.title')}>
          {
            localize(_dictionaries?.toiletCleaning as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.toiletCleaning?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('solidWasteRemoving.title')}>
          {
            localize(_dictionaries?.solidWasteRemoving as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.solidWasteRemoving?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('hotWaterSource.hotWaterSource')}>
          {
            localize(_dictionaries?.hotWaterSource as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.hotWaterSource?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('heatingSystemSource.heatingSystemSource')}>
          {
            localize(_dictionaries?.heatingSystemSource as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.heatingSystemSource?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('electricity.title')}>
          {
            localize(_dictionaries?.electricity as any)?.find(
              (dictionary) =>
                dictionary?.id === _organizationPassport?.electricity?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('foodPreparingPlace.foodPreparingPlace')}>
          {
            localize(_dictionaries?.foodPreparingPlace as any)?.find(
              (dictionary) =>
                dictionary?.id ===
                _organizationPassport?.foodPreparingPlace?.id,
            )?.data?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.internet')}>
          {_organizationPassport?.internet ? t('general.yes') : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.projectCapacity')}>
          {_organizationPassport?.projectCapacity}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.totalBuildingsUsableArea')}
        >
          {_organizationPassport?.totalBuildingsUsableArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.bedroomArea')}>
          {_organizationPassport?.bedroomArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.groupsNumber')}>
          {_organizationPassport?.groupsNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.totalEmployeesNumber')}
        >
          {_organizationPassport?.totalEmployeesNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.womenEmployeesNumber')}
        >
          {_organizationPassport?.womenEmployeesNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.menEmployeesNumber')}>
          {_organizationPassport?.menEmployeesNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.girlsNumber')}>
          {_organizationPassport?.girlsNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.boysNumber')}>
          {_organizationPassport?.boysNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.cleanersNumber')}>
          {_organizationPassport?.cleanersNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            'institutionPassport.toiletEquippedForReducedMobilityOrVisionImpairments',
          )}
        >
          {_organizationPassport?.toiletEquippedForReducedMobilityOrVisionImpairments
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            'institutionPassport.sinksWashbasinsAvailabilityForHandWashing',
          )}
        >
          {_organizationPassport?.sinksWashbasinsAvailabilityForHandWashing
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.handsWashingPointsWithWater')}
        >
          {_organizationPassport?.handsWashingPointsWithWater
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.handsWashingPointsWithWaterAndSoap')}
        >
          {_organizationPassport?.handsWashingPointsWithWaterAndSoap
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            'institutionPassport.sinkOrWashbasinEquippedForReducedMobilityImpairments',
          )}
        >
          {_organizationPassport?.sinkOrWashbasinEquippedForReducedMobilityImpairments
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.gasAvailability')}>
          {_organizationPassport?.gasAvailability
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.centralSewerage')}>
          {_organizationPassport?.centralSewerage
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.workingRefrigeratorAvailability')}
        >
          {_organizationPassport?.workingRefrigeratorAvailability
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.workingSinkPresenceForWashingDishes')}
        >
          {_organizationPassport?.workingSinkPresenceForWashingDishes
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.bathingRoomsNumber')}>
          {_organizationPassport?.bathingRoomsNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.separateRoomsOrBathTimeGirlsBoys')}
        >
          {_organizationPassport?.separateRoomsOrBathTimeGirlsBoys
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.separateRoomsOrBathTimeStudentsStaff')}
        >
          {_organizationPassport?.separateRoomsOrBathTimeStudentsStaff
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            'institutionPassport.separateFemalesAndMalesBathWithReducedMobility',
          )}
        >
          {_organizationPassport?.separateFemalesAndMalesBathWithReducedMobility
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.bathingHotWaterAvailability')}
        >
          {_organizationPassport?.bathingHotWaterAvailability
            ? t('general.yes')
            : t('general.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.fullyUsableToilets')}>
          {_organizationPassport?.fullyUsableToilets}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.hoursWaterAvailabilityLastTwoWeek')}
        >
          {_organizationPassport?.hoursWaterAvailabilityLastTwoWeek}
        </Descriptions.Item>

        {/* Added Fields */}

        <Descriptions.Item
          label={t('institutionPassport.automaticWashingMachines')}
        >
          {_organizationPassport?.automaticWashingMachines}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.semiAutomaticWashingMachines')}
        >
          {_organizationPassport?.semiAutomaticWashingMachines}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.bowlerPotsNumber')}>
          {_organizationPassport?.bowlerPotsNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.smallBowlerPotsNumber')}
        >
          {_organizationPassport?.smallBowlerPotsNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.ovensNumber')}>
          {_organizationPassport?.ovensNumber}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.orchardsAndFlowerGardensArea')}
        >
          {_organizationPassport?.orchardsAndFlowerGardensArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.courtyardArea')}>
          {_organizationPassport?.courtyardArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.officeCleaningArea')}>
          {_organizationPassport?.officeCleaningArea}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.waterSupplySewerageHeatingArea')}
        >
          {_organizationPassport?.waterSupplySewerageHeatingArea}
        </Descriptions.Item>
        <Descriptions.Item label={t('institutionPassport.lightingPoints')}>
          {_organizationPassport?.lightingPoints}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('institutionPassport.separatedBuildingsNumber')}
        >
          {_organizationPassport?.separatedBuildingsNumber}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    _organizationPassport: state.organizationPassport.institution_passport,
    _dictionaries: state.dictionaries.allDictionaries,
  }
}

export default connect(mapStateToProps)(InstitutionPassport)
