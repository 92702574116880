import useQuery from '@app/hooks/useQuery'
import { TGender } from '@app/interfaces/organization-growth'
import { useGetOrganizationGrowthStatisticsQuery } from '@app/store/rtk/api/organization-growth-api'
import { Button, Card, Form, Spin } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import GrowthStatisticsFilters from './components/growth-statistics-filters'
import './growth-statistics.less'
import { femaleLine, maleLine } from './statistics-data'

const GrowthStatistics: React.FC = () => {
  const [t] = useTranslation()
  const [gender, setGender] = useState<TGender>('MALE')
  const [form] = Form.useForm()
  const [skip, setSkip] = useState<boolean>(true)
  const { serializeQuery } = useQuery()
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(),
  )
  const { data: statistics, isLoading } =
    useGetOrganizationGrowthStatisticsQuery(searchParams.toString(), { skip })

  const onShowHandler = () => {
    if (skip) setSkip(false)
    setSearchParams(
      new URLSearchParams(
        serializeQuery({
          query: {
            ...(form.getFieldValue('regionId')
              ? { regionId: form.getFieldValue('regionId') }
              : {}),
            ...(form.getFieldValue('districtId')
              ? { districtId: form.getFieldValue('districtId') }
              : {}),
            organizationId: form.getFieldValue('organizationId'),
            groupId: form.getFieldValue('groupId'),
            pupilId: form.getFieldValue('pupilId'),
            'quarterId.equals': form.getFieldValue('quarterId'),
          },
        }),
      ),
    )
  }

  const onReset = () => {
    form.resetFields()
  }
  return (
    <>
      <div className="header-form">
        <GrowthStatisticsFilters form={form} setGender={setGender} />
        <Button
          type="primary"
          style={{ marginTop: '24px' }}
          onClick={onShowHandler}
        >
          {t('general.filter')}
        </Button>
        <Button
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}
        >
          {t('general.reset')}
        </Button>
      </div>
      <Spin spinning={isLoading}>
        <Card style={{ padding: '24px' }}>
          <div className="charts">
            <ResponsiveContainer width={'50%'} height={'100%'}>
              <LineChart
                data={
                  gender === 'MALE'
                    ? maleLine.map((maleLine) => ({
                        ...maleLine,
                        ...statistics?.response
                          .filter((item) => item.gender === 'MALE')
                          .find((item) => item.age === maleLine.age),
                      }))
                    : femaleLine.map((femaleLine) => ({
                        ...femaleLine,
                        ...statistics?.response
                          .filter((item) => item.gender === 'FEMALE')
                          .find((item) => item.age === femaleLine.age),
                      }))
                }
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="age"
                  label={{
                    value: t('general.years'),
                    position: 'insideBottom',
                    offset: -10,
                  }}
                />
                <YAxis
                  domain={[0, 200]}
                  label={{
                    value: t('growth.height'),
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip />
                <Legend layout="vertical" wrapperStyle={{ left: '70px' }} />
                <Line
                  name={t('growth.averageHeight')}
                  type="monotone"
                  dataKey={'averageHeight'}
                  stroke="#7452ca"
                  strokeWidth={2}
                />
                <Line
                  name={t('growth.chart.positive')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'positiveMaleHeight'
                      : 'positiveFemaleHeight'
                  }
                  stroke="#36ad03"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.neutralPlus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'neutralMalePlusHeight'
                      : 'neutralFemalePlusHeight'
                  }
                  stroke="#e30808"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.neutralMinus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'neutralMaleMinusHeight'
                      : 'neutralFemaleMinusHeight'
                  }
                  stroke="#e30808"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.criticalPlus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'criticalMalePlusHeight'
                      : 'criticalFemalePlusHeight'
                  }
                  stroke="#000000"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.criticalMinus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'criticalMaleMinusHeight'
                      : 'criticalFemaleMinusHeight'
                  }
                  stroke="#000000"
                  strokeDasharray="4 4"
                />
              </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'50%'} height={'100%'}>
              <LineChart
                data={
                  gender === 'MALE'
                    ? maleLine.map((maleLine) => ({
                        ...maleLine,
                        ...statistics?.response
                          .filter((item) => item.gender === 'MALE')
                          .find((item) => item.age === maleLine.age),
                      }))
                    : femaleLine.map((femaleLine) => ({
                        ...femaleLine,
                        ...statistics?.response
                          .filter((item) => item.gender === 'FEMALE')
                          .find((item) => item.age === femaleLine.age),
                      }))
                }
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="age"
                  label={{
                    value: t('general.years'),
                    position: 'insideBottom',
                    offset: -10,
                  }}
                />
                <YAxis
                  domain={[0, 60]}
                  label={{
                    value: t('growth.weight'),
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip />
                <Legend layout="vertical" wrapperStyle={{ left: '70px' }} />
                <Line
                  name={t('growth.averageWeight')}
                  type="monotone"
                  dataKey="averageWeight"
                  stroke="#7452ca"
                  strokeWidth={2}
                />
                <Line
                  name={t('growth.chart.positive')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'positiveMaleWeight'
                      : 'positiveFemaleWeight'
                  }
                  stroke="#36ad03"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.neutralPlus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'neutralMalePlusWeight'
                      : 'neutralFemalePlusWeight'
                  }
                  stroke="#e30808"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.neutralMinus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'neutralMaleMinusWeight'
                      : 'neutralFemaleMinusWeight'
                  }
                  stroke="#e30808"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.criticalPlus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'criticalMalePlusWeight'
                      : 'criticalFemalePlusWeight'
                  }
                  stroke="#000000"
                  strokeDasharray="4 4"
                />
                <Line
                  name={t('growth.chart.criticalMinus')}
                  type="monotone"
                  dataKey={
                    gender === 'MALE'
                      ? 'criticalMaleMinusWeight'
                      : 'criticalFemaleMinusWeight'
                  }
                  stroke="#000000"
                  strokeDasharray="4 4"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </Spin>
    </>
  )
}

export default GrowthStatistics
