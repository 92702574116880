import { AUTHORITIES } from '@app/constants/authorities'
import { IOrganizationGrowthBulkPupil } from '@app/interfaces/organization-growth'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import {
  useGetOrganizationGrowthBulkPupilQuery,
  useUpdateOrganizationGrowthBulkPupilMutation,
} from '@app/store/rtk/api/organization-growth-api'
import { toggleEditModal } from '@app/store/slices/organization-growth-slice'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Form, InputNumber, Table } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import OrganizationGrowthFilters from './components/organization-growth-filters'
import OrganizationGrowthEditForm from './components/organization-growth-form-edit'
import './organization-growth.less'
import useLocalization from '@app/hooks/useLocalization'
import { groupBy } from 'lodash'
import { useGetAllQuarters } from '@app/hooks/useGetAllQuarters'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_HEIGHT_WEIGHT_ALL_EDIT,
  AUTHORITIES.ROLE_HEIGHT_WEIGHT_REGION_EDIT,
  AUTHORITIES.ROLE_HEIGHT_WEIGHT_DISTRICT_EDIT,
  AUTHORITIES.ROLE_HEIGHT_WEIGHT_CURRENT_EDIT,
]

const OrganizationGrowth: React.FC = () => {
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [pupil, setPupil] = useState<IOrganizationGrowthBulkPupil>()
  const { allQuarters } = useGetAllQuarters()
  const { localize, languageId } = useLocalization()

  const { data: growthPupils, isLoading } =
    useGetOrganizationGrowthBulkPupilQuery(searchParams.toString(), {
      skip: !searchParams.has('educationGroupId'),
    })
  const [updateBulkPupilGrowth, { isLoading: isUpdateLoading }] =
    useUpdateOrganizationGrowthBulkPupilMutation()
  setDocumentTitle(t('routes.growth'))

  const onPupilClickHandler = (pupilId: number) => {
    setPupil(growthPupils?.response.find((pupil) => pupil.pupilId === pupilId))
    dispatch(toggleEditModal(true))
  }

  const onFinish = (values) => {
    let missedField = false

    const newValues = Object.values(
      groupBy(
        Object.entries(values).filter(([_, value]) => !!value),
        ([key]) => key.split('-')[1],
      ),
    )
      .map((pupilInfoArr) => {
        return Object.values(
          groupBy(pupilInfoArr, ([key]) => key.split('-')[2]),
        ).map((quarter) => {
          if (quarter.length === 2) {
            const [height, weight] = quarter
            const [key, heightValue] = height
            const [_, weightValue] = weight
            const [__, pupilId, quarterId, id] = key.split('-')
            return {
              height: heightValue,
              weight: weightValue,
              pupilId: parseInt(pupilId, 10),
              quarterId: parseInt(quarterId, 10),
              ...(parseInt(id, 10) ? { id: parseInt(id, 10) } : {}),
            }
          }
          if (quarter.length === 1) {
            missedField = true
          }
        })
      })
      .flat()
      .filter((value) => !!value)
    if (missedField) {
      openNotificationWithIcon(
        'warning',
        t('general.errorDetails.message', {
          message: 'weight or height is empty in some fields',
        }),
      )
    }
    updateBulkPupilGrowth(newValues as any)
      .unwrap()
      .then(() => {
        openNotificationWithIcon('success', t('growth.successAdd'))
      })
  }

  const data = useMemo(() => {
    if (!growthPupils || !allQuarters) return []
    return growthPupils.response.map((pupil) => {
      const pupilBodyInformation0 = pupil.pupilBodyInformation.find(
        (grouthQuarter) =>
          grouthQuarter.quarterId ===
          allQuarters.find((q) => q.type === 'FIRST_QUARTER')?.id,
      )
      const pupilBodyInformation1 = pupil.pupilBodyInformation.find(
        (grouthQuarter) =>
          grouthQuarter.quarterId ===
          allQuarters.find((q) => q.type === 'SECOND_QUARTER')?.id,
      )
      const pupilBodyInformation2 = pupil.pupilBodyInformation.find(
        (grouthQuarter) =>
          grouthQuarter.quarterId ===
          allQuarters.find((q) => q.type === 'THIRD_QUARTER')?.id,
      )
      return {
        key: pupil.pupilId,
        pupilId: pupil.pupilId,
        fullName: pupil.fullName,
        educaitonGroupId: pupil.educaitonGroupId,
        pupilBodyInformation0,
        pupilBodyInformation1,
        pupilBodyInformation2,
      }
    })
  }, [growthPupils])

  const columns = useMemo(() => {
    if (!allQuarters) return []
    return [
      {
        title: '№',
        dataIndex: 'id',
        render: (text, record, index) => <Text>{index + 1}</Text>,
      },
      {
        title: t('general.quarter'),
        children: [
          {
            title: t('employee.fullName'),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text, cell) => (
              <div
                style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                onClick={() => onPupilClickHandler(cell.pupilId)}
              >
                {text || ''}
              </div>
            ),
          },
        ],
      },
      ...allQuarters!.map((quartal, quartalIndex) => {
        return {
          title: localize(allQuarters)[quartalIndex]?.data.name,
          children: [
            {
              title: t('growth.height'),
              width: 100,
              key: `pupilBodyInformation${quartal.id}height`,
              dataIndex: `pupilBodyInformation${quartalIndex}`,
              render: (body, record) => {
                return rolesChecker(permissions) ? (
                  <Form.Item
                    name={`height-${record.pupilId}-${quartal.id}-${body?.id}`}
                    style={{ width: '100px' }}
                    initialValue={body?.height}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        max: 200,
                        message: t('errors.minMaxMessage', {
                          min: 0,
                          max: 200,
                        }),
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                ) : (
                  <Text>{body?.height}</Text>
                )
              },
            },
            {
              title: t('growth.weight'),
              width: 100,
              key: `pupilBodyInformation${quartal.id}weight`,
              dataIndex: `pupilBodyInformation${quartalIndex}`,
              render: (body, record) => {
                return rolesChecker(permissions) ? (
                  <Form.Item
                    name={`weight-${record.pupilId}-${quartal.id}-${body?.id}`}
                    style={{ width: '100px' }}
                    initialValue={body?.weight}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                        max: 200,
                        message: t('errors.minMaxMessage', {
                          min: 0,
                          max: 200,
                        }),
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                ) : (
                  <Text>{body?.weight}</Text>
                )
              },
            },
          ],
        }
      }),
    ]
  }, [allQuarters, localize, languageId])

  return (
    <>
      <OrganizationGrowthEditForm pupil={pupil} />
      <div className="header-form">
        <OrganizationGrowthFilters setSearchParams={setSearchParams} />
      </div>
      <Card
        title={t('growth.pupilInfo')}
        extra={
          rolesChecker(permissions) && (
            <Button
              disabled={isUpdateLoading}
              onClick={form.submit}
              type="primary"
            >
              {t('growth.editAll')}
            </Button>
          )
        }
      >
        <Form onFinish={onFinish} form={form}>
          <Table
            columns={columns}
            dataSource={data}
            rowClassName="table-row"
            className="organization-growth-table"
            loading={isLoading}
            pagination={false}
          />
        </Form>
      </Card>
    </>
  )
}

export default OrganizationGrowth
