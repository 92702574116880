import { EditOutlined } from '@ant-design/icons'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import { IPathParams } from '@app/constants/path-params'
import ThreeDots from '@app/shared/loader/ThreeDots'
import {
  useGetOrganizationQuery,
  usePatchOrganizationMutation,
} from '@app/store/rtk/api/organization/organization-api'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Descriptions, Form, Input } from 'antd'
import Checkbox from 'antd/es/checkbox'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import DescriptionContent from './components/description-content/description-content.tsx'

const permissions = [
  AUTHORITIES.ADMIN,
  AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
  AUTHORITIES.ROLE_ORGANIZATION_NAME_CHANGE,
  AUTHORITIES.ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE,
  AUTHORITIES.ROLE_ORGANIZATION_NEIGHBORHOOD_CHANGE,
  AUTHORITIES.ROLE_DASH1_CURRENT_VIEW,
]

const OrganizationPage: React.FC = () => {
  const params: IPathParams = useParams()
  const { data: organization } = useGetOrganizationQuery(Number(params?.id))
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const [toggleStatus, { isLoading }] = usePatchOrganizationMutation()
  const toggleEmisApiStatus = () => {
    toggleStatus(form.getFieldsValue())
  }
  setDocumentTitle(t('organization.organization'))
  return (
    <>
      <Card
        extra={
          rolesChecker(permissions) && (
            <Link
              to={`/organization/${params.id}/edit`}
              className={'ant-btn ant-btn-default'}
            >
              <EditOutlined />
              {t('general.edit')}
            </Link>
          )
        }
        title={`${organization?.name}`}
      >
        <Descriptions size={'middle'} column={1} bordered>
          <Descriptions.Item label={'ID'}>{organization?.id}</Descriptions.Item>
          <Descriptions.Item label={t('general.name')}>
            {organization?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('region.region')}>
            <DescriptionContent
              apiKey={'region'}
              id={organization?.region?.id}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('district.district')}>
            <DescriptionContent
              apiKey={'district'}
              id={organization?.district?.id}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('neighborhood.neighborhood')}>
            <DescriptionContent
              apiKey={'neighborhood'}
              nhood={organization?.neighborhood}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={t('organizationLocality.organizationLocality')}
          >
            <DescriptionContent
              apiKey={ApiKeys.organizationLocality}
              id={organization?.locality?.id}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('institutionPassport.soato')}>
            {organization?.soato}
          </Descriptions.Item>
          <Descriptions.Item label={t('organization.code')}>
            {organization?.code}
          </Descriptions.Item>
          <Descriptions.Item label={t('organizationType.organizationType')}>
            <DescriptionContent
              apiKey={ApiKeys.organizationType}
              id={organization?.type?.id}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t('organizationStatus.organizationStatus')}>
            <DescriptionContent
              apiKey={ApiKeys.organizationsStatus}
              id={organization?.status?.id}
            />
          </Descriptions.Item>
          {organization?.type.type === 'INSTITUTION' && (
            <>
              <Descriptions.Item label={t('organizationSector.title')}>
                <DescriptionContent
                  apiKey={ApiKeys.organizationSector}
                  id={organization?.organizationSector?.id}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('institutionType.institutionType')}>
                <DescriptionContent
                  apiKey={ApiKeys.institutionType}
                  id={organization?.passport?.institutionType?.id}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('institutionSubType.title')}>
                <DescriptionContent
                  apiKey={ApiKeys.institutionSubType}
                  id={organization?.institutionSubType?.id}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('organizationBuildingType.title')}>
                <DescriptionContent
                  apiKey={ApiKeys.organizationBuildingType}
                  id={organization?.organizationBuildingType?.id}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t('organizationSchedule.title')}>
                <DescriptionContent
                  apiKey={ApiKeys.organizationSchedule}
                  id={organization?.organizationSchedule?.id}
                />
              </Descriptions.Item>
              <Descriptions.Item
                label={t('institutionPassport.queuePercentage')}
              >
                {organization?.passport?.id
                  ? `${organization.passport.queuePercentage}%`
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('institutionPassport.projectCapacity')}
              >
                {organization?.passport?.id
                  ? `${organization.passport.projectCapacity}`
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('institutionPassport.plannedCapacity')}
              >
                {organization?.passport?.id
                  ? `${organization.passport?.plannedCapacity ?? ''}`
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('institutionPassport.cadastralNumber')}
              >
                {organization?.cadastralNumber != null
                  ? organization?.cadastralNumber
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={t('scheduler.emisApiStatus')}>
                {isLoading ? (
                  <ThreeDots style="dot-pulse" height={32} padding={8} />
                ) : (
                  <Form
                    form={form}
                    initialValues={{
                      id: organization.id,
                      emisApiStatus: organization.emisApiStatus,
                    }}
                  >
                    <Form.Item hidden name="id">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      style={{ padding: 0, margin: 0 }}
                      name="emisApiStatus"
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={toggleEmisApiStatus}
                        disabled={
                          !rolesChecker([
                            AUTHORITIES.ADMIN,
                            AUTHORITIES.ROLE_ORGANIZATION_EMISAPI_STATUS_CHANGE,
                          ])
                        }
                      />
                    </Form.Item>
                  </Form>
                )}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Card>
    </>
  )
}

export default OrganizationPage
