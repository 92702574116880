import { useCallback, useState } from 'react'

interface UseBooleanOutput {
    value: boolean // значение
    setValue: (value: boolean) => void // установить значение
    setTrue: () => void // установить true
    setFalse: () => void // установить false
    toggle: () => void // переключить значение
}

/**
 * Хук для управления булевым состоянием
 * @param defaultValue - начальное значение (по умолчанию false)
 */
const useBoolean = (defaultValue?: boolean): UseBooleanOutput => {
    const [value, setValue] = useState(!!defaultValue)

    const setTrue = useCallback(() => {
        setValue(true)
    }, [])

    const setFalse = useCallback(() => {
        setValue(false)
    }, [])

    const toggle = useCallback(() => {
        setValue(prev => !prev)
    }, [])

    return { value, setValue, setTrue, setFalse, toggle }
}

export default useBoolean 