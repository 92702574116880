import bbitApi from '@app/api/make-request'
import { NO } from '@app/constants/chars'
import useLocalization from '@app/hooks/useLocalization'
import { isEmpty } from 'lodash'
import {
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Table,
  Typography,
} from 'antd'
import { Fragment, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  IPupil,
  IPupilReadinessMap,
  PupilReadinessTitle,
} from '@app/interfaces/pupil/pupils'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import ChildInfoComponent from '@app/pages/child-stats/components/child-info-table'

const { Paragraph, Title } = Typography

type Props = {
  edit?: boolean
  pupil?: Partial<IPupil>
  pupilReadinessMapId?: number
  deleteGrade?: (formId: string) => void
}
const GRADES = ['K', 'B', 'T', 'D']

const ChildDevelopmentView = ({
  pupil,
  edit = false,
  pupilReadinessMapId,
  deleteGrade = () => {},
}: Props) => {
  const [t] = useTranslation()

  const { data: pupilReadnessMap } = useQuery(
    [
      'pupil/api/v1/pupils-readiness-map',
      pupil?.pupilReadinessMapId || pupilReadinessMapId,
    ],
    async () => {
      const { data } = await bbitApi.get<IPupilReadinessMap>(
        `/pupil/api/v1/pupils-readiness-map/${pupil?.pupilReadinessMapId}`,
      )
      return data
    },
    {
      enabled: !!pupil?.pupilReadinessMapId,
      cacheTime: 0,
    },
  )

  const { isLoading, data: pupilReadinessTitle } = useQuery(
    'pupil/api/v1/pupil-readiness-map-title/with-subtitle',
    async () => {
      const { data } = await bbitApi.get<PupilReadinessTitle[]>(
        '/pupil/api/v1/pupil-readiness-map-title/with-subtitle',
      )
      return data
    },
  )

  const dataSource = useMemo(() => {
    if (!pupilReadinessTitle) {
      return []
    }
    if (
      pupilReadinessTitle &&
      !(pupil?.pupilReadinessMapId || pupilReadinessMapId)
    ) {
      return pupilReadinessTitle.map((i) => ({
        ...i,
        subtitles: i.subtitles?.map((sub) => ({ ...sub, key: sub.id })),
      }))
    }
    return pupilReadinessTitle.map((title) => ({
      ...title,
      subtitles: title.subtitles?.map((subtitle) => {
        const detail = pupilReadnessMap?.details?.find(
          (d) => d.subtitle.id === subtitle.id,
        )

        return {
          ...subtitle,
          key: subtitle.id,
          grade: detail?.grade,
          comment: detail?.comment,
          detailId: detail?.id,
        }
      }),
    }))
  }, [
    pupilReadinessTitle,
    pupil?.pupilReadinessMapId,
    pupilReadinessMapId,
    pupilReadnessMap,
  ])

  const { localizeData } = useLocalization()
  const columns = [
    {
      title: NO,
      dataIndex: 'id',
      key: 'id',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: t('growth.growthResult'),
      dataIndex: 'data',
      key: 'subtitle.data',
      render: (subtitle) => <>{localizeData(subtitle)?.name}</>,
    },
    {
      title: t('educationGrade.educationGrade'),
      key: 'grade',
      dataIndex: 'grade',
      ...(edit
        ? {
            render: (value, record) => {
              const formId = JSON.stringify({
                subtitleId: record.id,
                grade: 'grade',
                detailId: record?.detailId,
              })
              return (
                <Row>
                  <Col>
                    <Form.Item initialValue={value} name={formId}>
                      <Radio.Group>
                        {GRADES.map((grade) => (
                          <Radio key={grade} value={grade}>
                            {grade}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {edit && (
                    <Col>
                      <DeleteOutlined
                        style={{ color: 'red' }}
                        onClick={() => {
                          deleteGrade(formId)
                        }}
                      />
                    </Col>
                  )}
                </Row>
              )
            },
          }
        : {
            children: GRADES.map((t) => ({
              title: t,
              key: t,
              dataIndex: 'grade',
              render: (val) => <Checkbox checked={val === t} disabled />,
            })),
          }),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment, record) =>
        edit ? (
          <Form.Item
            name={JSON.stringify({
              subtitleId: record.id,
              comment: 'comment',
              detailId: record?.detailId,
            })}
            initialValue={comment}
          >
            <Input />
          </Form.Item>
        ) : (
          <Paragraph>{comment}</Paragraph>
        ),
    },
  ]

  if (isEmpty(dataSource)) return <></>
  return (
    <>
      <ChildInfoComponent
        birthDate={pupil?.birthDate}
        fullName={pupil?.fullName}
        gender={pupil?.gender}
        completionDate={pupilReadnessMap?.completionDate}
      />
      {dataSource.map((title) => (
        <Fragment key={title.id}>
          <Title level={4}>{localizeData(title.data)?.name}</Title>
          <Table
            loading={isLoading}
            pagination={false}
            dataSource={title.subtitles}
            columns={columns}
          />
        </Fragment>
      ))}
    </>
  )
}

export default ChildDevelopmentView
