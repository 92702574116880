import organizationRepository from '@app/api/repositories/organization-repository'
import {
  default as PupilApplicationRepository,
  default as pupilApplicationRepository,
} from '@app/api/repositories/pupil-application-repository'
import { ApiKeys } from '@app/constants/api-keys'
import { AUTHORITIES } from '@app/constants/authorities'
import useCache from '@app/hooks/useCache'
import useLocalization from '@app/hooks/useLocalization'
import { IEducationGrade } from '@app/interfaces/education/education-grade'
import { ILang } from '@app/interfaces/languages'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IPupilApplication } from '@app/interfaces/pupil/pupil-application'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Col, Form, Input, Row, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

interface props {
  languages: ILang[]
}

interface appWithOrganization {
  application: IPupilApplication
  organization: IOrganization
}

const PupilReject: React.FC<props> = ({ languages }) => {
  const [t] = useTranslation()
  const [appsWithOrganizations, setAppsWithOrganizations] = useState<
    appWithOrganization[]
  >([])
  setDocumentTitle(t('admin.title'))
  const { data: grades } = useCache<IEducationGrade>(ApiKeys.educationGrade)
  const { localizeData } = useLocalization()

  const onSearch = async (value) => {
    const applications = await PupilApplicationRepository.getAll({
      params: `applicationNo.equals=${value.applicationNo}`,
    }).then((response) => response.data)
    const appsWithOrganizationList: appWithOrganization[] = []
    for (const application of applications) {
      const organization = await organizationRepository
        .getOne({ data: Number(application.organizationId) })
        .then((response) => response.data)
      appsWithOrganizationList.push({
        application,
        organization,
      })
    }
    setAppsWithOrganizations(appsWithOrganizationList)
  }

  const onCancelHandler = (id: number) => {
    pupilApplicationRepository.cancel(id).then((response) => {
      openNotificationWithIcon('success', t('admin.pupilReject.success'))
    })
  }

  const columns = [
    {
      title: t('admin.applicationNo'),
      dataIndex: 'applicationNo',
      key: 'applicationNo',
    },
    {
      title: t('admin.pupilReject.organizationName'),
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: t('educationGrade.title'),
      dataIndex: 'educationGrade',
      key: 'educationGrade',
    },
    {
      title: t('admin.pupilReject.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Button
          type="primary"
          key={record.applicationNo}
          onClick={() => onCancelHandler(record.id)}
        >
          {t('admin.pupilReject.cancel')}
        </Button>
      ),
    },
  ]

  const data = useMemo(() => {
    if (isEmpty(appsWithOrganizations) || isEmpty(grades)) return []
    return appsWithOrganizations.map(({ application, organization }) => {
      const grade = grades.find((g) => g.id === application?.educationGradeId)
      const educationGrade =
        localizeData(grade?.data)?.name || grade?.data?.at(0)?.name
      return {
        key: application.id,
        id: application.id,
        applicationNo: application.applicationNo,
        organizationName: organization.name,
        status: application.status,
        educationGrade,
      }
    })
  }, [appsWithOrganizations, grades, localizeData])

  return (
    <Card title={t('admin.title')}>
      <Form
        onFinish={onSearch}
        className="user-form"
        style={{ padding: '24px' }}
      >
        <Row gutter={16}>
          <Col span={22}>
            <Form.Item
              label={t('admin.applicationNo')}
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}
              name={'applicationNo'}
            >
              <Input />
            </Form.Item>
          </Col>
          {rolesChecker([AUTHORITIES.ROLE_APPLICATION_ALL_CANCEL]) && (
            <Col span={2}>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                  {t('general.search')}
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        rowClassName="table-row"
      />
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    languages: state.langs.langs,
  }
}

export default connect(mapStateToProps, null)(PupilReject)
