import bbitApi from '@api/make-request'
import {
  IPathParamsArgs,
  PageableData,
  stringArgs,
} from '@api/repositories/i-repository'
import {
  IChangeOrganizationMobileUserPassword,
  ICreateOrganizationMobileUserBody,
  IOrganizationMobileUser,
  IUpdateOrganizationMobileUser,
} from '@app/interfaces/organization-mobile-users'
import { resolveParamsV2 } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'
import { omit } from 'lodash'

class OrganizationMobileUsersRepository {
  create(data: ICreateOrganizationMobileUserBody): Promise<
    AxiosResponse<{
      code: number
      data: IOrganizationMobileUser
    }>
  > {
    return bbitApi.post(`/attendance-mobile-auth/api/users`, data)
  }

  getAll(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<PageableData<IOrganizationMobileUser>>> {
    return bbitApi.get(`/attendance-mobile-auth/api/users`, {
      params: resolveParamsV2(args),
      signal: args.signal,
    })
  }

  getOne(args: stringArgs): Promise<AxiosResponse<IOrganizationMobileUser>> {
    return bbitApi.get(`/attendance-mobile-auth/api/users/${args.data}`, {
      signal: args.signal,
    })
  }

  update(
    data: IUpdateOrganizationMobileUser,
  ): Promise<AxiosResponse<IOrganizationMobileUser>> {
    return bbitApi.put(`/attendance-mobile-auth/api/users/${data.userId}`, omit(data, 'userId'))
  }

  remove(
    args: stringArgs,
  ): Promise<AxiosResponse<IOrganizationMobileUser, any>> {
    return bbitApi.delete(`/attendance-mobile-auth/api/users/${args.data}`, {
      signal: args.signal,
    })
  }

  updatePassword(
    data: IChangeOrganizationMobileUserPassword,
  ): Promise<AxiosResponse<IOrganizationMobileUser>> {
    return bbitApi.patch(
      `/attendance-mobile-auth/api/users/${data.userId}/password`,
      omit(data, 'userId'),
    )
  }
}

export default new OrganizationMobileUsersRepository()
