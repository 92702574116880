import { FilterOutlined } from '@ant-design/icons'
import useQuery from '@app/hooks/useQuery'
import useRegionDestricts from '@app/shared/use-region-districts/use-region-districts'
import { Button, Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  request: (string) => void
  searchParams: URLSearchParams
}

const NeighborhoodFilterForm = ({ request, searchParams }: props) => {
  const [form] = Form.useForm()
  const { serializeQuery } = useQuery()
  const [t] = useTranslation()
  const { District, Region } = useRegionDestricts()

  const onFinish = (values: any) => {
    const newValues = {
      'districtId.equals': values.districtId,
      'regionId.equals': values.regionId,
    }

    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }

  const onReset = () => {
    const newValues = {
      'districtId.equals': null,
      'regionId.equals': null,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }

  return (
    <div className="header-form">
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        onReset={() => form.resetFields()}
      >
        <Row gutter={8}>
          <Col span={5}>
            <Form.Item name={'regionId'} label={t('region.region')}>
              {Region}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={'districtId'} label={t('district.title')}>
              {District}
            </Form.Item>
          </Col>
        </Row>
        <div>
          <Button type="primary" htmlType="submit">
            <FilterOutlined />
            {t('general.filter')}
          </Button>
          <Button
            htmlType={'reset'}
            style={{ margin: '0 8px' }}
            onClick={onReset}
          >
            {t('general.reset')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NeighborhoodFilterForm
