import { DATE_FORMAT } from '@app/constants/date'
import { LOADING } from '@app/constants/redux-state'
import useLocalization from '@app/hooks/useLocalization'
import { IEmployeeState } from '@app/interfaces/employee/employee'
import { IEmployeeRate } from '@app/interfaces/employee/employee-rate'
import { IOrganizationPosition } from '@app/interfaces/employee/organization-position'
import Spinner from '@app/shared/spinner/spinner'
import {
  clearEmployee,
  clearEmployeeMvd,
  clearEmployeeUser,
  getEmployeeByPin,
  getEmployeeByPinAndBirthdayMvdV2,
  updateEmployeeShowPassport,
  updateEmployeeType,
} from '@app/store/actions/employee-actions'
import { getEmployeeRates } from '@app/store/actions/employee-rate-actions'
import { getOrganizationPositions } from '@app/store/actions/organization-position-actions'
import { EmployeeType } from '@app/store/reducers/employee-reducer'
import { Button, Card, Col, DatePicker, Form, Input, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import './assing-an-employee-form.less'
import AddEmployeeWithPositionForm from './components/add-employee-with-position-form/add-employee-with-position-form-new'
import locale from 'antd/es/date-picker/locale/ru_RU'

interface props {
  _employeesState: IEmployeeState
  _employeeRates?: IEmployeeRate[]
  _organizationPositions: IOrganizationPosition[]
}

interface params {
  id?: string
  posId?: string
}

const AssignAnEmployeeForm: React.FC<props> = ({
  _employeesState,
  _employeeRates,
  _organizationPositions,
}) => {
  const {
    employee,
    state,
    employeeType,
    showPassport,
    employeeMvd,
    employeeUser,
  } = _employeesState
  const params: params = useParams()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [form] = useForm()
  const { localize } = useLocalization()
  const [searchParams] = useSearchParams(
    `organizationId.equals=${params.id}&status.equals=OPEN`,
  )

  useEffect(() => {
    const source = axios.CancelToken.source()
    if (isEmpty(_employeeRates))
      dispatch(
        getEmployeeRates({ source }, t('errors.dictionary.employeeRate')),
      )
    if (isEmpty(_organizationPositions))
      dispatch(
        getOrganizationPositions(
          { params: searchParams, source },
          t('employeePosition.error'),
        ),
      )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  const reset = () => {
    dispatch(clearEmployee())
    dispatch(clearEmployeeUser())
    dispatch(clearEmployeeMvd())
    dispatch(updateEmployeeShowPassport(false))
  }

  const onSearchByPinHandler = (values) => {
    reset()
    dispatch(
      getEmployeeByPin(
        { data: values.pin },
        t('employee.error'),
        t('employee.tryAgainWarning'),
        t('employee.found'),
      ),
    )
  }

  const onSearchByBirthdayHandler = (values) => {
    dispatch(
      getEmployeeByPinAndBirthdayMvdV2(
        {
          pin: values.pin,
          birthDate: values.birthdate.format(DATE_FORMAT),
        },
        t('employee.error'),
        t('employee.found'),
      ),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(clearEmployee())
      dispatch(clearEmployeeUser())
      dispatch(clearEmployeeMvd())
      dispatch(updateEmployeeType(EmployeeType.NONE))
      dispatch(updateEmployeeShowPassport(false))
    }
  }, [])

  return (
    <Card className="padding-content">
      <Form
        layout="vertical"
        form={form}
        onFinish={
          !showPassport ? onSearchByPinHandler : onSearchByBirthdayHandler
        }>
        <Row justify="center">
          <Col span={8}>
            <Form.Item
              label={t('users.pin')}
              name="pin"
              rules={[
                {
                  transform: (value) =>
                    typeof value === 'string' ? value.trim() : undefined,
                  required: true,
                  message: t('errors.requiredMessage'),
                },
              ]}>
              <Input required={true} allowClear />
            </Form.Item>
            {showPassport && (
              <Form.Item
                label={t('employee.birthDate')}
                name="birthdate"
                rules={[
                  {
                    required: true,
                    message: t('errors.requiredMessage'),
                  },
                ]}>
                <DatePicker
                  format={'DD-MM-YYYY'}
                  style={{ width: '100%' }}
                  locale={locale}
                />
              </Form.Item>
            )}
            <Button
              onClick={form.submit}
              type={'primary'}
              block
              className="margin-bottom">
              {t('employee.search')}
            </Button>

            {state === LOADING && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
          </Col>
        </Row>
      </Form>
      {!isEmpty(employeeMvd) || !isEmpty(employeeUser) ? (
        <AddEmployeeWithPositionForm
          reset={reset}
          params={params}
          employeeType={employeeType}
          pin={form.getFieldValue('pin')}
          pending={state === LOADING}
        />
      ) : null}
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    _employeesState: state.organizationEmployee,
    _employeeRates: state.employeeRate.employeeRates,
    _organizationPositions: state.organizationPositions.organizationPositions,
  }
}

export default connect(mapStateToProps)(AssignAnEmployeeForm)
