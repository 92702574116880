import { ApiKeys } from '@app/constants/api-keys'
import { IOrganizationEmployee } from '@app/interfaces/employee/employee'
import { resolveTParams } from '@app/utils/resolve-params/resolve-params'
import { emptyEmployeeApi } from '.'
import { IRtkResponse } from '../../types'

export const organizationEmployeeApi = emptyEmployeeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationEmployees: build.query<
      IRtkResponse<IOrganizationEmployee[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee-position',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationEmployee[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeePosition,
                id,
              })),
              { type: ApiKeys.employeePosition, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employeePosition, id: 'LIST' }],
    }),
    getSickListEmployees: build.query<
      // TODO MYFIX-007 backend api berishi kerak
      IRtkResponse<IOrganizationEmployee[]>,
      URLSearchParams | string
    >({
      query: (args = '') => ({
        url: 'employee/api/v1/employee-position',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationEmployee[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeePosition,
                id,
              })),
              { type: ApiKeys.employeePosition, id: 'LIST' },
            ]
          : [{ type: ApiKeys.employeePosition, id: 'LIST' }],
    }),
    getOrganizationMentorEmployees: build.query<
      IRtkResponse<IOrganizationEmployee[]>,
      URLSearchParams | string
    >({
      query: (args) => ({
        url: 'employee/api/v1/employee-position',
        method: 'get',
        params: {
          ...resolveTParams(args),
        },
      }),
      transformResponse: (response: IOrganizationEmployee[], meta) => {
        return { response, meta }
      },
      providesTags: (data) =>
        data
          ? [
              ...data.response.map(({ id }) => ({
                type: ApiKeys.employeePosition,
                id,
              })),
              { type: ApiKeys.employeePosition, id: 'MENTOR_LIST' },
            ]
          : [{ type: ApiKeys.employeePosition, id: 'MENTOR_LIST' }],
    }),
    getOrganizationEmployee: build.query<IOrganizationEmployee, number>({
      query: (id) => ({
        url: `employee/api/v1/employee-position/${id}`,
        method: 'get',
      }),
      providesTags: (result, error, id) => [
        { type: ApiKeys.employeePosition, id },
      ],
    }),
  }),
})

export const {
  useGetOrganizationEmployeesQuery,
  useGetOrganizationEmployeeQuery,
  useGetOrganizationMentorEmployeesQuery,
  useGetSickListEmployeesQuery,
} = organizationEmployeeApi
