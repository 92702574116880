import { AUTHORITIES } from '@app/constants/authorities'
import useUserManageRoles from '@app/hooks/roles/manage-roles'
import usePseudoRoles from '@app/hooks/roles/pseudo-roles'
import useSuperRoles from '@app/hooks/roles/super-roles'
import { IUserGroupRoles } from '@app/interfaces/roles'
import { filterByString } from '@app/utils/filter-by-string/filter-by-string'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'
import { Checkbox, Col, Form, Radio, Row, Table, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  role?: IUserGroupRoles
}

const RolesItem: React.FC<props> = ({ role }) => {
  const [t] = useTranslation()
  const [admin, setAdmin] = useState<boolean>(
    !!role?.roles?.find((role) => role.name === 'ROLE_ADMIN')?.name,
  )

  const onChange = (e) => {
    if (e.find((b) => b === 'ROLE_ADMIN')) {
      return setAdmin(true)
    }
    return setAdmin(false)
  }

  const columns = [
    {
      title: t('general.title'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('users.roles'),
      dataIndex: 'role',
      key: 'role',
    },
  ]

  const manageRoles = useUserManageRoles().map((role) => {
    return {
      key: role.id,
      name: role.name,
      role: (
        <Form.Item className={'roles_wrapper'} name={role.manage}>
          <Checkbox.Group>
            {filterByString('VIEW', role.roles).find((role) => role.name) ? (
              <Typography.Title level={5}>{t('general.view')}</Typography.Title>
            ) : null}
            <Row>
              {filterByString('VIEW', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
            {filterByString('EDIT', role.roles).find((role) => role.name) ? (
              <Typography.Title level={5}>
                {t('general.editing')}
              </Typography.Title>
            ) : null}
            <Row>
              {filterByString('EDIT', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
              {filterByString('ADD', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
              {filterByString('CHANGE', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
            {filterByString('APPROVED', role.roles).find(
              (role) => role.name,
            ) ? (
              <Typography.Title level={5}>
                {t('general.accepting')}
              </Typography.Title>
            ) : null}
            <Row>
              {filterByString('APPROVED', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
            {filterByString('DISMISS', role.roles).find((role) => role.name) ? (
              <Typography.Title level={5}>
                {t('general.dismissing')}
              </Typography.Title>
            ) : null}
            <Row>
              {filterByString('DISMISS', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
            <Row>
              {filterByString('RR_', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
            </Row>
            <Row>
              {filterByString('ROLE_APPLICATION', role.roles).map((role) => {
                return (
                  <Col key={role.id}>
                    <Checkbox disabled={admin} key={role.id} value={role.name}>
                      {role.name}
                    </Checkbox>
                  </Col>
                )
              })}
              {filterByString('ROLE_PLANNER_MANAGEMENT', role.roles).map(
                (role) => {
                  return (
                    <Col key={role.id}>
                      <Checkbox
                        disabled={admin}
                        key={role.id}
                        value={role.name}
                      >
                        {role.name}
                      </Checkbox>
                    </Col>
                  )
                },
              )}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      ),
    }
  })

  const superAndPseudoRoles = [
    {
      key: 25,
      name: useSuperRoles()[0].name,
      role: (
        <Form.Item className={'roles_wrapper'} name={'superRoles'}>
          <Checkbox.Group onChange={onChange}>
            <Checkbox value={useSuperRoles()[0].roles[0].name}>
              {useSuperRoles()[0].roles[0].name}
            </Checkbox>
            {rolesChecker([AUTHORITIES.SUSER]) && (
              <Checkbox value={useSuperRoles()[0].roles[1].name}>
                {useSuperRoles()[0].roles[1].name}
              </Checkbox>
            )}
          </Checkbox.Group>
        </Form.Item>
      ),
    },
    {
      key: 26,
      name: usePseudoRoles()[0].name,
      role: (
        <Form.Item className={'roles_wrapper'} name={'pseudoRoles'}>
          <Radio.Group>
            <Row>
              {usePseudoRoles()[0].roles.map((role) => (
                <Col key={role.id}>
                  <Radio disabled={admin} value={role.name}>
                    {`${role.name} - ${role.translate}`}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      ),
    },
  ]

  const data = superAndPseudoRoles.concat(manageRoles)

  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={data}
      rowClassName="table-row"
    />
  )
}

export default RolesItem
