import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IEmployee,
  IEmployeeCount,
  IEmployeeState,
} from '@app/interfaces/employee/employee'
import { IMvdUserV2 } from '@app/interfaces/mvd-user'
import { IUser } from '@app/interfaces/users'
import {
  CLEAR_EMPLOYEE,
  CLEAR_EMPLOYEES_ALL,
  CLEAR_EMPLOYEES_MENTOR,
  CLEAR_EMPLOYEES_MVD,
  CLEAR_EMPLOYEES_USER,
  COUNT_EMPLOYEES,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  FETCH_EMPLOYEES_ALL_FAILURE,
  FETCH_EMPLOYEES_ALL_REQUEST,
  FETCH_EMPLOYEES_ALL_SUCCESS,
  FETCH_EMPLOYEES_MENTOR_FAILURE,
  FETCH_EMPLOYEES_MENTOR_REQUEST,
  FETCH_EMPLOYEES_MENTOR_SUCCESS,
  FETCH_EMPLOYEE_CLEANUP,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_MVD_SUCCESS,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_USER_SUCCESS,
  REFRESH_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SHOW_PASSPORT,
  UPDATE_EMPLOYEE_TYPE,
} from '@app/store/types/employee'

export enum EmployeeType {
  EMPLOYEE,
  EMPLOYEE_USER,
  EMPLOYEE_MVD,
  NONE,
}

const initialState: IEmployeeState = {
  employee: {} as IEmployee,
  employeesAll: [],
  employeesMentor: [],
  employeesCount: {} as IEmployeeCount,
  employeeUser: {} as IUser,
  employeeMvd: {} as IMvdUserV2,
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
  employeeType: EmployeeType.NONE,
  showPassport: false,
}

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_EMPLOYEES_ALL:
      return { ...state, employeesAll: [], state: IDLE }
    case CLEAR_EMPLOYEE:
      return {
        ...state,
        employee: {},
        employeeType: EmployeeType.NONE,
        state: IDLE,
      }
    case CLEAR_EMPLOYEES_USER:
      return {
        ...state,
        employeeUser: {},
        employeeType: EmployeeType.NONE,
        state: IDLE,
      }
    case CLEAR_EMPLOYEES_MVD:
      return {
        ...state,
        employeeMvd: {},
        employeeType: EmployeeType.NONE,
        state: IDLE,
      }
    case CLEAR_EMPLOYEES_MENTOR:
      return { ...state, employeesMentor: [], state: IDLE }
    case COUNT_EMPLOYEES:
      return { ...state, employeesCount: action.payload }
    case REFRESH_EMPLOYEE:
      return { ...state, refresh: !state.refresh }
    case CREATE_EMPLOYEE:
      return {
        ...state,
        employeeStatuses: [...state.employeesAll, action.payload],
      }
    case UPDATE_EMPLOYEE:
      const updatedEmployees = state.employeesAll.map(function (employee) {
        if (action.payload.id === employee.id) {
          return action.payload
        }
        return employee
      })
      return { ...state, employees: updatedEmployees }
    case DELETE_EMPLOYEE:
      const editedEmployees = state.employeesAll.filter(function (employee) {
        if (action.payload.id === employee.id) {
          return false
        }
        return employee
      })
      return { ...state, employees: editedEmployees }
    case FETCH_EMPLOYEES_ALL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEES_ALL_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeesAll: action.payload.employeesAll,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEES_ALL_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeesAll: [],
      }
    case FETCH_EMPLOYEE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_EMPLOYEES_MENTOR_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEES_MENTOR_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employeesMentor: action.payload.employeesMentor,
        count: action.payload.count,
      }
    case FETCH_EMPLOYEES_MENTOR_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employeesMentor: [],
      }
    case FETCH_EMPLOYEE_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        employee: action.payload,
        employeeUser: {},
      }
    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        employee: {},
        employeeUser: {},
        employeeMvd: {},
        // showPassport: false,
      }
    case FETCH_EMPLOYEE_USER_SUCCESS:
      return {
        ...state,
        employeeUser: action.payload,
      }
    case FETCH_EMPLOYEE_MVD_SUCCESS:
      return {
        ...state,
        employeeMvd: action.payload,
      }
    case UPDATE_EMPLOYEE_TYPE:
      return {
        ...state,
        employeeType: action.payload,
      }
    case UPDATE_EMPLOYEE_SHOW_PASSPORT:
      return {
        ...state,
        showPassport: action.payload,
      }
    default:
      return state
  }
}
