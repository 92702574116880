import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import {
  IOrganizationPupils,
  IOrganizationPupilsState,
} from '@app/interfaces/pupil/organization-pupils'
import {
  CLEAR_ORGANIZATION_PUPIL,
  CLEAR_ORGANIZATION_PUPILS,
  DELETE_ORGANIZATION_PUPIL,
  FETCH_ORGANIZATION_PUPILS_FAILURE,
  FETCH_ORGANIZATION_PUPILS_FULL_FAILURE,
  FETCH_ORGANIZATION_PUPILS_FULL_REQUEST,
  FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS,
  FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE,
  FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST,
  FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS,
  FETCH_ORGANIZATION_PUPILS_REQUEST,
  FETCH_ORGANIZATION_PUPILS_SUCCESS,
  FETCH_ORGANIZATION_PUPIL_CLEANUP,
  FETCH_ORGANIZATION_PUPIL_FAILURE,
  FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE,
  FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST,
  FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS,
  FETCH_ORGANIZATION_PUPIL_REQUEST,
  FETCH_ORGANIZATION_PUPIL_SUCCESS,
  GET_ORGANIZATION_PUPIL,
  GET_ORGANIZATION_PUPILS,
  GET_ORGANIZATION_PUPILS_IN_QUEUE,
  GET_QUEUE_COUNT,
  REFRESH_ORGANIZATION_PUPIL,
  UPDATE_ORGANIZATION_PUPIL,
} from '@app/store/types/organization-pupils'

const initialState: IOrganizationPupilsState = {
  pupils: [],
  pupil: {} as IOrganizationPupils,
  pupilsInQueue: [],
  pupilsInQueueCounter: [],
  pupilsFull: [],
  refresh: false,
  state: IDLE,
  message: '',
  code: -1,
  count: '',
  imagesCount: '',
}

export const organizationPupilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_PUPIL:
      return { ...state, pupil: action.payload }
    case GET_ORGANIZATION_PUPILS:
      return { ...state, pupils: action.payload }
    case GET_ORGANIZATION_PUPILS_IN_QUEUE:
      return { ...state, pupilsInQueue: action.payload }
    case GET_QUEUE_COUNT:
      return { ...state, pupilsInQueueCounter: action.payload }
    case CLEAR_ORGANIZATION_PUPILS:
      return { ...state, pupils: [], state: IDLE }
    case REFRESH_ORGANIZATION_PUPIL:
      return { ...state, refresh: !state.refresh }
    case CLEAR_ORGANIZATION_PUPIL:
      return { ...state, pupil: {}, state: IDLE }
    case UPDATE_ORGANIZATION_PUPIL:
      const updatedPupils = state.pupils.map(function (pupil) {
        if (action.payload.id === pupil.id) {
          return action.payload
        }
        return pupil
      })
      return { ...state, pupils: updatedPupils }
    case DELETE_ORGANIZATION_PUPIL:
      const deletedPupils = state.pupilsInQueue.filter((pupil) => {
        if (pupil.id === action.payload.id) {
          return false
        }
        return pupil
      })
      return { ...state, pupilsInQueue: deletedPupils }
    case FETCH_ORGANIZATION_PUPILS_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_PUPILS_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupils: action.payload.pupils,
        count: action.payload.count,
        imagesCount: action.payload.imagesCount,
      }
    case FETCH_ORGANIZATION_PUPILS_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupils: [],
      }
    case FETCH_ORGANIZATION_PUPIL_CLEANUP:
      return {
        ...state,
        state: IDLE,
      }
    case FETCH_ORGANIZATION_PUPIL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_PUPIL_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupil: action.payload,
      }
    case FETCH_ORGANIZATION_PUPIL_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupil: {},
      }
    case FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupilsInQueue: action.payload.pupilsInQueue,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupilsInQueue: [],
      }
    case FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupilInQueue: action.payload,
      }
    case FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupilInQueue: {},
      }
    case FETCH_ORGANIZATION_PUPILS_FULL_REQUEST:
      return {
        ...state,
        state: LOADING,
      }
    case FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS:
      return {
        ...state,
        state: SUCCEEDED,
        message: '',
        code: -1,
        pupilsFull: action.payload.pupilsFull,
        count: action.payload.count,
      }
    case FETCH_ORGANIZATION_PUPILS_FULL_FAILURE:
      return {
        ...state,
        state: FAILED,
        message: action.payload.message,
        code: action.payload.code,
        pupilsFull: [],
      }
    default:
      return state
  }
}
