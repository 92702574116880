import bbitApi from '@api/make-request'
import {
  IEmployeeRepository,
  IPathParamsArgs,
  numberArgs,
  stringArgs,
} from '@api/repositories/i-repository'
import {
  IEmployee,
  IEmployeeAppointment,
  IEmployeeChangeStatus,
  IEmployeeChangeStatusWithDate,
  IGetEmployeeInfo,
  IOrganizationEmployee,
} from '@app/interfaces/employee/employee'
import { IMvdUserV2 } from '@app/interfaces/mvd-user'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class EmployeeRepository implements IEmployeeRepository<IEmployee> {
  create(data: IEmployee): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post('/employee/api/v1/employee', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IEmployee[]>> {
    return bbitApi.get(`/employee/api/v1/employee`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.get(`/employee/api/v1/employee/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IEmployee): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.put(`/employee/api/v1/employee/${data.id}`, data)
  }

  partialUpdate(data: any): Promise<AxiosResponse<IEmployee>> {
    // return bbitApi.patch(`/employee/api/v1/employee/${data.id}`, data);
    return bbitApi.patch(`/employee/api/v1/employee/${data.id}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
  }

  createEmployeeWithOrganizationPosition(
    data: IEmployee,
  ): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      '/employee/api/v1/employee/create-employee-with-organization-position',
      data,
    )
  }

  getOneByPin(args: stringArgs): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.get(`/employee/api/v1/employee/get-by-pin/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  appointmentEmployeeOrganizationPosition(
    data: IEmployeeAppointment,
  ): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      '/employee/api/v1/employee/appointment-employee-organization-position',
      data,
    )
  }

  countEmployees(args: stringArgs): Promise<AxiosResponse<number>> {
    return bbitApi.get(`/employee/api/v1/employee/dashboard${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  changeRate(data: any): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.put(
      `/employee/api/v1/employee/${data.employeeId}/change-rate`,
      {
        data: data,
      },
    )
  }

  getEmployeePositions(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IOrganizationEmployee>> {
    return bbitApi.get(`/employee/api/v1/employee-position`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  fromDecree(data: IEmployeeChangeStatus): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      `employee/api/v1/employee-decree/${data.employeeId}/from-decree`,
      data,
    )
  }

  toDecree(data: IEmployeeChangeStatus): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      `employee/api/v1/employee-decree/${data.employeeId}/to-decree`,
      data,
    )
  }

  sickLeave(
    data: IEmployeeChangeStatusWithDate,
  ): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      `employee/api/v1/employee/${data.employeeId}/add-employee-sick-leave`,
      data,
    )
  }

  dismiss(data: IEmployeeChangeStatus): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      `employee/api/v1/employee/${data.employeeId}/dismiss`,
      data,
    )
  }
  vacation(
    data: IEmployeeChangeStatusWithDate,
  ): Promise<AxiosResponse<IEmployee>> {
    return bbitApi.post(
      `employee/api/v1/employee-vacation/${data.employeeId}/add-vacation`,
      data,
    )
  }
  getEmployeeInfo(data: IGetEmployeeInfo): Promise<AxiosResponse<IMvdUserV2>> {
    return bbitApi.post(`employee/api/v1/employee/info-image`, data)
  }
}

export default new EmployeeRepository()
