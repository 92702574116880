import bbitApi from '@api/make-request'

export function loginViaEgov(access_code) {
  return bbitApi.get(`authorization/oauth2/access-token?code=${access_code}`)
}

export function login(data) {
  return bbitApi.post('/api/authenticate', data)
}

export function loginNoCapcha(data) {
  return bbitApi.post('/api/authenticatenocap', data)
}
