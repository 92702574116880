import React from 'react'
import Layout from '@app/layout/layout'
import { AuthPage, GetAccessCode } from '@app/pages'
import { IRoute, routes } from '@app/routes'
import RouteGuard from '@app/shared/route-guard/route-guard'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LayoutErrorBoundary } from './layout/components'
import useScrollToTop from '@app/hooks/useScrollToTop'
import ChildDevelopmentPrint from '@app/pages/child-stats/components/child-development-print'

function App() {
  useScrollToTop()
  const mapRoutes = (routes: IRoute | undefined) => {
    return (
      routes &&
      Object.values(routes).map(({ path, component, roles, children }) => (
        <Route
          key={path}
          path={path}
          element={
            <RouteGuard component={component} roles={roles} path={path} />
          }
        >
          {mapRoutes(children)}
        </Route>
      ))
    )
  }

  return (
    <LayoutErrorBoundary>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/profile" />} />
          {mapRoutes(routes as any)}
        </Route>
        <Route path={'/auth'} element={<AuthPage />} />
        <Route path={'/get-access-code'} element={<GetAccessCode />} />
        <Route
          path="/organization/:id/child-stats/pupils-readiness-map"
          element={<ChildDevelopmentPrint />}
        />
      </Routes>
    </LayoutErrorBoundary>
  )
}

export default App
