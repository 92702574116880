import useLocalization from '@app/hooks/useLocalization'
import { IOrganization } from '@app/interfaces/organization/organization'
import ThreeDots from '@app/shared/loader/ThreeDots'
import { useGetOrganizationLocalitiesQuery } from '@app/store/rtk/api/organization/organization-locality-api'
import { sortByName } from '@app/utils/sort-by-name/sort-by-name'
import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  organization?: IOrganization
  disabled?: boolean
}

const OrganizationLocalityItem: React.FC<props> = ({
  organization,
  disabled,
}) => {
  const [t] = useTranslation()
  const { localize } = useLocalization()
  const { data, isLoading } = useGetOrganizationLocalitiesQuery('')

  return (
    <>
      {isLoading ? (
        <ThreeDots style="dot-pulse" height={86} padding={24} />
      ) : (
        <Form.Item
          label={t('organizationLocality.organizationLocality')}
          name={'locality'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          initialValue={
            localize(data?.response as any)?.find(
              (locality) => locality.id === organization?.locality?.id,
            )?.id
          }
        >
          <Select allowClear={true} disabled={disabled}>
            {sortByName(localize(data?.response as any))?.map((locality) => {
              if (locality.status) {
                return (
                  <Select.Option key={locality.id} value={locality.id}>
                    {locality.data.name}
                  </Select.Option>
                )
              }
              return null
            })}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

export default OrganizationLocalityItem
