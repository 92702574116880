import EmployeeRepository from '@app/api/repositories/employee-repository'
import { IEmployee } from '@app/interfaces/employee/employee'
import ModalFooter from '@app/shared/modal-footer/modal-footer'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { refreshEmployee } from '@app/store/actions/employee-actions'
import { updatePupil } from '@app/store/actions/pupils-actions'
import { Button, Form, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

interface props {
  employee?: IEmployee
  organizationId?: number
}

const RemoveEmployeeAvatarForm: React.FC<props> = ({ employee, organizationId }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t, i18n] = useTranslation()

  const showModal = () => {
    setVisible(true)
  }
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()
  const onFinish = async (values: any) => {
    const newValues = {
      id: employee?.id,
      fileId: null,
      ...(organizationId
        ? {
            organizationId,
          }
        : {}),
    }
    setConfirmLoading(true)
    const avatarApi = EmployeeRepository.partialUpdate
    await avatarApi(employee ? newValues : values)
      .then((response: AxiosResponse<IEmployee>) => {
        if (response.status === 200) {
          dispatch(updatePupil(response.data))
          openNotificationWithIcon('success', t('success.updated'))
          setConfirmLoading(false)
          dispatch(refreshEmployee())
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} size={'small'} type={'dashed'}>
        {t('users.removeAvatar')}
      </Button>
      <Modal
        open={visible}
        title={t('users.removeAvatar')}
        onCancel={confirmLoading ? undefined : hideModal}
        width={400}
        confirmLoading={confirmLoading}
        footer={[
          <React.Fragment key={1}>
            <ModalFooter
              confirm={confirmLoading}
              onCancel={hideModal}
              onSubmit={form.submit}
              remove={true}
            />
          </React.Fragment>,
        ]}
      >
        <Spin spinning={confirmLoading}>
          <Form form={form} onFinish={onFinish} layout={'vertical'}>
            <Form.Item>
              <div>{t('users.removeAccept')}</div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}
export default connect(null, null)(RemoveEmployeeAvatarForm)
