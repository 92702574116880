import { Button } from 'antd'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmployeesOrganizationTable from './components/employee-organization-table/employees-organization-table'
import EmployeeSickLeaveTable from './components/employee-sick-leave-table/employee-sick-leave-table'

export const EMPLOYEE_STATUSES = 'WORKING,DECREE,VACATION,MEDICAL'

const EmployeesOrganization: React.FC = () => {
  const [t] = useTranslation()
  const [view, setView] = useState<
    'employee-list' | 'sice-leave' | 'mobile-users' | 'employee-applications'
  >('employee-list')

  const renderTabs = useMemo(() => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button
          onClick={() => setView('employee-list')}
          type={view === 'employee-list' ? 'primary' : 'default'}>
          {t('employee.title')}
        </Button>
        <Button
          onClick={() => setView('sice-leave')}
          type={view === 'sice-leave' ? 'primary' : 'default'}>
          {t('employee.sickLists')}
        </Button>
        <Button
          onClick={() => setView('employee-applications')}
          type={view === 'employee-applications' ? 'primary' : 'default'}>
          {t('employee.employeeApplications')}
        </Button>
      </div>
    )
  }, [view])

  return (
    <>
    <EmployeesOrganizationTable />
      {/* <div className="header-form">{renderTabs}</div>
      {view === 'employee-list' && <EmployeesOrganizationTable />}
      {view === 'sice-leave' && <EmployeeSickLeaveTable />}
      {view === 'employee-applications' && <div />} */}
    </>
  )
}

export default EmployeesOrganization
