import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { connect } from 'react-redux'
import useQuery from '@app/hooks/useQuery'
import useLocalization from '@app/hooks/useLocalization'
import { EMPLOYEE_STATUSES } from '../../employees-organization-table'

interface props {
  request: (string) => void
  load: boolean
  statuses?: any[]
  positions?: any[]
  searchParams: URLSearchParams

  totalCount: number
  totalUploadedPhotos: number
}

const EmployeeOrganizationFilter: React.FC<props> = ({
  statuses,
  positions,
  request,
  load,
  searchParams,
  totalCount,
  totalUploadedPhotos,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { Option } = Select
  const { serializeQuery } = useQuery()
  const { localizeData } = useLocalization()
  const onFinish = (values: any) => {
    const newValues = {
      'employeePositionStatusType.in': values.employeePositionStatusType
        ? values.employeePositionStatusType.join(',')
        : EMPLOYEE_STATUSES,
      'employeePositionDictionaryId.in': values.employeePositionDictionaryId
        ? values.employeePositionDictionaryId.join(',')
        : undefined,
      'pin.equals': values.pin ? values.pin : undefined,
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
  }

  const onReset = () => {
    const newValues = {
      'employeePositionStatusType.in': '',
      'employeePositionDictionaryId.in': '',
      'pin.equals': '',
    }
    request(
      serializeQuery({
        query: newValues,
        searchParams: searchParams.toString(),
      }),
    )
    form.resetFields()
  }
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      onReset={() => form.resetFields()}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('employeeStatus.title')}
            name={'employeePositionStatusType'}>
            <Select
              allowClear={true}
              showArrow={true}
              showSearch={false}
              mode={'multiple'}>
              {statuses?.map((status) => {
                return (
                  <Option key={status.id} value={status.type}>
                    {localizeData(status.data)?.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('employeePosition.title')}
            name={'employeePositionDictionaryId'}>
            <Select
              allowClear={true}
              showArrow={true}
              showSearch={false}
              mode={'multiple'}>
              {positions?.map((position) => {
                return (
                  <Option key={position.id} value={position.id}>
                    {localizeData(position.data)?.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={[
              {
                transform: (value) =>
                  typeof value === 'string' ? value.trim() : undefined,
              },
            ]}
            name={'pin'}
            label={t('employee.pin')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Button disabled={!load} type="primary" htmlType="submit">
            <FilterOutlined />
            {t('general.filter')}
          </Button>
          <Button
            disabled={!load}
            htmlType={'reset'}
            style={{ margin: '0 8px' }}
            onClick={onReset}>
            {t('general.reset')}
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <div>
            <h4>
              {t('general.totalCount')}: {totalCount}
            </h4>
            {/* <h4>
              {t('general.uploadedPhotos')}: {totalUploadedPhotos}
            </h4> */}
          </div>
        </Col>
      </Row>
    </Form>
  )
}

const mapStateToProps = (state) => {
  return {
    districtsById: state.districts.districtsByRegionId,
  }
}

export default connect(mapStateToProps)(EmployeeOrganizationFilter)
