import { FAILED, IDLE, LOADING, SUCCEEDED } from '@app/constants/redux-state'
import { IOrganizationMobileUsersState } from '@app/interfaces/organization-mobile-users'
import {
  CLEAR_ORGANIZATION_MOBILE_USERS,
  CREATE_ORGANIZATION_MOBILE_USER,
  DELETE_ORGANIZATION_MOBILE_USER,
  ERROR_ORGANIZATION_MOBILE_USERS,
  FETCH_ORGANIZATION_MOBILE_USERS,
  SUCCESS_ORGANIZATION_MOBILE_USERS,
  UPDATE_ORGANIZATION_MOBILE_USER,
} from '@app/store/types/organization-mobile-users'

const initialState: IOrganizationMobileUsersState = {
  mobileUsers: [],
  state: IDLE,
  count: 0,
}

export const organizationMobileUsersReducer = (
  state = initialState,
  action,
): IOrganizationMobileUsersState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_MOBILE_USERS:
      return {
        ...state,
        state: LOADING,
      }
    case SUCCESS_ORGANIZATION_MOBILE_USERS:
      return {
        ...state,
        mobileUsers: action.payload.mobileUsers,
        count: action.payload.count,
        state: SUCCEEDED,
      }
    case ERROR_ORGANIZATION_MOBILE_USERS:
      return {
        ...state,
        state: FAILED,
      }
    case CREATE_ORGANIZATION_MOBILE_USER:
      return {
        ...state,
        mobileUsers: [...(state.mobileUsers ?? []), action.payload],
        count: (state.count ?? 0) + 1,
        state: SUCCEEDED,
      }
    case DELETE_ORGANIZATION_MOBILE_USER:
      return {
        ...state,
        mobileUsers: (state.mobileUsers ?? []).filter(
          (user) => user.id !== action.payload,
        ),
        count: (state.count ?? 0) - 1,
        state: SUCCEEDED,
      }
    case UPDATE_ORGANIZATION_MOBILE_USER:
      return {
        ...state,
        mobileUsers: (state.mobileUsers ?? []).map((user) => {
          if (user.id === action.payload.id) {
            return {
              ...user,
              ...action.payload,
            }
          }
          return user
        }),
        state: SUCCEEDED,
      }
    case CLEAR_ORGANIZATION_MOBILE_USERS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
