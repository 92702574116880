import organizationMobileUsersRepository from '@api/repositories/organization-mobile-users-repository'
import { IChangeOrganizationMobileUserPassword } from '@app/interfaces/organization-mobile-users'
import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface UpdatePasswordMobileUserProps {
  visible: boolean
  onCancel: () => void
  userId: string
}

export const UpdatePasswordMobileUser: React.FC<
  UpdatePasswordMobileUserProps
> = ({ visible, onCancel, userId }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (
    values: IChangeOrganizationMobileUserPassword,
  ) => {
    try {
      setLoading(true)
      await organizationMobileUsersRepository.updatePassword({
        ...values,
        userId,
      })
      form.resetFields()
      onCancel()
    } catch (error) {
      console.error('Error updating password:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      title={t('mobileUsers.update_password')}
      onCancel={onCancel}
      footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="currentPassword"
          label={t('mobileUsers.currentPassword')}
          rules={[
            { required: true, message: t('errors.requiredMessage') },
            {
              min: 4,
              max: 255,
              message: t('errors.minMaxMessage'),
            },
          ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label={t('mobileUsers.newPassword')}
          rules={[
            { required: true, message: t('errors.requiredMessage') },
            {
              min: 4,
              max: 255,
              message: t('errors.minMaxMessage'),
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item className="text-right">
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            {t('general.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('general.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
