import PupilsRepository from '@app/api/repositories/pupils-repository'
import { IFile } from '@app/interfaces/files'
import { IPupil } from '@app/interfaces/pupil/pupils'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import PhotoItem from '@app/shared/photo-item/photo-item'
import { refreshOrganizationPupils } from '@app/store/actions/organization-pupils-actions'
import { updatePupil } from '@app/store/actions/pupils-actions'
import { Button, Form, Modal, Spin } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface props {
  pupil: IPupil
  avatar: IFile
  edit?: boolean

  additionalValues?: {
    organizationId?: number
    groupId?: number
  }
}

const AvatarForm: React.FC<props> = ({ pupil, avatar, edit, additionalValues }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [t] = useTranslation()

  const showModal = () => {
    setVisible(true)
  }
  const hideModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const dispatch = useDispatch()

  const onFinish = async (id: number) => {
    const newValues = {
      id: pupil.id,
      fileId: id,
      ...(additionalValues ? additionalValues : {})
    }
    setConfirmLoading(true)
    const avatarApi = PupilsRepository.partialUpdate
    await avatarApi(newValues)
      .then((response: AxiosResponse<IPupil>) => {
        if (response.status === 200) {
          dispatch(updatePupil(response.data))
          openNotificationWithIcon('success', t('success.updated'))
          setConfirmLoading(false)
          dispatch(refreshOrganizationPupils())
        }
        hideModal()
      })
      .catch(() => {
        setConfirmLoading(false)
        openNotificationWithIcon('error', t('errors.error'))
      })
  }

  return (
    <React.Fragment>
      <Button onClick={showModal} size={'small'} type={'dashed'}>
        {edit ? t('users.changeAvatar') : t('users.addAvatar')}
      </Button>

      <Modal
        open={visible}
        title={edit ? t('users.changeAvatar') : t('users.addAvatar')}
        onCancel={confirmLoading ? undefined : hideModal}
        width={400}
        confirmLoading={confirmLoading}
        footer={[<React.Fragment key={1}></React.Fragment>]}>
        <Spin spinning={confirmLoading}>
          <Form form={form} onFinish={onFinish} layout={'vertical'}>
            <Form.Item className={'avatar__item'} name={'fileId'}>
              <PhotoItem
                upload={true}
                onSubmit={onFinish}
                closeModal={hideModal}
                isLoading={setConfirmLoading}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  )
}
export default AvatarForm
