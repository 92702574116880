export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  SUSER: 'ROLE_SUSER',

  // Отчеты
  RR_CHILD_COUNT_BY_INSTITUTION: 'RR_CHILD_COUNT_BY_INSTITUTION',
  RR_CHILD_COUNT_BY_GROUP: 'RR_CHILD_COUNT_BY_GROUP',
  RR_CHILD_COUNT_BY_AGE: 'RR_CHILD_COUNT_BY_AGE',
  RR_CHILD_COUNT_ON_QUEUE_BY_AGE: 'RR_CHILD_COUNT_ON_QUEUE_BY_AGE',
  RR_HEIGHT_WEIGHT_DATA_ENTRY_STATUS_BY_GROUPS:
    'RR_HEIGHT_WEIGHT_DATA_ENTRY_STATUS_BY_GROUPS',
  RR_CONTRACT_N_CHILD_INFO_OF_PARTNERSHIP_ORGANIZATIONS:
    'RR_CONTRACT_N_CHILD_INFO_OF_PARTNERSHIP_ORGANIZATIONS',
  RR_APPS_COUNT_ON_QUEUE_BY_CHILD_AGE: 'RR_APPS_COUNT_ON_QUEUE_BY_CHILD_AGE',

  ROLE_SYSTEM: 'ROLE_SYSTEM',
  ROLE_ANONYMOUS: 'ROLE_ANONYMOUS',
  // Заведующий
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_REGION_IKT: 'ROLE_REGION_IKT',
  ROLE_DISTRICT_IKT: 'ROLE_DISTRICT_IKT',

  // Dictionaries
  ROLE_SETTING_DICTIONARY_VIEW: 'ROLE_SETTING_DICTIONARY_VIEW',
  ROLE_SETTING_DICTIONARY_ADD: 'ROLE_SETTING_DICTIONARY_ADD',
  ROLE_SETTING_DICTIONARY_EDIT: 'ROLE_SETTING_DICTIONARY_EDIT',
  ROLE_SETTING_DICTIONARY_DELETE: 'ROLE_SETTING_DICTIONARY_DELETE',

  // Organizations
  ROLE_ORGANIZATION_ALL_VIEW: 'ROLE_ORGANIZATION_ALL_VIEW',
  ROLE_ORGANIZATION_REGION_VIEW: 'ROLE_ORGANIZATION_REGION_VIEW',
  ROLE_ORGANIZATION_DISTRICT_VIEW: 'ROLE_ORGANIZATION_DISTRICT_VIEW',
  ROLE_ORGANIZATION_CURRENT_VIEW: 'ROLE_ORGANIZATION_CURRENT_VIEW',

  // Add Organizations
  ROLE_ORGANIZATION_ALL_ADD: 'ROLE_ORGANIZATION_ALL_ADD',
  ROLE_ORGANIZATION_REGION_ADD: 'ROLE_ORGANIZATION_REGION_ADD',
  ROLE_ORGANIZATION_DISTRICT_ADD: 'ROLE_ORGANIZATION_DISTRICT_ADD',

  // Edit Organizations
  ROLE_ORGANIZATION_CLASSIFICATOR_EDIT: 'ROLE_ORGANIZATION_CLASSIFICATOR_EDIT',
  ROLE_ORGANIZATION_ALL_EDIT: 'ROLE_ORGANIZATION_ALL_EDIT',
  ROLE_ORGANIZATION_REGION_EDIT: 'ROLE_ORGANIZATION_REGION_EDIT',
  ROLE_ORGANIZATION_DISTRICT_EDIT: 'ROLE_ORGANIZATION_DISTRICT_EDIT',
  ROLE_ORGANIZATION_CURRENT_EDIT: 'ROLE_ORGANIZATION_CURRENT_EDIT',
  ROLE_ORGANIZATION_EMISAPI_STATUS_CHANGE:
    'ROLE_ORGANIZATION_EMISAPI_STATUS_CHANGE',
  ROLE_ORGANIZATION_NAME_CHANGE: 'ROLE_ORGANIZATION_NAME_CHANGE',
  ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE:
    'ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE',
  ROLE_ORGANIZATION_NEIGHBORHOOD_CHANGE:
    'ROLE_ORGANIZATION_NEIGHBORHOOD_CHANGE',

  // Employee
  ROLE_EMPLOYEE_ALL_VIEW: 'ROLE_EMPLOYEE_ALL_VIEW',
  ROLE_EMPLOYEE_REGION_VIEW: 'ROLE_EMPLOYEE_REGION_VIEW',
  ROLE_EMPLOYEE_DISTRICT_VIEW: 'ROLE_EMPLOYEE_DISTRICT_VIEW',
  ROLE_EMPLOYEE_CURRENT_VIEW: 'ROLE_EMPLOYEE_CURRENT_VIEW',

  // Organizationsda mobile user yaratish uchun rolni shu joyga yozaman

  // Add EMPLOYEE
  ROLE_EMPLOYEE_ALL_ADD: 'ROLE_EMPLOYEE_ALL_ADD',
  ROLE_EMPLOYEE_REGION_ADD: 'ROLE_EMPLOYEE_REGION_ADD',
  ROLE_EMPLOYEE_DISTRICT_ADD: 'ROLE_EMPLOYEE_DISTRICT_ADD',

  // Edit EMPLOYEE
  ROLE_EMPLOYEE_ALL_EDIT: 'ROLE_EMPLOYEE_ALL_EDIT',
  ROLE_EMPLOYEE_REGION_EDIT: 'ROLE_EMPLOYEE_REGION_EDIT',
  ROLE_EMPLOYEE_DISTRICT_EDIT: 'ROLE_EMPLOYEE_DISTRICT_EDIT',
  ROLE_EMPLOYEE_CURRENT_EDIT: 'ROLE_EMPLOYEE_CURRENT_EDIT',

  // PUPIL
  ROLE_PUPIL_ALL_VIEW: 'ROLE_PUPIL_ALL_VIEW',
  ROLE_PUPIL_REGION_VIEW: 'ROLE_PUPIL_REGION_VIEW',
  ROLE_PUPIL_DISTRICT_VIEW: 'ROLE_PUPIL_DISTRICT_VIEW',
  ROLE_PUPIL_CURRENT_VIEW: 'ROLE_PUPIL_CURRENT_VIEW',

  // Add PUPIL
  ROLE_PUPIL_ALL_ADD: 'ROLE_PUPIL_ALL_ADD',
  ROLE_PUPIL_REGION_ADD: 'ROLE_PUPIL_REGION_ADD',
  ROLE_PUPIL_DISTRICT_ADD: 'ROLE_PUPIL_DISTRICT_ADD',

  // Edit PUPIL
  ROLE_PUPIL_ALL_EDIT: 'ROLE_PUPIL_ALL_EDIT',
  ROLE_PUPIL_REGION_EDIT: 'ROLE_PUPIL_REGION_EDIT',
  ROLE_PUPIL_DISTRICT_EDIT: 'ROLE_PUPIL_DISTRICT_EDIT',
  ROLE_PUPIL_CURRENT_EDIT: 'ROLE_PUPIL_CURRENT_EDIT',

  // Education Group View

  ROLE_EDUCATION_GROUP_ALL_VIEW: 'ROLE_EDUCATION_GROUP_ALL_VIEW',
  ROLE_EDUCATION_GROUP_REGION_VIEW: 'ROLE_EDUCATION_GROUP_REGION_VIEW',
  ROLE_EDUCATION_GROUP_DISTRICT_VIEW: 'ROLE_EDUCATION_GROUP_DISTRICT_VIEW',
  ROLE_EDUCATION_GROUP_CURRENT_VIEW: 'ROLE_EDUCATION_GROUP_CURRENT_VIEW',

  //Education Group ADD/EDIT
  ROLE_EDUCATION_GROUP_CLASSIFICATOR_EDIT:
    'ROLE_EDUCATION_GROUP_CLASSIFICATOR_EDIT',
  ROLE_EDUCATION_GROUP_ALL_EDIT: 'ROLE_EDUCATION_GROUP_ALL_EDIT',
  ROLE_EDUCATION_GROUP_REGION_EDIT: 'ROLE_EDUCATION_GROUP_REGION_EDIT',
  ROLE_EDUCATION_GROUP_DISTRICT_EDIT: 'ROLE_EDUCATION_GROUP_DISTRICT_EDIT',
  ROLE_EDUCATION_GROUP_CURRENT_EDIT: 'ROLE_EDUCATION_GROUP_CURRENT_EDIT',

  /**
   * Отчисление воспитанников
   */

  ROLE_PUPIL_ALL_DISMISS: 'ROLE_PUPIL_ALL_DISMISS',
  ROLE_PUPIL_REGION_DISMISS: 'ROLE_PUPIL_REGION_DISMISS',
  ROLE_PUPIL_DISTRICT_DISMISS: 'ROLE_PUPIL_DISTRICT_DISMISS',
  ROLE_PUPIL_CURRENT_DISMISS: 'ROLE_PUPIL_CURRENT_DISMISS',

  /**
   * Принятие воспитанников по направлению
   */

  ROLE_PUPIL_ALL_APPROVED: 'ROLE_PUPIL_ALL_APPROVED',
  ROLE_PUPIL_REGION_APPROVED: 'ROLE_PUPIL_REGION_APPROVED',
  ROLE_PUPIL_DISTRICT_APPROVED: 'ROLE_PUPIL_DISTRICT_APPROVED',
  ROLE_PUPIL_CURRENT_APPROVED: 'ROLE_PUPIL_CURRENT_APPROVED',

  /**
   * Просмотр посещения
   */
  ROLE_ATTENDANCE_ALL_VIEW: 'ROLE_ATTENDANCE_ALL_VIEW',
  ROLE_ATTENDANCE_REGION_VIEW: 'ROLE_ATTENDANCE_REGION_VIEW',
  ROLE_ATTENDANCE_DISTRICT_VIEW: 'ROLE_ATTENDANCE_DISTRICT_VIEW',
  ROLE_ATTENDANCE_CURRENT_VIEW: 'ROLE_ATTENDANCE_CURRENT_VIEW',

  /**
   * Изменение причин отсутствия
   */
  ROLE_ATTENDANCE_REASON_ALL_EDIT: 'ROLE_ATTENDANCE_REASON_ALL_EDIT',
  ROLE_ATTENDANCE_REASON_REGION_EDIT: 'ROLE_ATTENDANCE_REASON_REGION_EDIT',
  ROLE_ATTENDANCE_REASON_DISTRICT_EDIT: 'ROLE_ATTENDANCE_REASON_DISTRICT_EDIT',
  ROLE_ATTENDANCE_REASON_CURRENT_EDIT: 'ROLE_ATTENDANCE_REASON_CURRENT_EDIT',

  /**
   * Просмотр мониторинга посещения
   */
  ROLE_MONITOR_ATTENDANCE_ALL_VIEW: 'ROLE_MONITOR_ATTENDANCE_ALL_VIEW',
  ROLE_MONITOR_ATTENDANCE_REGION_VIEW: 'ROLE_MONITOR_ATTENDANCE_REGION_VIEW',
  ROLE_MONITOR_ATTENDANCE_DISTRICT_VIEW:
    'ROLE_MONITOR_ATTENDANCE_DISTRICT_VIEW',
  ROLE_MONITOR_ATTENDANCE_CURRENT_VIEW: 'ROLE_MONITOR_ATTENDANCE_CURRENT_VIEW',

  // Menu View
  ROLE_MENU_ALL_VIEW: 'ROLE_MENU_ALL_VIEW',
  ROLE_MENU_REGION_VIEW: 'ROLE_MENU_REGION_VIEW',
  ROLE_MENU_DISTRICT_VIEW: 'ROLE_MENU_DISTRICT_VIEW',
  ROLE_MENU_CURRENT_VIEW: 'ROLE_MENU_CURRENT_VIEW',

  // Menu Edit
  ROLE_MENU_ALL_EDIT: 'ROLE_MENU_ALL_EDIT',
  ROLE_MENU_REGION_EDIT: 'ROLE_MENU_REGION_EDIT',
  ROLE_MENU_DISTRICT_EDIT: 'ROLE_MENU_DISTRICT_EDIT',
  ROLE_MENU_CURRENT_EDIT: 'ROLE_MENU_CURRENT_EDIT',

  // Menu Subsidies
  ROLE_CONTRACT_SUBSIDY_ALL_VIEW: 'ROLE_CONTRACT_SUBSIDY_ALL_VIEW',
  ROLE_CONTRACT_SUBSIDY_REGION_VIEW: 'ROLE_CONTRACT_SUBSIDY_REGION_VIEW',
  ROLE_CONTRACT_SUBSIDY_DISTRICT_VIEW: 'ROLE_CONTRACT_SUBSIDY_DISTRICT_VIEW',
  ROLE_CONTRACT_SUBSIDY_CURRENT_VIEW: 'ROLE_CONTRACT_SUBSIDY_CURRENT_VIEW',
  ROLE_CONTRACT_SUBSIDY_ALL_EDIT: 'ROLE_CONTRACT_SUBSIDY_ALL_EDIT',
  ROLE_CONTRACT_SUBSIDY_REGION_EDIT: 'ROLE_CONTRACT_SUBSIDY_REGION_EDIT',
  ROLE_CONTRACT_SUBSIDY_DISTRICT_EDIT: 'ROLE_CONTRACT_SUBSIDY_DISTRICT_EDIT',
  ROLE_CONTRACT_SUBSIDY_CURRENT_EDIT: 'ROLE_CONTRACT_SUBSIDY_CURRENT_EDIT',

  // Menu growth
  ROLE_HEIGHT_WEIGHT_ALL_VIEW: 'ROLE_HEIGHT_WEIGHT_ALL_VIEW',
  ROLE_HEIGHT_WEIGHT_DISTRICT_VIEW: 'ROLE_HEIGHT_WEIGHT_DISTRICT_VIEW',
  ROLE_HEIGHT_WEIGHT_REGION_VIEW: 'ROLE_HEIGHT_WEIGHT_REGION_VIEW',
  ROLE_HEIGHT_WEIGHT_CURRENT_VIEW: 'ROLE_HEIGHT_WEIGHT_CURRENT_VIEW',
  ROLE_HEIGHT_WEIGHT_ALL_EDIT: 'ROLE_HEIGHT_WEIGHT_ALL_EDIT',
  ROLE_HEIGHT_WEIGHT_REGION_EDIT: 'ROLE_HEIGHT_WEIGHT_REGION_EDIT',
  ROLE_HEIGHT_WEIGHT_DISTRICT_EDIT: 'ROLE_HEIGHT_WEIGHT_DISTRICT_EDIT',
  ROLE_HEIGHT_WEIGHT_CURRENT_EDIT: 'ROLE_HEIGHT_WEIGHT_CURRENT_EDIT',
  ROLE_HEIGHT_WEIGHT_STAT_ALL_VIEW: 'ROLE_HEIGHT_WEIGHT_STAT_ALL_VIEW',
  ROLE_HEIGHT_WEIGHT_STAT_REGION_VIEW: 'ROLE_HEIGHT_WEIGHT_STAT_REGION_VIEW',
  ROLE_HEIGHT_WEIGHT_STAT_DISTRICT_VIEW:
    'ROLE_HEIGHT_WEIGHT_STAT_DISTRICT_VIEW',
  ROLE_HEIGHT_WEIGHT_STAT_CURRENT_VIEW: 'ROLE_HEIGHT_WEIGHT_STAT_CURRENT_VIEW',

  // Menu growth
  ROLE_FURNITURE_TEMP_VIEW: 'ROLE_FURNITURE_TEMP_VIEW',

  // Passportization
  ROLE_PASSPORTIZATION_ALL_VIEW: 'ROLE_PASSPORTIZATION_ALL_VIEW',
  ROLE_PASSPORTIZATION_REGION_VIEW: 'ROLE_PASSPORTIZATION_REGION_VIEW',
  ROLE_PASSPORTIZATION_DISTRICT_VIEW: 'ROLE_PASSPORTIZATION_DISTRICT_VIEW',
  ROLE_PASSPORTIZATION_CURRENT_VIEW: 'ROLE_PASSPORTIZATION_CURRENT_VIEW',

  ROLE_PASSPORTIZATION_ALL_EDIT: 'ROLE_PASSPORTIZATION_ALL_EDIT',
  ROLE_PASSPORTIZATION_REGION_EDIT: 'ROLE_PASSPORTIZATION_REGION_EDIT',
  ROLE_PASSPORTIZATION_DISTRICT_EDIT: 'ROLE_PASSPORTIZATION_DISTRICT_EDIT',
  ROLE_PASSPORTIZATION_CURRENT_EDIT: 'ROLE_PASSPORTIZATION_CURRENT_EDIT',

  ROLE_ORGANIZATION_CONTACT_ALL_EDIT: 'ROLE_ORGANIZATION_CONTACT_ALL_EDIT',
  ROLE_ORGANIZATION_CONTACT_REGION_EDIT:
    'ROLE_ORGANIZATION_CONTACT_REGION_EDIT',
  ROLE_ORGANIZATION_CONTACT_DISTRICT_EDIT:
    'ROLE_ORGANIZATION_CONTACT_DISTRICT_EDIT',
  ROLE_ORGANIZATION_CONTACT_CURRENT_EDIT:
    'ROLE_ORGANIZATION_CONTACT_CURRENT_EDIT',

  ROLE_STAFF_TABLE_ALL_VIEW: 'ROLE_STAFF_TABLE_ALL_VIEW',
  ROLE_STAFF_TABLE_REGION_VIEW: 'ROLE_STAFF_TABLE_REGION_VIEW',
  ROLE_STAFF_TABLE_DISTRICT_VIEW: 'ROLE_STAFF_TABLE_DISTRICT_VIEW',
  ROLE_STAFF_TABLE_CURRENT_VIEW: 'ROLE_STAFF_TABLE_CURRENT_VIEW',

  ROLE_APPLICATION_ALL_CANCEL: 'ROLE_APPLICATION_ALL_CANCEL',
  ROLE_APPLICATION_SEND: 'ROLE_APPLICATION_SEND',
  ROLE_PLANNER_HISTORY_VIEW: 'ROLE_PLANNER_HISTORY_VIEW',
  ROLE_PLANNER_CURRENT_STATUS_VIEW: 'ROLE_PLANNER_CURRENT_STATUS_VIEW',
  ROLE_PLANNER_MANAGEMENT: 'ROLE_PLANNER_MANAGEMENT',

  ROLE_DASH1_CURRENT_VIEW: 'ROLE_DASH1_CURRENT_VIEW',

  ROLE_PUPIL_READINESS_CREATE: 'ROLE_PUPIL_READINESS_CREATE',
}
