import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Row } from 'antd'
import useQuery from '@app/hooks/useQuery'

interface props {
  request: (string) => void
  showTable: boolean
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
}

const PupilsFilterForm: React.FC<props> = ({
  request,
  setShowTable,
  showTable,
}) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { serializeQuery } = useQuery()

  const onFinish = (values: any) => {
    !showTable && setShowTable(true)
    const newValues = {
      'id.equals': values.id,
      'pin.equals': values.pin,
      'phone.equals': values.phone,
      'metric.equals': values.metric,
    }
    request(serializeQuery({ query: newValues }))
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label={'ID'} name={'id'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pupils.pin')} name={'pin'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'metric'} label={t('pupils.metric')}>
            <Input />
          </Form.Item>
        </Col>
        <>
          <Col span={8}>
            <Form.Item label={t('pupils.phone')} name={'phone'}>
              <Input />
            </Form.Item>
          </Col>
        </>
      </Row>
      <div>
        <Button type="primary" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onFinish}
        >
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

export default PupilsFilterForm
