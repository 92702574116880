import { Alert, Button, Space } from 'antd'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

const replaceError = (key, value) => {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(
      (obj, propName) => {
        obj[propName] = value[propName]
        return obj
      },
      { name: value.name },
    )
  } else {
    return value
  }
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation()
  const [show, setShow] = useState(false)
  return (
    <Space style={{ width: '100%', justifyContent: 'center', height: '100vh' }}>
      <Alert
        message="Error Occured"
        showIcon
        description={show && `${JSON.stringify(error, replaceError, 2)}`}
        type="error"
        action={
          <Space wrap>
            <Button
              type="primary"
              onClick={() => {
                window.location.replace('/')
              }}
              size="small"
            >
              {t('general.back')}
            </Button>
            <Button type="primary" onClick={resetErrorBoundary} size="small">
              {t('general.error')}
            </Button>

            <Button size="small" danger onClick={() => setShow((p) => !p)}>
              {show ? t('general.hide') : t('general.show')}
            </Button>
          </Space>
        }
      />
    </Space>
  )
}

type Props = { children: React.ReactNode }

const LayoutErrorBoundary = ({ children }: Props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
}

export default LayoutErrorBoundary
