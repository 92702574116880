import organizationMobileUsersRepository from '@api/repositories/organization-mobile-users-repository'
import {
  IOrganizationMobileUser,
  IUpdateOrganizationMobileUser,
} from '@app/interfaces/organization-mobile-users'
import { optimisticUpdateMobileUsersState } from '@app/store/actions/organization-mobile-users-actions'
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface UpdateMobileUserProps {
  visible: boolean
  onCancel: () => void
  user: IOrganizationMobileUser
}

export const UpdateMobileUser: React.FC<UpdateMobileUserProps> = ({
  visible,
  onCancel,
  user,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onSuccess = () => {
    dispatch(
      optimisticUpdateMobileUsersState('update', {
        id: user.id,
        fullName: form.getFieldValue('fullName'),
        username: form.getFieldValue('username'),
      }),
    )
  }

  useEffect(() => {
    if (visible && user) {
      form.setFieldsValue({
        fullName: user.fullName,
        username: user.username,
      })
    }
  }, [visible, user, form])

  const handleSubmit = async (
    values: Omit<IUpdateOrganizationMobileUser, 'userId'>,
  ) => {
    try {
      setLoading(true)
      await organizationMobileUsersRepository.update({
        ...values,
        organizationId: user.organizationId,
        userId: user.id,
      })
      onSuccess()
      onCancel()
    } catch (error) {
      console.error('Error updating user:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      title={t('mobileUsers.edit_user')}
      onCancel={onCancel}
      footer={null}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="fullName"
          label={t('mobileUsers.fullName')}
          rules={[{ required: true, message: t('errors.requiredMessage') }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="username"
          label={t('mobileUsers.login')}
          rules={[
            { required: true, message: t('errors.requiredMessage') },
            {
              min: 4,
              max: 255,
              message: t('errors.minMaxMessage'),
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item className="text-right">
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            {t('general.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('general.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
