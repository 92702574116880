import { LOADING } from '@app/constants/redux-state'
import useMatchGroup from '@app/hooks/useMatchGroup'
import usePagination from '@app/hooks/usePagination'
import { getQueueApplications } from '@app/store/actions/application-actions'
import { RootState } from '@app/store/store'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Table } from 'antd'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Cell, Pie, PieChart } from 'recharts'
import './organization-queue-list.less'

interface params {
  id?: string
}

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#f28482',
  '#4a4e69',
  '#05668d',
]

const OrganizationQueueListPage: React.FC = () => {
  const params: params = useParams()
  const dispatch = useDispatch()
  const { queueApplications, state, count } = useSelector(
    (state: RootState) => state.queueApplications,
  )
  const [t] = useTranslation()
  const { searchParams, requestFunction, pageAndSize, setPage } = usePagination(
    `page=0&size=3000`,
    {
      page: 1,
      size: 10,
    },
  )
  const { diffYears } = useMatchGroup()
  setDocumentTitle(t('routes.pupilQueueList'))

  useEffect(() => {
    const source = axios.CancelToken.source()
    dispatch(
      getQueueApplications({ params: searchParams, source }, Number(params.id)),
    )
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [searchParams])

  const data = !isEmpty(queueApplications)
    ? queueApplications.map((app) => {
        return {
          key: app.id,
          id: app.id,
          applicationDate: app.applicationDate,
          applicationNo: app.applicationNo,
          benefit: app.benefit,
          birthDate: app.birthDate,
          fullName: app.fullName,
          metric: app.metric,
          status: app.status,
        }
      })
    : []

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => (
        <p>{(pageAndSize.page - 1) * pageAndSize.size + index + 1}</p>
      ),
    },
    {
      title: t('pupils.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName?.localeCompare(b.fullName),
        multiple: 1,
      },
    },
    {
      title: t('pupils.metric'),
      dataIndex: 'metric',
      key: 'metric',
      sorter: {
        compare: (a, b) => a.metric?.localeCompare(b.metric),
        multiple: 2,
      },
    },
    {
      title: t('pupils.queueList.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a, b) => a.status?.localeCompare(b.status),
        multiple: 3,
      },
      render: (status) => <p className="queue-list__table--status">{status}</p>,
    },
    {
      title: t('pupils.applicationDate'),
      dataIndex: 'applicationDate',
      key: 'applicationDate',
      sorter: {
        compare: (a, b) => a.applicationDate?.localeCompare(b.applicationDate),
        multiple: 4,
      },
    },
    {
      title: t('pupils.applicationNo'),
      dataIndex: 'applicationNo',
      key: 'applicationNo',
      sorter: {
        compare: (a, b) => a.applicationNo?.localeCompare(b.applicationNo),
        multiple: 5,
      },
    },
    {
      title: t('pupils.birthDate'),
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: {
        compare: (a, b) => a.birthDate?.localeCompare(b.birthDate),
        multiple: 6,
      },
    },
    {
      title: t('pupils.benefit'),
      dataIndex: 'benefit',
      key: 'benefit',
      sorter: {
        compare: (a, b) => a.benefit - b.benefit,
        multiple: 7,
      },
      render: (text, record, index) => (
        <p>
          {text
            ? t('pupils.queueList.benefitYes')
            : t('pupils.queueList.benefitNo')}
        </p>
      ),
    },
  ]

  const getPupilCountByYear = (year: number, data: any): number => {
    return data?.filter((app) =>
      year === 6
        ? diffYears(new Date(), new Date(app.birthDate)) >= year
        : diffYears(new Date(), new Date(app.birthDate)) === year,
    ).length
  }

  const getBenefitChartData = (apps: any): any[] => {
    return [
      { name: 'with benefit', value: apps.filter((app) => app.benefit).length },
      {
        name: 'with no benefit',
        value: apps.filter((app) => !app.benefit).length,
      },
    ]
  }

  const getPupilByYearsChartData = (apps: any): any[] => {
    return [
      { name: '0', value: getPupilCountByYear(0, apps) },
      { name: '1', value: getPupilCountByYear(1, apps) },
      { name: '2', value: getPupilCountByYear(2, apps) },
      { name: '3', value: getPupilCountByYear(3, apps) },
      { name: '4', value: getPupilCountByYear(4, apps) },
      { name: '5', value: getPupilCountByYear(5, apps) },
      { name: '6', value: getPupilCountByYear(6, apps) },
    ]
  }

  return (
    <div>
      <div className={'header-form queue-list'}>
        <div className="queue-list__left">
          <p className="queue-list__left--summary">
            {t('pupils.queueList.info')}
          </p>
          <p className="queue-list__left--total">
            {t('pupils.queueList.totalCount')}: {count}
          </p>
          <p className="queue-list__left--benefit">
            {`${t('pupils.queueList.benefitCount')}: ${
              queueApplications?.filter((app) => app.benefit)?.length
            }`}
          </p>
          <p className="queue-list__left--no-benefit">
            {`${t('pupils.queueList.noBenefitCount')}: ${
              queueApplications?.filter((app) => !app.benefit)?.length
            }`}
          </p>
        </div>
        <div className="queue-list__right">
          <div className="queue-list__right--ages">
            <PieChart height={250} width={250}>
              <Pie
                data={getPupilByYearsChartData(queueApplications)}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
                labelLine={true}
                isAnimationActive={false}
              >
                {getPupilByYearsChartData(queueApplications).map(
                  (entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ),
                )}
              </Pie>
            </PieChart>
            <div className="legend">
              <div className="legend__item zero">
                {t('pupils.queueList.ageZero')}
              </div>
              <div className="legend__item one">
                {t('pupils.queueList.ageOne')}
              </div>
              <div className="legend__item two">
                {t('pupils.queueList.ageTwo')}
              </div>
              <div className="legend__item three">
                {t('pupils.queueList.ageThree')}
              </div>
              <div className="legend__item four">
                {t('pupils.queueList.ageFour')}
              </div>
              <div className="legend__item five">
                {t('pupils.queueList.ageFive')}
              </div>
              <div className="legend__item six">
                {t('pupils.queueList.ageSixPlus')}
              </div>
            </div>
          </div>
          <div className="queue-list__right--benefit">
            <div className="legend">
              <div className="legend__item true">
                {t('pupils.queueList.benefitYes')}
              </div>
              <div className="legend__item false">
                {t('pupils.queueList.benefitNo')}
              </div>
            </div>
            <PieChart height={250} width={250}>
              <Pie
                data={getBenefitChartData(queueApplications)}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
                labelLine={true}
                isAnimationActive={false}
              >
                {getBenefitChartData(queueApplications).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
      </div>
      <Card>
        <Table
          className="queue-list__table"
          pagination={false}
          dataSource={data}
          loading={state === LOADING}
          columns={columns}
          rowClassName="table-row"
          // footer={() => (
          //   <Pagination
          //     request={requestFunction}
          //     totalCount={count}
          //     setPage={setPage}
          //     hideSizeChanger={true}
          //     staticSize={1000}
          //   />
          // )}
        />
      </Card>
    </div>
  )
}

export default OrganizationQueueListPage
