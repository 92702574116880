import { applicationReducer } from '@app/store/reducers/application-reducer'
import { camerasReducer } from '@app/store/reducers/cameras-reducer'
import { districtReducer } from '@app/store/reducers/districts-reducer'
import { educationDegreeReducer } from '@app/store/reducers/education-degree-reducer'
import { educationGradeReducer } from '@app/store/reducers/education-grade-reducer'
import { educationGroupRoomReducer } from '@app/store/reducers/education-group-room-reducer'
import { educationGroupsReducer } from '@app/store/reducers/education-groups-reducer'
import { educationLanguageReducer } from '@app/store/reducers/education-language-reducer'
import { employeeEducationReducer } from '@app/store/reducers/employee-education-reducer'
import { employeePositionReducer } from '@app/store/reducers/employee-position-reducer'
import { employeeRateReducer } from '@app/store/reducers/employee-rate-reducer'
import { employeeReducer } from '@app/store/reducers/employee-reducer'
import { employeeStatusReducer } from '@app/store/reducers/employee-status-reducer'
import { filesReducer } from '@app/store/reducers/files-reducer'
import { holidaysReducer } from '@app/store/reducers/holidays-reducer'
import { institutionPassportReducer } from '@app/store/reducers/institution-passport-reducer'
import { institutionSchedulesReducer } from '@app/store/reducers/institutions-schedules-reducer'
import { institutionsTypesReducer } from '@app/store/reducers/institutions-types-reducer'
import { langReducer } from '@app/store/reducers/languages-reducer'
import { nationalityReducer } from '@app/store/reducers/nationality-reducer'
import { neighborhoodReducer } from '@app/store/reducers/neighborhood-reducer'
import { organizationAttendanceMonitoringReducer } from '@app/store/reducers/organization-attendance-monitoring-reducer'
import { organizationAttendanceReducer } from '@app/store/reducers/organization-attendance-reducer'
import { organizationLocalityReducer } from '@app/store/reducers/organization-locality-reducer'
import { organizationMenuReducer } from '@app/store/reducers/organization-menu-reducer'
import { organizationPositionReducer } from '@app/store/reducers/organization-position-reducer'
import { organizationPupilsReducer } from '@app/store/reducers/organization-pupils-reducer'
import { organizationReducer } from '@app/store/reducers/organization-reducer'
import { organizationStatusReducer } from '@app/store/reducers/organization-status-reducer'
import { organizationTimesheetReducer } from '@app/store/reducers/organization-timesheet-reducer'
import { organizationTypesReducer } from '@app/store/reducers/organization-type-reducer'
import { organizationWarehouseReducer } from '@app/store/reducers/organization-warehouse-reducer'
import { publicDictionariesReducer } from '@app/store/reducers/public-dictionaries-reducer'
import { manualPupilAddReducer } from '@app/store/reducers/pupil-manual-add-reducer'
import { pupilsReducer } from '@app/store/reducers/pupils-reducer'
import { regionsReducer } from '@app/store/reducers/regions-reducer'
import { rolesReducer } from '@app/store/reducers/roles-reducer'
import { userInfoReducer } from '@app/store/reducers/user-info-reducer'
import { usersReducer } from '@app/store/reducers/user-reducer'
import organizationGrowthReducer from '@app/store/slices/organization-growth-slice'
import { combineReducers } from 'redux'
import { withReduxStateSync } from 'redux-state-sync'
import { benefitTypeReducer } from './reducers/benefit-type-reduces'
import { institutionSubtypeReducer } from './reducers/institution-subtype-reducer'
import { organizationBuildingTypeReducer } from './reducers/organization-building-type-reducer'
import { organizationContractSubsidyReducer } from './reducers/organization-contract-subsidy-reducer'
import { organizationScheduleReducer } from './reducers/organization-schedule-reducer'
import { organizationSectorReducer } from './reducers/organization-sector-reducer'
import { organizationMobileUsersReducer } from './reducers/organization-mobile-users-reducer'
import { emptyEducationApi } from './rtk/api/education'
import { emptyEmployeeApi } from './rtk/api/employee'
import { languageApi } from './rtk/api/languages-api'
import { nationalityApi } from './rtk/api/nationality-api'
import { emptyOrganizationApi } from './rtk/api/organization'
import { organizationGrowthApi } from './rtk/api/organization-growth-api'
import { emptyOrganizationPassportApi } from './rtk/api/organization-passport'
import { organizationPupilApi } from './rtk/api/organization-pupil-api'
import { reasonsApi } from './rtk/api/reasons-api'
import { userInfoApi } from './rtk/api/user-info-api'

const rootReducer = combineReducers({
  // users
  users: usersReducer,
  userInfo: userInfoReducer,
  roles: rolesReducer,
  [userInfoApi.reducerPath]: userInfoApi.reducer,

  // organizations
  organizations: organizationReducer,
  organizationPositions: organizationPositionReducer,
  organizationPupils: organizationPupilsReducer,
  organizationPassport: institutionPassportReducer,
  organizationCameras: camerasReducer,
  organizationAttendance: organizationAttendanceReducer,
  organizationAttendanceMonitoring: organizationAttendanceMonitoringReducer,
  organizationWarehouse: organizationWarehouseReducer,
  organizationMenus: organizationMenuReducer,
  organizationTimesheet: organizationTimesheetReducer,
  organizationStatuses: organizationStatusReducer,
  organizationLocality: organizationLocalityReducer,
  organizationTypes: organizationTypesReducer,
  organizationGrowth: organizationGrowthReducer,
  organizationSector: organizationSectorReducer,
  organizationSchedule: organizationScheduleReducer,
  organizationBuildingType: organizationBuildingTypeReducer,
  organizationContractSubsidy: organizationContractSubsidyReducer,
  institutionSchedules: institutionSchedulesReducer,
  institutionTypes: institutionsTypesReducer,
  institutionSubType: institutionSubtypeReducer,
  mobileUsers: organizationMobileUsersReducer,
  [emptyOrganizationApi.reducerPath]: emptyOrganizationApi.reducer,
  [emptyOrganizationPassportApi.reducerPath]:
    emptyOrganizationPassportApi.reducer,
  [organizationGrowthApi.reducerPath]: organizationGrowthApi.reducer,
  [organizationPupilApi.reducerPath]: organizationPupilApi.reducer,

  // dictionaries
  dictionaries: publicDictionariesReducer,
  regions: regionsReducer,
  langs: langReducer,
  districts: districtReducer,
  neighborhood: neighborhoodReducer,
  nationality: nationalityReducer,
  holidays: holidaysReducer,
  [nationalityApi.reducerPath]: nationalityApi.reducer,
  [reasonsApi.reducerPath]: reasonsApi.reducer,

  // education
  educationGroups: educationGroupsReducer,
  educationGroupRoom: educationGroupRoomReducer,
  educationDegrees: educationDegreeReducer,
  educationGrade: educationGradeReducer,
  educationLanguage: educationLanguageReducer,
  [emptyEducationApi.reducerPath]: emptyEducationApi.reducer,

  // employees
  employeeRate: employeeRateReducer,
  employeeStatus: employeeStatusReducer,
  employeeEducation: employeeEducationReducer,
  employeePosition: employeePositionReducer,
  organizationEmployee: employeeReducer,
  [emptyEmployeeApi.reducerPath]: emptyEmployeeApi.reducer,

  // pupils
  pupils: pupilsReducer,
  queueApplications: applicationReducer,
  manualPupilsAdd: manualPupilAddReducer,
  benefitType: benefitTypeReducer,
  [languageApi.reducerPath]: languageApi.reducer,

  // files
  files: filesReducer,
})

export default withReduxStateSync(rootReducer)
