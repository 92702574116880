import bbitApi, { bbitApiWithoutNotification } from '@api/make-request'
import {
  IUserRepository,
  numberArgs,
  IPathParamsArgs,
  stringArgs,
} from '@api/repositories/i-repository'
import { IUser } from '@app/interfaces/users'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

class UsersRepository implements IUserRepository<IUser> {
  create(data: IUser): Promise<AxiosResponse<IUser>> {
    return bbitApi.post('/api/v1/users', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IUser[]>> {
    return bbitApi.get(`/api/v1/users`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IUser>> {
    return bbitApi.get(`/api/v1/users/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  getOneByPin(args: stringArgs): Promise<AxiosResponse<IUser>> {
    return bbitApiWithoutNotification.get(`/api/v1/users/pin/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(data: IUser): Promise<AxiosResponse<IUser>> {
    return bbitApi.put(`/api/v1/users/${data.id}`, data)
  }

  passwordUpdate(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.put(`/api/v1/users/password-update/${data.id}`, data)
  }
}

export default new UsersRepository()
