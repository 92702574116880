import { IPathParamsArgs, numberArgs } from '@app/api/repositories/i-repository'
import organizationPupilRepository from '@app/api/repositories/organization-pupil-repository'
import { IOrganizationPupilsActions } from '@app/interfaces/pupil/organization-pupils'
import { getFile } from '@app/store/actions/files-actions'
import {
  CLEAR_ORGANIZATION_PUPIL,
  CLEAR_ORGANIZATION_PUPILS,
  DELETE_ORGANIZATION_PUPIL,
  FETCH_ORGANIZATION_PUPILS_FAILURE,
  FETCH_ORGANIZATION_PUPILS_FULL_FAILURE,
  FETCH_ORGANIZATION_PUPILS_FULL_REQUEST,
  FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS,
  FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE,
  FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST,
  FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS,
  FETCH_ORGANIZATION_PUPILS_REQUEST,
  FETCH_ORGANIZATION_PUPILS_SUCCESS,
  FETCH_ORGANIZATION_PUPIL_CLEANUP,
  FETCH_ORGANIZATION_PUPIL_FAILURE,
  FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE,
  FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST,
  FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS,
  FETCH_ORGANIZATION_PUPIL_REQUEST,
  FETCH_ORGANIZATION_PUPIL_SUCCESS,
  GET_QUEUE_COUNT,
  REFRESH_ORGANIZATION_PUPIL,
  REFRESH_ORGANIZATION_PUPIL_QUEUE,
  UPDATE_ORGANIZATION_PUPIL,
} from '@app/store/types/organization-pupils'
import axios from 'axios'

export const getOrganizationPupils =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPupilsRequest())
    await organizationPupilRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationPupilsSuccess(
            response.data,
            response.headers['x-total-count'],
            response.headers['x-total-count'], // TODO MYFIX-005 backenddan response headerda jami bolalarning nechtasida rasmk yuklangani soni kelishi kerak
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationPupilsFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchOrganizationPupilCleanup())
  }

export const getOrganizationPupil =
  (args: numberArgs, messages: { pupilError: string; fileError: string }) =>
  async (dispatch) => {
    dispatch(fetchOrganizationPupilRequest())
    await organizationPupilRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationPupilSuccess(response.data))
        if (response.data.pupil.fileId)
          dispatch(
            getFile(
              { data: response.data.pupil.fileId, source: args.source },
              messages.fileError,
            ),
          )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchOrganizationPupilFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchOrganizationPupilCleanup())
  }

export const getOrganizationPupilsFull =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPupilsFullRequest())
    await organizationPupilRepository
      .getAllFull({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationPupilsFullSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationPupilsFullFailure(error?.message, error?.code),
          )
        }
      })
    dispatch(fetchOrganizationPupilCleanup())
  }

export const getOrganizationPupilsQueue =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPupilsQueueRequest())
    await organizationPupilRepository
      .getAll({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchOrganizationPupilsQueueSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationPupilsQueueFailure(error?.message, error?.code),
          )
        }
      })
    dispatch(fetchOrganizationPupilCleanup())
  }

export const getOrganizationPupilQueue =
  (args: numberArgs, message: string) => async (dispatch) => {
    dispatch(fetchOrganizationPupilQueueRequest())
    await organizationPupilRepository
      .getOne(args)
      .then((response) => {
        dispatch(fetchOrganizationPupilQueueSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(
            fetchOrganizationPupilQueueFailure(error?.message, error?.code),
          )
        }
      })
    dispatch(fetchOrganizationPupilCleanup())
  }

const fetchOrganizationPupilsRequest = () => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_REQUEST,
  }
}

const fetchOrganizationPupilsSuccess = (pupils, count, imagesCount) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_SUCCESS,
    payload: { pupils, count, imagesCount },
  }
}

const fetchOrganizationPupilsFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPupilRequest = () => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_REQUEST,
  }
}

const fetchOrganizationPupilSuccess = (pupil) => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_SUCCESS,
    payload: pupil,
  }
}

const fetchOrganizationPupilFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPupilsQueueRequest = () => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_QUEUE_REQUEST,
  }
}

const fetchOrganizationPupilsQueueSuccess = (pupilsInQueue, count) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_QUEUE_SUCCESS,
    payload: { pupilsInQueue, count },
  }
}

const fetchOrganizationPupilsQueueFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_QUEUE_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPupilQueueRequest = () => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_QUEUE_REQUEST,
  }
}

const fetchOrganizationPupilQueueSuccess = (pupilInQueue) => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_QUEUE_SUCCESS,
    payload: pupilInQueue,
  }
}

const fetchOrganizationPupilQueueFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_QUEUE_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPupilsFullRequest = () => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_FULL_REQUEST,
  }
}

const fetchOrganizationPupilsFullSuccess = (pupilsFull, count) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_FULL_SUCCESS,
    payload: { pupilsFull, count },
  }
}

const fetchOrganizationPupilsFullFailure = (message, code) => {
  return {
    type: FETCH_ORGANIZATION_PUPILS_FULL_FAILURE,
    payload: { message, code },
  }
}

const fetchOrganizationPupilCleanup = () => {
  return {
    type: FETCH_ORGANIZATION_PUPIL_CLEANUP,
  }
}

export function getOrganizationQueue(pupil): IOrganizationPupilsActions {
  return {
    type: GET_QUEUE_COUNT,
    payload: pupil,
  }
}

export function clearOrganizationPupils() {
  return {
    type: CLEAR_ORGANIZATION_PUPILS,
    payload: null,
  }
}

export function refreshOrganizationPupils() {
  return {
    type: REFRESH_ORGANIZATION_PUPIL,
    payload: null,
  }
}

export function refreshOrganizationPupilsQueue() {
  return {
    type: REFRESH_ORGANIZATION_PUPIL_QUEUE,
    payload: null,
  }
}

export function clearOrganizationPupil() {
  return {
    type: CLEAR_ORGANIZATION_PUPIL,
    payload: null,
  }
}

export function updateOrganizationPupil(pupil): IOrganizationPupilsActions {
  return {
    type: UPDATE_ORGANIZATION_PUPIL,
    payload: pupil,
  }
}

export function deleteOrganizationPupil(pupil): IOrganizationPupilsActions {
  return {
    type: DELETE_ORGANIZATION_PUPIL,
    payload: pupil,
  }
}
