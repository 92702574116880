import bbitApi from '@api/make-request'
import {
  IOrganizationPupilRepository,
  IPathParamsArgs,
  numberArgs,
  stringArgs,
} from '@api/repositories/i-repository'
import {
  IOrganizationPupils,
  IOrganizationPupilsFull,
  IQueuePupilsCount,
} from '@app/interfaces/pupil/organization-pupils'
import { resolveParams } from '@app/utils/resolve-params/resolve-params'
import { AxiosResponse } from 'axios'

export interface IGroupChangeRequest {
  pupilId: number
  educationGroupId: number
  orderNo: string
  note: string
}

export interface IGroupChangeResponse {
  id: number
  status: string
  pupilId: number
}

class OrganizationPupilsRepository
  implements IOrganizationPupilRepository<IOrganizationPupils>
{
  create(
    data: IOrganizationPupils,
  ): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.post('/education/api/v1/pupil-education', data)
  }

  getAll(args: IPathParamsArgs): Promise<AxiosResponse<IOrganizationPupils[]>> {
    return bbitApi.get(`/education/api/v1/pupil-education`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getAllFull(
    args: IPathParamsArgs,
  ): Promise<AxiosResponse<IOrganizationPupilsFull[]>> {
    return bbitApi.get(`/education/api/v1/pupil-education/with-organization`, {
      params: resolveParams(args),
      cancelToken: args.source?.token,
    })
  }

  getOne(args: numberArgs): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.get(`/education/api/v1/pupil-education/${args.data}`, {
      cancelToken: args.source?.token,
    })
  }

  update(
    data: IOrganizationPupils,
  ): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.put(`/education/api/v1/pupil-education/${data.id}`, data)
  }

  approved(data: any): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.put(
      `/education/api/v1/pupil-education/pupil-approved/${data.id}`,
      data,
    )
  }

  quashed(data: any): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.put(
      `/education/api/v1/pupil-education/pupil-quashed/${data.id}`,
      data,
    )
  }

  dismiss(data: any): Promise<AxiosResponse<IOrganizationPupils>> {
    return bbitApi.put(
      `/education/api/v1/pupil-education/pupil-dismiss/${data.id}`,
      data,
    )
  }

  count(args: stringArgs): Promise<AxiosResponse<IQueuePupilsCount[]>> {
    return bbitApi.get(
      `/report/api/v1/pupil-education/dashboard${args.data || ''}`,
      { cancelToken: args.source?.token },
    )
  }

  changeGroup(
    data: IGroupChangeRequest,
  ): Promise<AxiosResponse<IGroupChangeResponse>> {
    return bbitApi.put('/education/api/v1/pupil-education/change-group', data)
  }

  toggleGovernmentStake(data: any): Promise<AxiosResponse<any>> {
    return bbitApi.patch(`/education/api/v1/pupil-education/${data.id}`, data)
  }
  // TODO MYFIX-007 backend api berishi kerak
  changePaymentPercentage(data: any): Promise<AxiosResponse<any>> {
    return new Promise((r) => {
      setTimeout(() => {
        r({})
      }, 800)
    })
    return bbitApi.patch(`/education/api/v1/pupil-education/${data.id}`, data)
  }
}

export default new OrganizationPupilsRepository()
