import React from 'react'

import { AUTHORITIES } from '@app/constants/authorities'
import {
  Admin,
  AssignAnEmployeeForm,
  CamerasPage,
  Indicators,
  EducationGroupsPage,
  EmployeeEdit,
  EmployeePage,
  EmployeePositionsTable,
  EmployeesOrganizationTable,
  OrganizationsMobileUsersPage,
  Furniture,
  GroupPage,
  GrowthStatistics,
  HaveNotAccess,
  HolidaysPage,
  InstitutionPassport,
  InstitutionPassportForm,
  ManagerMainPage,
  ManageUsersPage,
  Manual,
  MenuCalendar,
  MenuOrganizer,
  NotFound,
  Organization,
  OrganizationAttendance,
  OrganizationAttendanceMonitoring,
  OrganizationCameras,
  OrganizationContactsPage,
  OrganizationContractsForm,
  OrganizationContractsPage,
  OrganizationContractSubsidy,
  OrganizationForm,
  OrganizationPage,
  OrganizationPassportization,
  OrganizationPupilAddForm,
  OrganizationPupilPage,
  OrganizationPupilsPage,
  OrganizationPupilsQueuePage,
  OrganizationQueueListPage,
  OrganizationRooms,
  OrganizationsPage,
  OrganizationTimesheet,
  OrganizationWarehouse,
  OrganizationWorkforce,
  Profile,
  PupilPage,
  PupilReject,
  PupilSearch,
  PupilsPage,
  ReferenceBookBenefitType,
  ReferenceBookDistricts,
  ReferenceBookEducationDegrees,
  ReferenceBookEducationGrade,
  ReferenceBookEducationLanguages,
  ReferenceBookEmployeeEducation,
  ReferenceBookEmployeePosition,
  ReferenceBookEmployeeRate,
  ReferenceBookEmployeeStatus,
  ReferenceBookInstitutionSchedules,
  ReferenceBookInstitutionsTypes,
  ReferenceBookNationality,
  ReferenceBookOrganizationLocality,
  ReferenceBookOrganizationStatus,
  ReferenceBookOrganizationTypes,
  ReferenceBookOwnerships,
  ReferenceBookPage,
  ReferenceBookPageRegions,
  ReferenceBookReasons,
  ReferenceBookStayDurations,
  ReferenceLanguages,
  Reports,
  RolesForm,
  RolesPage,
  Scheduler,
  ServerError,
  Statistics,
  UserForm,
  UserPage,
  UsersPage,
  ReferenceBookNeighborhood,
  ChildStats,
} from '@app/pages'
import organizationPassportizationForm from './pages/organizations/components/organization-passportization/components/organization-passportization-form/organization-passportization-form'

export const ROUTE_NAMES = {
  child_stats: 'child-stats',
  profile: 'profile',
  statistics: 'statistics',
  manual: 'manual',
  monitoring: 'monitoring',
  growth_statistics: 'growth_statistics',
  warehouse: 'warehouse',
  furniture: 'furniture',
  pupils_search: 'pupils_search',
  pupil: 'pupil',
  pupils: 'pupils',
  workforce: 'workforce',
  menu: '',
  menu_organizer: 'menu/menu-organizer',
  menu_calendar: 'menu/menu-calendar',
  reports: 'reports',
  cameras: 'cameras',
  // ADMIN
  admin: 'admin',
  pupil_reject: 'admin/pupil_reject',
  scheduler: 'admin/scheduler',
  // MANAGE USERS
  manage_users: 'manage-users',
  users_roles: 'manage-users/roles',
  users_role_add: 'manage-users/roles/add',
  users_role_edit: 'manage-users/roles/:roleId/edit',
  users: 'manage-users/users',
  user: 'manage-users/users/user/:userId',
  user_add: 'manage-users/users/add',
  // TODO add other paths here then map them over to create routes
}

export type IRoute = {
  [Property in keyof typeof ROUTE_NAMES]: {
    path: string
    component: React.FC | any
    roles: string[]
    children?: IRoute
  }
}

export const routes = {
  // NAVBAR

  profile: {
    path: 'profile',
    component: Profile,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ],
  },
  statistics: {
    path: 'statistics',
    component: Statistics,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ],
  },
  manual: {
    path: 'manual',
    component: Manual,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_ANONYMOUS,
    ],
  },

  // MDO
  monitoring: {
    path: 'monitoring',
    component: OrganizationAttendanceMonitoring,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_MONITOR_ATTENDANCE_ALL_VIEW,
      AUTHORITIES.ROLE_MONITOR_ATTENDANCE_CURRENT_VIEW,
      AUTHORITIES.ROLE_MONITOR_ATTENDANCE_DISTRICT_VIEW,
      AUTHORITIES.ROLE_MONITOR_ATTENDANCE_REGION_VIEW,
    ],
  },
  growth_statistics: {
    path: 'growth-statistics',
    component: GrowthStatistics,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_STAT_ALL_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_STAT_REGION_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_STAT_DISTRICT_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_STAT_CURRENT_VIEW,
    ],
  },
  warehouse: {
    path: 'warehouse',
    component: OrganizationWarehouse,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
    ],
  },
  furniture: {
    path: 'furniture',
    component: Furniture,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_FURNITURE_TEMP_VIEW],
  },
  pupils_search: {
    path: 'pupils_search',
    component: PupilSearch,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
      AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
      AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW,
    ],
  },
  pupil: {
    path: 'pupils/:pupilId',
    component: PupilPage,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_PUPIL_ALL_VIEW],
  },
  pupils: {
    path: 'pupils',
    component: PupilsPage,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_PUPIL_ALL_VIEW],
  },
  workforce: {
    path: 'workforce',
    component: OrganizationWorkforce,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_STAFF_TABLE_ALL_VIEW,
      AUTHORITIES.ROLE_STAFF_TABLE_REGION_VIEW,
      AUTHORITIES.ROLE_STAFF_TABLE_DISTRICT_VIEW,
      AUTHORITIES.ROLE_STAFF_TABLE_CURRENT_VIEW,
    ],
  },
  menu: {
    path: '',
    component: () => <></>,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_MENU_ALL_VIEW,
      AUTHORITIES.ROLE_MENU_REGION_VIEW,
      AUTHORITIES.ROLE_MENU_DISTRICT_VIEW,
      AUTHORITIES.ROLE_MENU_CURRENT_VIEW,
    ],
  },
  menu_organizer: {
    path: 'menu/menu-organizer',
    component: MenuOrganizer,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_MENU_ALL_VIEW,
      AUTHORITIES.ROLE_MENU_REGION_VIEW,
      AUTHORITIES.ROLE_MENU_DISTRICT_VIEW,
      AUTHORITIES.ROLE_MENU_CURRENT_VIEW,
    ],
  },
  menu_calendar: {
    path: 'menu/menu-calendar',
    component: MenuCalendar,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
    ],
  },
  reports: {
    path: 'reports',
    component: Reports,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.RR_CHILD_COUNT_BY_AGE,
      AUTHORITIES.RR_CHILD_COUNT_BY_INSTITUTION,
      AUTHORITIES.RR_CHILD_COUNT_BY_GROUP,
      AUTHORITIES.RR_CHILD_COUNT_ON_QUEUE_BY_AGE,
      AUTHORITIES.RR_CONTRACT_N_CHILD_INFO_OF_PARTNERSHIP_ORGANIZATIONS,
      AUTHORITIES.RR_APPS_COUNT_ON_QUEUE_BY_CHILD_AGE,
      AUTHORITIES.RR_HEIGHT_WEIGHT_DATA_ENTRY_STATUS_BY_GROUPS,
    ],
  },
  cameras_page: {
    path: 'cameras',
    component: CamerasPage,
    roles: [AUTHORITIES.ADMIN],
  },

  // ADMIN
  admin: {
    path: 'admin',
    component: Admin,
    roles: [AUTHORITIES.ADMIN],
  },
  pupil_reject: {
    path: 'admin/pupil_reject',
    component: PupilReject,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_APPLICATION_ALL_CANCEL],
  },
  scheduler: {
    path: 'admin/scheduler',
    component: Scheduler,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_PLANNER_HISTORY_VIEW,
      AUTHORITIES.ROLE_PLANNER_MANAGEMENT,
      AUTHORITIES.ROLE_PLANNER_CURRENT_STATUS_VIEW,
    ],
  },
  // settings: {
  //   path: 'settings',
  //   component: SettingsPage,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_medical_staff: {
  //   path: 'settings/reference_book/medical-staff',
  //   component: ReferenceBookMedicalStaff,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_water_sources: {
  //   path: 'settings/reference_book/water-sources',
  //   component: ReferenceBookWaterSource,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_drinking_water_availabe_during_year: {
  //   path: 'settings/reference_book/drinking-water-available-during-year',
  //   component: DrinkingWaterAvailableDuringYears,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_water_cleaning_methods: {
  //   path: 'settings/reference_book/water-cleaning-methods',
  //   component: ReferenceBookCleaningWaterMethods,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_toilet_types: {
  //   path: 'settings/reference_book/toilet-types',
  //   component: ReferenceToiletTypes,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_toilet_locations: {
  //   path: 'settings/reference_book/toilet-locations',
  //   component: ReferenceToiletLocations,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_toilet_cleanings: {
  //   path: 'settings/reference_book/toilet-cleanings',
  //   component: ReferenceToiletCleanings,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_toilet_solid_waste_removings: {
  //   path: 'settings/reference_book/solid-waste-removings',
  //   component: ReferenceBookSolidWasteRemovings,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_hot_water_sources: {
  //   path: 'settings/reference_book/hot-water-sources',
  //   component: ReferenceBookHotWaterSources,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_heating_system_sources: {
  //   path: 'settings/reference_book/heating-system-sources',
  //   component: ReferenceBookHeatingSystemSources,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_electricitys: {
  //   path: 'settings/reference_book/electricitys',
  //   component: ReferenceBookElectricitys,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },
  // reference_book_food_preparing_places: {
  //   path: 'settings/reference_book/food-preparing-places',
  //   component: ReferenceBookFoodPreparingPlaces,
  //   roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  // },

  // MANAGE USERS
  manage_users: {
    path: 'manage-users',
    component: ManageUsersPage,
    roles: [AUTHORITIES.ADMIN],
  },
  users_roles: {
    path: 'manage-users/roles',
    component: RolesPage,
    roles: [AUTHORITIES.ADMIN],
  },
  users_role_add: {
    path: 'manage-users/roles/add',
    component: RolesForm,
    roles: [AUTHORITIES.ADMIN],
  },

  users_role_edit: {
    path: 'manage-users/roles/:roleId/edit',
    component: RolesForm,
    roles: [AUTHORITIES.ADMIN],
  },

  users: {
    path: 'manage-users/users',
    component: UsersPage,
    roles: [AUTHORITIES.ADMIN],
  },
  user: {
    path: 'manage-users/users/user/:userId',
    component: UserPage,
    roles: [AUTHORITIES.ADMIN],
  },
  user_add: {
    path: 'manage-users/users/add',
    component: UserForm,
    roles: [AUTHORITIES.ADMIN],
  },
  user_edit: {
    path: 'manage-users/users/user/:userId/:edit',
    component: UserForm,
    roles: [AUTHORITIES.ADMIN],
  },

  // REFERENCES
  reference_book: {
    path: 'reference_book',
    component: ReferenceBookPage,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_regions: {
    path: 'reference_book/regions',
    component: ReferenceBookPageRegions,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_lang: {
    path: 'reference_book/languages',
    component: ReferenceLanguages,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_district: {
    path: 'reference_book/districts',
    component: ReferenceBookDistricts,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_neighborhood: {
    path: 'reference_book/neighborhood',
    component: ReferenceBookNeighborhood,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_doo_types: {
    path: 'reference_book/institutions-types',
    component: ReferenceBookInstitutionsTypes,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_ownerships: {
    path: 'reference_book/ownerships',
    component: ReferenceBookOwnerships,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_stay_duration: {
    path: 'reference_book/stay-duration',
    component: ReferenceBookStayDurations,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_institution_schedules: {
    path: 'reference_book/institution-schedules',
    component: ReferenceBookInstitutionSchedules,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_organization_status: {
    path: 'reference_book/organization-statuses',
    component: ReferenceBookOrganizationStatus,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_organization_locality: {
    path: 'reference_book/organization-locality',
    component: ReferenceBookOrganizationLocality,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_employee_rate: {
    path: 'reference_book/employee-rates',
    component: ReferenceBookEmployeeRate,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_employee_status: {
    path: 'reference_book/employee-statuses',
    component: ReferenceBookEmployeeStatus,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_employee_education: {
    path: 'reference_book/employee-educations',
    component: ReferenceBookEmployeeEducation,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_education_grade: {
    path: 'reference_book/education-grades',
    component: ReferenceBookEducationGrade,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_education_language: {
    path: 'reference_book/education-languages',
    component: ReferenceBookEducationLanguages,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_nationality: {
    path: 'reference_book/nationalities',
    component: ReferenceBookNationality,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_employee_position: {
    path: 'reference_book/employee-positions',
    component: ReferenceBookEmployeePosition,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_organization_type: {
    path: 'reference_book/organization_types',
    component: ReferenceBookOrganizationTypes,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_holidays: {
    path: 'reference_book/holidays',
    component: HolidaysPage,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_reasons: {
    path: 'reference_book/reasons',
    component: ReferenceBookReasons,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_education_degrees: {
    path: 'reference_book/education_degree',
    component: ReferenceBookEducationDegrees,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },
  reference_book_benefit_type: {
    path: 'reference_book/benefit_type',
    component: ReferenceBookBenefitType,
    roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_SETTING_DICTIONARY_VIEW],
  },

  organizations: {
    path: 'organizations',
    component: OrganizationsPage,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
    ],
  },
  organization_institution_add: {
    path: 'institution/:addInstitution',
    component: OrganizationForm,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
    ],
  },
  organization_gov_agency_add: {
    path: 'gov-agency/:addGov',
    component: OrganizationForm,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
    ],
  },
  organization: {
    path: 'organization/:id',
    component: Organization,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
      AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
    ],
    children: {
      child_stats: {
        path: ROUTE_NAMES.child_stats,
        component: ChildStats,
        roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW],
      },
      indicators: {
        path: 'indicators',
        component: Indicators,
        roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_DASH1_CURRENT_VIEW],
      },
      organization_view: {
        path: 'page',
        component: OrganizationPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
        ],
      },
      organization_contacts: {
        path: 'contacts',
        component: OrganizationContactsPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
        ],
      },
      organization_contracts: {
        path: 'contracts',
        component: OrganizationContractsPage,
        roles: [AUTHORITIES.ADMIN],
      },
      organization_contract_subsidy: {
        path: 'contract-subsidy',
        component: OrganizationContractSubsidy,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_CONTRACT_SUBSIDY_ALL_VIEW,
          AUTHORITIES.ROLE_CONTRACT_SUBSIDY_REGION_VIEW,
          AUTHORITIES.ROLE_CONTRACT_SUBSIDY_DISTRICT_VIEW,
          AUTHORITIES.ROLE_CONTRACT_SUBSIDY_CURRENT_VIEW,
        ],
      },
      contract_add: {
        path: 'contracts/add',
        component: OrganizationContractsForm,
        roles: [AUTHORITIES.ADMIN],
      },
      manager_home: {
        path: 'manager',
        component: ManagerMainPage,
        roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_MANAGER],
      },
      organization_attendance: {
        path: 'attendance',
        component: OrganizationAttendance,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ATTENDANCE_ALL_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_CURRENT_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_DISTRICT_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_REGION_VIEW,
        ],
      },
      // organization_attendance_monitoring: {
      //   path: "attendance-monitoring",
      //   component: OrganizationAttendanceMonitoring,
      //   roles: [
      //     AUTHORITIES.ADMIN,
      //     AUTHORITIES.ROLE_ATTENDANCE_ALL_VIEW,
      //     AUTHORITIES.ROLE_ATTENDANCE_CURRENT_VIEW,
      //     AUTHORITIES.ROLE_ATTENDANCE_DISTRICT_VIEW,
      //     AUTHORITIES.ROLE_ATTENDANCE_REGION_VIEW,
      //   ],
      // },
      organization_timesheet: {
        path: 'timesheet',
        component: OrganizationTimesheet,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ATTENDANCE_ALL_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_CURRENT_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_DISTRICT_VIEW,
          AUTHORITIES.ROLE_ATTENDANCE_REGION_VIEW,
        ],
      },
      organization_rooms: {
        path: 'rooms',
        component: OrganizationRooms,
        roles: [AUTHORITIES.ADMIN, AUTHORITIES.ROLE_REGION_IKT],
      },
      organization_workforce: {
        path: 'workforce',
        component: OrganizationWorkforce,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_STAFF_TABLE_ALL_VIEW,
          AUTHORITIES.ROLE_STAFF_TABLE_REGION_VIEW,
          AUTHORITIES.ROLE_STAFF_TABLE_DISTRICT_VIEW,
          AUTHORITIES.ROLE_STAFF_TABLE_CURRENT_VIEW,
        ],
      },
      organization_menu_calendar: {
        path: 'menu-calendar',
        component: MenuCalendar,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
        ],
      },
      organization_warehouse: {
        path: 'warehouse',
        component: OrganizationWarehouse,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
        ],
      },
      organization_passportization: {
        path: 'passportization',
        component: OrganizationPassportization,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PASSPORTIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_DISTRICT_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_CURRENT_VIEW,
        ],
      },
      organization_passportization_add: {
        path: 'passportization/add',
        component: organizationPassportizationForm,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PASSPORTIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_DISTRICT_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_CURRENT_VIEW,
        ],
      },
      organization_passportization_edit: {
        path: 'passportization/:passportizationId/:edit',
        component: organizationPassportizationForm,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PASSPORTIZATION_ALL_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_REGION_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_DISTRICT_VIEW,
          AUTHORITIES.ROLE_PASSPORTIZATION_CURRENT_VIEW,
        ],
      },
      institution_passport: {
        path: 'passport',
        component: InstitutionPassport,
        roles: [
          AUTHORITIES.ADMIN,
          // AUTHORITIES.ROLE_ORGANIZATION_ALL_VIEW,
          // AUTHORITIES.ROLE_ORGANIZATION_CURRENT_VIEW,
          // AUTHORITIES.ROLE_ORGANIZATION_REGION_VIEW,
          // AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_VIEW,
        ],
      },
      organization_employees: {
        path: 'employees',
        component: EmployeesOrganizationTable,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DISTRICT_IKT,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_VIEW,
        ],
      },
      organization_employees_positions: {
        path: 'employees-positions',
        component: EmployeePositionsTable,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DISTRICT_IKT,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_VIEW,
        ],
      },
      organization_mobile_users: {
        path: 'mobile-users',
        component: OrganizationsMobileUsersPage,
        roles: [
          // NOTE Mobile user bo'limi uchun o'zini rollarini yaratib qo'yish kerak bo'lishi mumkin
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_DISTRICT_IKT,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_VIEW,
        ],
      },
      organization_groups: {
        path: 'groups',
        component: EducationGroupsPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_EDUCATION_GROUP_ALL_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_CURRENT_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_DISTRICT_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_REGION_VIEW,
        ],
      },
      organization_group: {
        path: 'groups/:groupId',
        component: GroupPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_EDUCATION_GROUP_ALL_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_CURRENT_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_DISTRICT_VIEW,
          AUTHORITIES.ROLE_EDUCATION_GROUP_REGION_VIEW,
        ],
      },
      organization_pupils: {
        path: 'pupils',
        component: OrganizationPupilsPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
          AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
          AUTHORITIES.ROLE_PUPIL_CURRENT_VIEW,
          AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW,
        ],
      },
      organization_pupils_queue: {
        path: 'pupils-queue',
        component: OrganizationPupilsQueuePage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
          AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
          AUTHORITIES.ROLE_PUPIL_CURRENT_VIEW,
          AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW,
        ],
      },
      organization_queue_list: {
        path: 'queue-list',
        component: OrganizationQueueListPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
          AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
          AUTHORITIES.ROLE_PUPIL_CURRENT_VIEW,
          AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW,
        ],
      },
      organization_cameras: {
        path: 'cameras',
        component: OrganizationCameras,
        roles: [AUTHORITIES.ADMIN],
      },
      organization_edit: {
        path: ':edit',
        component: OrganizationForm,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
          AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
          AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
          AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
          AUTHORITIES.ROLE_ORGANIZATION_NAME_CHANGE,
          AUTHORITIES.ROLE_ORGANIZATION_CADASTRAL_NUMBER_CHANGE,
          AUTHORITIES.ROLE_DASH1_CURRENT_VIEW,
        ],
      },
      organization_employee: {
        path: 'employees/employee/:employeeId',
        component: EmployeePage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_VIEW,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_VIEW,
        ],
      },
      organization_employee_form: {
        path: 'employees-positions/:posId/assign-employee',
        component: AssignAnEmployeeForm,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_EDIT,
        ],
      },
      organization_employee_edit_form: {
        path: 'employees/employee/:employeeId/edit',
        component: EmployeeEdit,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_EMPLOYEE_ALL_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_CURRENT_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_DISTRICT_EDIT,
          AUTHORITIES.ROLE_EMPLOYEE_REGION_EDIT,
        ],
      },
      organization_pupil: {
        path: 'pupils/pupil/:pupilId',
        component: OrganizationPupilPage,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PUPIL_ALL_VIEW,
          AUTHORITIES.ROLE_PUPIL_CURRENT_VIEW,
          AUTHORITIES.ROLE_PUPIL_DISTRICT_VIEW,
          AUTHORITIES.ROLE_PUPIL_REGION_VIEW,
        ],
      },
      organization_add_pupil: {
        path: 'pupils/manual-add',
        component: OrganizationPupilAddForm,
        roles: [
          AUTHORITIES.ADMIN,
          AUTHORITIES.ROLE_PUPIL_ALL_EDIT,
          AUTHORITIES.ROLE_PUPIL_REGION_EDIT,
          AUTHORITIES.ROLE_PUPIL_DISTRICT_EDIT,
          AUTHORITIES.ROLE_PUPIL_CURRENT_EDIT,
        ],
      },
      institution_passport_add: {
        path: 'passport/add',
        component: InstitutionPassportForm,
        roles: [
          AUTHORITIES.ADMIN,
          // AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
        ],
      },
      institution_passport_edit: {
        path: 'passport/:passportId/:edit',
        component: InstitutionPassportForm,
        roles: [
          AUTHORITIES.ADMIN,
          // AUTHORITIES.ROLE_ORGANIZATION_ALL_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_CURRENT_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_REGION_EDIT,
          // AUTHORITIES.ROLE_ORGANIZATION_DISTRICT_EDIT,
        ],
      },
    },
  },

  // OTHER
  notfound: {
    path: '404',
    component: NotFound,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ],
  },
  does_not_have_access: {
    path: '403',
    component: HaveNotAccess,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ],
  },
  server_error: {
    path: '500',
    component: ServerError,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.USER,
      AUTHORITIES.ROLE_MANAGER,
      AUTHORITIES.ROLE_REGION_IKT,
      AUTHORITIES.ROLE_DISTRICT_IKT,
    ],
  },
}
