import bbitApi from '@app/api/make-request'
import { numberArgs } from '@app/api/repositories/i-repository'
import { host_without_last_slash } from '@app/constants/env'
import { IFile, IFileActions } from '@app/interfaces/files'
import {
  FETCH_FILE_FAILURE,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  PRELOAD_IMAGE,
  REMOVE_IMAGE,
} from '@app/store/types/files'
import axios from 'axios'

export function preLoadFile(photo: IFile): IFileActions {
  return {
    type: PRELOAD_IMAGE,
    payload: photo,
  }
}

export const getFile =
  (args: numberArgs, message?: string) => async (dispatch) => {
    dispatch(fetchFileRequest())
    await bbitApi
      .get(`${host_without_last_slash}/files/api/v1/files/${args.data}`, {
        cancelToken: args.source?.token,
      })
      .then((response) => {
        dispatch(fetchFileSuccess(response.data))
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchFileFailure(error?.message, error?.code))
        }
      })
  }

export const removeFile = () => {
  return {
    type: REMOVE_IMAGE,
    payload: null,
  }
}

const fetchFileRequest = () => {
  return {
    type: FETCH_FILE_REQUEST,
  }
}

const fetchFileSuccess = (photo: IFile) => {
  return {
    type: FETCH_FILE_SUCCESS,
    payload: photo,
  }
}

const fetchFileFailure = (message, code) => {
  return {
    type: FETCH_FILE_FAILURE,
    payload: { message, code },
  }
}
