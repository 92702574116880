import organizationMobileUsersRepository from '@app/api/repositories/organization-mobile-users-repository'
import { IOrganizationAttendanceActions } from '@app/interfaces/organization-attendance'
import { IOrganizationMobileUser } from '@app/interfaces/organization-mobile-users'
import {
  FETCH_ORGANIZATION_MOBILE_USERS,
  ERROR_ORGANIZATION_MOBILE_USERS,
  SUCCESS_ORGANIZATION_MOBILE_USERS,
  CLEAR_ORGANIZATION_MOBILE_USERS,
  UPDATE_ORGANIZATION_MOBILE_USER,
  DELETE_ORGANIZATION_MOBILE_USER,
  CREATE_ORGANIZATION_MOBILE_USER,
} from '@app/store/types/organization-mobile-users'

function getOrganizationMobileUsers(
  params: URLSearchParams,
  signal?: AbortSignal,
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ORGANIZATION_MOBILE_USERS,
      })
      const response = await organizationMobileUsersRepository.getAll({
        params,
        signal,
      })
      dispatch({
        type: SUCCESS_ORGANIZATION_MOBILE_USERS,
        payload: {
          mobileUsers: response?.data?.data?.content.map((i) => {
            return {
              id: i.id,
              fullName: i.fullName,
              organizationId: i.organizationId,
              username: i.username,
            } as IOrganizationMobileUser
          }),
          count: response?.data?.data?.totalElements,
        },
      })
    } catch (error) {
      dispatch({
        type: ERROR_ORGANIZATION_MOBILE_USERS,
        payload: error,
      })
    }
  }
}

function optimisticUpdateMobileUsersState(
  type: 'add',
  payload: IOrganizationMobileUser,
): void
function optimisticUpdateMobileUsersState(
  type: 'update',
  payload: Partial<IOrganizationMobileUser>,
): void
function optimisticUpdateMobileUsersState(type: 'delete', payload: string): void
function optimisticUpdateMobileUsersState(type, payload) {
  switch (type) {
    case 'add':
      return {
        type: CREATE_ORGANIZATION_MOBILE_USER,
        payload,
      }
    case 'update':
      return {
        type: UPDATE_ORGANIZATION_MOBILE_USER,
        payload,
      }
    case 'delete':
      return {
        type: DELETE_ORGANIZATION_MOBILE_USER,
        payload,
      }
  }
}

const clearOrganizationMobileUsers = (): IOrganizationAttendanceActions => {
  return {
    type: CLEAR_ORGANIZATION_MOBILE_USERS,
  }
}

export {
  getOrganizationMobileUsers,
  clearOrganizationMobileUsers,
  optimisticUpdateMobileUsersState,
}
