import { PlusOutlined } from '@ant-design/icons'
import { INationality } from '@app/interfaces/nationality'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import PupilsParentFormItem from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/found-pupil/components/pupils-parent-item/components/pupils-parent-form-item/pupils-parent-form-item'
import { Button, Form } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  pupil: IRegistryPupil
  _nationalities: INationality[]
  confirmLoading: boolean
}

const PupilsParentItem: React.FC<props> = ({
  pupil,
  _nationalities,
  confirmLoading,
}) => {
  const [t] = useTranslation()

  return (
    <div className="pupil-info-parents">
      <Title level={5}>{t('pupils.parents')}</Title>
      <Form.List
        name="parents"
        rules={[
          {
            validator: async (_, parents) => {
              if (!parents || parents.length < 1) {
                return Promise.reject(new Error(t('errors.requiredMessage')))
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => {
                const parent = pupil.pupil?.parents?.length
                  ? pupil.pupil?.parents?.find((parent, index) => index === key)
                  : undefined

                return (
                  <PupilsParentFormItem
                    name={name}
                    key={key}
                    remove={remove}
                    fieldKey={fieldKey as number}
                    confirmLoading={confirmLoading}
                    _nationalities={_nationalities}
                    parent={parent}
                  />
                )
              })}
              <Form.Item style={{ marginTop: '20px' }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {t('pupils.form.addParent')}
                </Button>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          )
        }}
      </Form.List>
    </div>
  )
}

export default PupilsParentItem
