import { IFile } from '@app/interfaces/files'
import { INationality } from '@app/interfaces/nationality'
import { IRegistryPupil } from '@app/interfaces/pupil/pupils'
import CitizenChooseForm from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/citizen-choose-form/citizen-choose-form'
import EnrollmentPupil from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/enrollment-pupil/enrollment-pupil'
import FindForeignPupilForm from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/find-foreign-pupil-form/find-foreign-pupil-form'
import FindPupilForm from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/find-pupil-form/find-pupil-form'
import FoundPupil from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/found-pupil/found-pupil'
import { IManualPupilAddState } from '@app/store/reducers/pupil-manual-add-reducer'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Card, Spin, Steps } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import './organization-pupil-add-form.less'

interface props {
  _pupilsInfo: IManualPupilAddState
  _pupil: IRegistryPupil
  _nationalities: INationality[]
  _avatar: IFile
}

const OrganizationPupilAddForm: React.FC<props> = ({
  _pupilsInfo,
  _pupil,
  _nationalities,
  _avatar,
}) => {
  const [current, setCurrent] = React.useState(0)
  const [t] = useTranslation()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  setDocumentTitle(t('pupils.adding'))

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: t('pupils.form.citizenship'),
      content: <CitizenChooseForm back={prev} next={next} />,
    },
    {
      title: t('pupils.form.findingPupil'),
      content:
        _pupilsInfo.citizenship === 'native' ? (
          <FindPupilForm
            loading={confirmLoading}
            setConfirmLoading={setConfirmLoading}
            pupilBirthday={_pupilsInfo.birthDate}
            metricNumber={_pupilsInfo.metricNumber}
            metricSerial={_pupilsInfo.metricSerial}
            next={next}
            prev={prev}
            current={current}
          />
        ) : (
          <FindForeignPupilForm
            loading={confirmLoading}
            setConfirmLoading={setConfirmLoading}
            pupilBirthday={_pupilsInfo.birthDate}
            metricNumber={_pupilsInfo.metricNumber}
            metricSerial={_pupilsInfo.metricSerial}
            next={next}
            prev={prev}
            current={current}
          />
        ),
    },
    {
      title: t('pupils.form.addPupil'),
      content: (
        <FoundPupil
          setConfirmLoading={setConfirmLoading}
          avatar={_avatar}
          pupilInfo={_pupilsInfo}
          citizenship={_pupilsInfo.citizenship}
          loading={confirmLoading}
          _nationalities={_nationalities}
          pupil={_pupil}
          current={current}
          prev={prev}
          next={next}
        />
      ),
    },
    {
      title: t('pupils.enrollment'),
      content: (
        <EnrollmentPupil
          loading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
          pupil={_pupil}
          current={current}
          prev={prev}
          next={next}
        />
      ),
    },
  ]

  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Spin spinning={confirmLoading}>
        <Card
          className="steps-content"
          style={{ padding: '24px', marginTop: '16px' }}
        >
          {steps[current].content}
        </Card>
      </Spin>
    </>
  )
}

function mapStateToProps(state) {
  return {
    _pupilsInfo: state.manualPupilsAdd,
    _pupil: state.pupils.registryPupil,
    _nationalities: state.nationality.nationalities,
    _avatar: state.files.preUploadedPhoto,
  }
}

export default connect(mapStateToProps)(OrganizationPupilAddForm)
