import EducationGroupsRepository from '@api/repositories/education-groups-repository'
import { acceptPupilIntoGroup } from '@api/rest/manual-education-create'
import bbitApi from '@app/api/make-request'
import {
  IAcceptPupilIntoGroup,
  IEducationGroup,
} from '@app/interfaces/education/education-groups'
import { IOrganization } from '@app/interfaces/organization/organization'
import { IPupilApproved, IRegistryPupil } from '@app/interfaces/pupil/pupils'
import StepsButtons from '@app/pages/organizations/components/organization-pupils/components/organization-pupil-add-form/components/steps-buttons'
import Loader from '@app/shared/loader/loader'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { Form, Input, Radio, Space, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import axios, { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

interface props {
  current: number
  prev: () => void
  next: () => void
  pupil: IRegistryPupil
  setConfirmLoading: (data: boolean) => void
  loading: boolean
}

interface params {
  id?: string
}

const EnrollmentPupil: React.FC<props> = ({
  current,
  prev,
  pupil,
  setConfirmLoading,
  loading,
}) => {
  const params: params = useParams()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [groups, setGroups] = useState<IEducationGroup[]>([])
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { data: organization } = useQuery('getorg', async () => {
    const { data } = await bbitApi.get<IOrganization>(
      `dictionary/api/v1/organizations/${params.id}`,
    )
    return data
  })
  useEffect(() => {
    const source = axios.CancelToken.source()
    EducationGroupsRepository.getAll({
      params: `organizationId.equals=${params.id}&size=100`,
      source,
    })
      .then((response) => {
        setGroups(response.data.filter((g) => g.status === 'OPEN'))
        setLoaded(true)
      })
      .catch(() => {
        openNotificationWithIcon('error', t('educationGroup.error'))
        setLoaded(true)
      })
    return () => {
      source.cancel('Component got unmounted')
    }
  }, [])

  if (!loaded) return <Loader />

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('educationGroup.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('educationGroup.groupFreeSeats'),
      dataIndex: 'groupFreeSeats',
      key: 'groupFreeSeats',
    },
    {
      title: t('general.action'),
      key: 'action',
      render: (text, record) => (
        <Radio disabled={record.groupFreeSeats === 0} value={record.id}></Radio>
      ),
    },
  ]

  function getActualDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return day + '.' + month + '.' + year
  }

  const date = getActualDate()

  const data = groups.map((group) => {
    return {
      key: group.id,
      id: group.id,
      name: group.name,
      groupFreeSeats: group.groupFreeSeats,
    }
  })

  const onFinish = (values: any) => {
    const newValues: IAcceptPupilIntoGroup = {
      ...values,
      pupilId: pupil.pupil?.id,
    }
    setConfirmLoading(true)
    acceptPupilIntoGroup(newValues)
      .then((response: AxiosResponse<IPupilApproved>) => {
        if (response.data.status === 'APPROVED') {
          openNotificationWithIcon('success', t('pupils.accepted'))
          setConfirmLoading(false)
          navigate(`/organization/${params.id}/pupils`)
        }
      })
      .catch(() => {
        openNotificationWithIcon('error', t('pupils.education.exist'))
        setConfirmLoading(false)
      })
  }

  return (
    <>
      <Form
        form={form}
        initialValues={{
          orderDate: date,
        }}
        layout={'vertical'}
        onFinish={onFinish}
      >
        <Title level={5}>{t('pupils.enrollment')}</Title>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
            {
              max: 120,
              message: t('errors.c'),
            },
          ]}
          label={t('pupils.orderNo')}
          name={'orderNo'}
        >
          <Input />
        </Form.Item>
        <Space
          direction="horizontal"
          style={{
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Title level={5}>
            {t('pupils.pupilFreeSeats')}:{' '}
            {organization?.passport.manualAvailable}
          </Title>
        </Space>
        <Form.Item
          name={'orderDate'}
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          label={t('pupils.orderDate')}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('errors.requiredMessage'),
            },
          ]}
          label={t('educationGroup.title')}
          name={'educationGroupId'}
        >
          <Radio.Group style={{ width: '100%' }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              rowClassName="table-row"
            />
          </Radio.Group>
        </Form.Item>
        <Form.Item label={t('pupils.note')} name={'note'}>
          <Input />
        </Form.Item>
      </Form>
      <StepsButtons
        confirmLoading={loading}
        form={form}
        prev={prev}
        current={current}
      />
    </>
  )
}

export default EnrollmentPupil
