import { IFile } from '@app/interfaces/files'
import bbitApi from '../make-request'

export function uploadFile(file: File) {
  const data = new FormData()
  data.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return bbitApi.post<IFile>('/files/api/v1/files', data, config)
}
