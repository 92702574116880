import { AUTH_TOKEN } from '@app/constants/authToken'
import i18n from '@app/i18n'
import { history } from '@app/index'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

const COMPONENT_UNMOUNT_ERROR = 'Component got unmounted'
const REQUEST_CANCELED_ERROR = 'canceled'
export type TApiRequestType = 'patch' | 'octet' | 'json' | 'stream'

export const URLS = {
  HOST: process.env.REACT_APP_HOST as string,
  MOBILE_HOST: 'https://mobile.bc.kinderhub.devel.uz:8090',
}

const bbitApi = axios.create({
  baseURL: URLS.HOST,
  params: '',
})

export const bbitApiWithoutNotification = axios.create({
  baseURL: URLS.HOST,
  params: '',
})

export const bbitMobileApi = axios.create({
  baseURL: URLS.MOBILE_HOST,
  params: '',
})

const rtkApi = axios.create({
  baseURL: URLS.HOST,
  params: '',
})

async function RequestInterceptor(config: AxiosRequestConfig) {
  const token = localStorage.getItem(AUTH_TOKEN)

  if (!config.headers) {
    config.headers = {}
  }

  config.headers['Authorization'] =
    config.headers['Authorization'] || 'Bearer ' + token
  config.headers['Accept'] = config.headers['Accept'] || 'application/json'
  config.headers['Content-Type'] =
    config.headers['Content-Type'] || 'application/json;charset=UTF-8'

  return config
}

bbitApi.interceptors.request.use(RequestInterceptor, function (error) {
  return Promise.reject(error)
})
bbitApiWithoutNotification.interceptors.request.use(
  RequestInterceptor,
  function (error) {
    return Promise.reject(error)
  },
)

bbitApi.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    const title = error?.response?.data?.title
      ? i18n.t('general.errorDetails.title', {
          title: error?.response?.data?.title,
        })
      : ''
    const message = error?.response?.data?.message
      ? i18n.t('general.errorDetails.message', {
          message: error?.response?.data?.message,
        })
      : ''
    const status = error?.response?.data?.status
      ? i18n.t('general.errorDetails.status', {
          status: error?.response?.data?.status,
        })
      : ''
    if (
      error?.message !== COMPONENT_UNMOUNT_ERROR &&
      error?.message !== REQUEST_CANCELED_ERROR
    )
      openNotificationWithIcon(
        'error',
        `${title}
      ${message}
      ${status}`,
        i18n.t('general.errorDetails.label'),
      )

    if (error.response) {
      if (window.location.pathname !== '/auth') {
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem(AUTH_TOKEN)
          history.push('/auth')
        }
      }
    }
    return Promise.reject(error.response)
  },
)
bbitApiWithoutNotification.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (window.location.pathname !== '/auth') {
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem(AUTH_TOKEN)
          history.push('/auth')
        }
      }
    }
    return Promise.reject(error.response)
  },
)

bbitMobileApi.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    const title = error?.response?.data?.title
      ? i18n.t('general.errorDetails.title', {
          title: error?.response?.data?.title,
        })
      : ''
    const message = error?.response?.data?.message
      ? i18n.t('general.errorDetails.message', {
          message: error?.response?.data?.message,
        })
      : ''
    const status = error?.response?.data?.status
      ? i18n.t('general.errorDetails.status', {
          status: error?.response?.data?.status,
        })
      : ''
    if (
      error?.message !== COMPONENT_UNMOUNT_ERROR &&
      error?.message !== REQUEST_CANCELED_ERROR
    )
      openNotificationWithIcon(
        'error',
        `${title}
      ${message}
      ${status}`,
        i18n.t('general.errorDetails.label'),
      )

    if (error.response) {
      if (window.location.pathname !== '/auth') {
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem(AUTH_TOKEN)
          history.push('/auth')
        }
      }
    }
    return Promise.reject(error.response)
  },
)

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: process.env.REACT_APP_HOST! },
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    type?: TApiRequestType
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params, type = 'json' }) => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN)
      const headers: any = {}
      headers['Authorization'] = 'Bearer ' + token
      headers['Accept'] = 'application/json'
      headers['Content-type'] =
        type === 'json'
          ? 'application/json;charset=UTF-8'
          : type === 'octet' || type === 'stream'
          ? 'application/octet-stream'
          : 'application/merge-patch+json'
      if (type === 'octet') headers['responseType'] = 'blob'
      if (type === 'stream') headers['responseType'] = 'stream'

      const result = await rtkApi({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      })
      return { data: result.data, meta: result.headers }
    } catch (axiosError) {
      const error = axiosError as AxiosError

      const title = error?.response?.data?.title
        ? i18n.t('general.errorDetails.title', {
            title: error?.response?.data?.title,
          })
        : ''
      const message = error?.response?.data?.message
        ? i18n.t('general.errorDetails.message', {
            message: error?.response?.data?.message,
          })
        : ''
      const status = error?.response?.data?.status
        ? i18n.t('general.errorDetails.status', {
            status: error?.response?.data?.status,
          })
        : ''
      if (error?.message !== COMPONENT_UNMOUNT_ERROR)
        openNotificationWithIcon(
          'error',
          `${title}
      ${message}
      ${status}`,
          i18n.t('general.errorDetails.label'),
        )
      if (error.response) {
        if (window.location.pathname !== '/auth') {
          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem(AUTH_TOKEN)
            history.push('/auth')
          }
        }
      }

      return {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message,
        },
      }
    }
  }

export default bbitApi
