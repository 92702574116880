import React from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { IUserGroupRoles } from '@app/interfaces/roles'
import useLocalization from '@app/hooks/useLocalization'
import useQuery from '@app/hooks/useQuery'

interface props {
  request: (string) => void
  load: boolean
  groupRoles: IUserGroupRoles[]
}

const UsersFilterForm: React.FC<props> = ({ request, load, groupRoles }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const { localizeUserData } = useLocalization()
  const { serializeQuery } = useQuery()

  const onFinish = (values: any) => {
    const newValues = {
      'id.equals': values.id,
      'firstName.equals': values.firstName,
      'lastName.equals': values.lastName,
      'middleName.equals': values.middleName,
      'email.equals': values.email,
      'phone.equals': values.phone,
      'passportNumber.equals': values.passportNumber,
      'idEgovUserId.equals': values.idEgovUserId,
      'groupRoleId.equals': values.groupRoles,
      'tin.contains': values.tin,
      'pin.contains': values.pin,
      'username.equals': values.username,
      'phone.contains': values.phone,
    }
    request(serializeQuery({ query: newValues }))
  }

  const onReset = (values: any) => {
    const newValues = {
      'id.equals': values.id,
      'tin.contains': values.tin,
      'pin.contains': values.pin,
      'username.equals': values.username,
      'groupRoleId.equals': values.groupRoles,
    }
    request(serializeQuery({ query: newValues }))
    form.resetFields()
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Row gutter={4}>
        <Col span={4}>
          <Form.Item name={'id'} label={t('users.id')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'username'} label={t('users.username')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'firstName'} label={t('users.firstName')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'lastName'} label={t('users.lastName')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'middleName'} label={t('users.middleName')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'email'} label={t('users.email')}>
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'phone'} label={t('users.phone')}>
            <Input type="phone" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name={'passportNumber'} label={t('users.passportNumber')}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name={'idEgovUserId'} label={t('users.idEgovUserIdShort')}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name={'tin'} label={t('users.tin')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'pin'} label={t('users.pin')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={'groupRoles'} label={t('users.groupRoles')}>
            <Select allowClear={true}>
              {groupRoles.map((role) => {
                if (role.status) {
                  return (
                    <Select.Option key={role.id} value={role.id}>
                      {localizeUserData(role.data)?.name}
                    </Select.Option>
                  )
                }
                return false
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div>
        <Button disabled={!load} type="primary" htmlType="submit">
          <FilterOutlined />
          {t('general.filter')}
        </Button>
        <Button
          disabled={!load}
          htmlType={'reset'}
          style={{ margin: '0 8px' }}
          onClick={onReset}
        >
          {t('general.reset')}
        </Button>
      </div>
    </Form>
  )
}

export default UsersFilterForm
