import employeeRepository from '@app/api/repositories/employee-repository'
import { numberArgs } from '@app/api/repositories/i-repository'
import mvdRepository from '@app/api/repositories/mvd-repository'
import usersRepository from '@app/api/repositories/users-repository'
import { IEmployeeActions } from '@app/interfaces/employee/employee'
import {
  CLEAR_EMPLOYEE,
  CLEAR_EMPLOYEES_ALL,
  CLEAR_EMPLOYEES_MENTOR,
  CLEAR_EMPLOYEES_MVD,
  CLEAR_EMPLOYEES_USER,
  COUNT_EMPLOYEES,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  FETCH_EMPLOYEES_ALL_FAILURE,
  FETCH_EMPLOYEES_ALL_REQUEST,
  FETCH_EMPLOYEES_ALL_SUCCESS,
  FETCH_EMPLOYEES_MENTOR_FAILURE,
  FETCH_EMPLOYEES_MENTOR_REQUEST,
  FETCH_EMPLOYEES_MENTOR_SUCCESS,
  FETCH_EMPLOYEE_CLEANUP,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_MVD_SUCCESS,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_USER_SUCCESS,
  REFRESH_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SHOW_PASSPORT,
  UPDATE_EMPLOYEE_TYPE,
} from '@app/store/types/employee'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { EmployeeType } from '../reducers/employee-reducer'
import {
  IPathParamsArgs,
  mvdArgs,
  stringArgs,
} from './../../api/repositories/i-repository'
import { openNotificationWithIcon } from '@app/shared/notification/notification'
import i18n from '@app/i18n'

export const getEmployee = (args: numberArgs, message) => async (dispatch) => {
  dispatch(fetchEmployeeRequest())
  await employeeRepository
    .getOne(args)
    .then((response) => {
      if (isEmpty(response.data)) {
        dispatch(fetchEmployeeFailure(message, 400))
        return
      }
      dispatch(fetchEmployeeSuccess(response.data))
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        dispatch(fetchEmployeeFailure(error?.message, error?.code))
      }
    })
  dispatch(fetchEmployeeCleanup())
}

export const getEmployeeByPinAndPassportMvd =
  (args: mvdArgs, message: string, success: string) => async (dispatch) => {
    dispatch(fetchEmployeeRequest())
    mvdRepository.getOne(args).then((mvdResponse) => {
      const parseString = require('xml2js').parseString
      parseString(mvdResponse.data, function (err, mvdResult) {
        const partialData =
          mvdResult['env:Envelope']['env:Body'][0]['n1:CEPResponse'][0]
        if (partialData['Result'][0] == '1') {
          parseString(
            partialData['Data'][0],
            function (err, partialDataResult) {
              const finalData = partialDataResult['datacepresponse']['row'][0]
              dispatch(fetchEmployeeMvdSuccess(finalData))
              dispatch(updateEmployeeType(EmployeeType.EMPLOYEE_MVD))
            },
          )
        } else {
          dispatch(fetchEmployeeFailure(message, 400))
        }
      })
    })
    dispatch(fetchEmployeeCleanup())
  }

export const getEmployeeByPinAndBirthdayMvdV2 =
  (
    args: {
      pin: string
      birthDate: string
    },
    message: string,
    success: string,
  ) =>
  async (dispatch) => {
    dispatch(fetchEmployeeRequest())
    await employeeRepository
      .getEmployeeInfo({
        pin: args.pin,
        birthDate: args.birthDate,
      })
      .then((response) => {
        dispatch(fetchEmployeeMvdSuccess(response.data))
        dispatch(updateEmployeeType(EmployeeType.EMPLOYEE_MVD))
      })
      .catch((error) => {
        dispatch(fetchEmployeeFailure(message, 400))
      })
    dispatch(fetchEmployeeCleanup())
  }

export const getEmployeeByPin =
  (
    args: stringArgs,
    message: string,
    messageTryAgain: string,
    success: string,
  ) =>
  async (dispatch) => {
    dispatch(fetchEmployeeRequest())
    await employeeRepository
      .getOneByPin(args)
      .then(async (emisResponse) => {
        usersRepository
          .getOneByPin(args)
          .then((response) => {
            dispatch(
              fetchEmployeeUserSuccess({
                ...response.data,
                ...emisResponse.data,
              }),
            )
            dispatch(updateEmployeeType(EmployeeType.EMPLOYEE_USER))
            dispatch(updateEmployeeShowPassport(true))
          })
          .catch(() => {
            dispatch(updateEmployeeShowPassport(true))
            openNotificationWithIcon(
              'error',
              `${messageTryAgain}`,
              i18n.t('general.errorDetails.label'),
            )
          })

        // if (isEmpty(emisResponse.data)) {
        //   usersRepository
        //     .getOneByPin(args)
        //     .then((response) => {
        //       dispatch(fetchEmployeeUserSuccess(response.data))
        //       dispatch(updateEmployeeType(EmployeeType.EMPLOYEE_USER))
        //     })
        //     .catch(() => {
        //       dispatch(updateEmployeeShowPassport(true))
        //     })
        // } else {
        //   dispatch(fetchEmployeeSuccess(emisResponse.data))
        //   dispatch(updateEmployeeType(EmployeeType.EMPLOYEE))
        // }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchEmployeeFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchEmployeeCleanup())
  }

export const getAllEmployees =
  (args: IPathParamsArgs, message) => async (dispatch) => {
    dispatch(fetchAllEmployeesRequest())
    await employeeRepository
      .getEmployeePositions({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchAllEmployeesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchAllEmployeesFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchEmployeeCleanup())
  }

export const getMentorEmployees =
  (args: IPathParamsArgs, message: string) => async (dispatch) => {
    dispatch(fetchMentorEmployeesRequest())
    await employeeRepository
      .getEmployeePositions({ params: args.params, source: args.source })
      .then((response) => {
        dispatch(
          fetchMentorEmployeesSuccess(
            response.data,
            response.headers['x-total-count'],
          ),
        )
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch(fetchMentorEmployeesFailure(error?.message, error?.code))
        }
      })
    dispatch(fetchEmployeeCleanup())
  }

export function countEmployees(employees) {
  return {
    type: COUNT_EMPLOYEES,
    payload: employees,
  }
}

export function clearAllEmployees() {
  return {
    type: CLEAR_EMPLOYEES_ALL,
  }
}

export function clearEmployee() {
  return {
    type: CLEAR_EMPLOYEE,
  }
}

export function clearMentorEmployees() {
  return {
    type: CLEAR_EMPLOYEES_MENTOR,
  }
}

export function clearEmployeeUser() {
  return {
    type: CLEAR_EMPLOYEES_USER,
  }
}

export function clearEmployeeMvd() {
  return {
    type: CLEAR_EMPLOYEES_MVD,
  }
}

export function refreshEmployee() {
  return {
    type: REFRESH_EMPLOYEE,
  }
}

export function createEmployee(employee): IEmployeeActions {
  return {
    type: CREATE_EMPLOYEE,
    payload: employee,
  }
}

export function updateEmployee(employee): IEmployeeActions {
  return {
    type: UPDATE_EMPLOYEE,
    payload: employee,
  }
}

export function deleteEmployee(employee): IEmployeeActions {
  return {
    type: DELETE_EMPLOYEE,
    payload: employee,
  }
}

const fetchAllEmployeesRequest = () => {
  return {
    type: FETCH_EMPLOYEES_ALL_REQUEST,
  }
}

const fetchAllEmployeesSuccess = (employeesAll, count) => {
  return {
    type: FETCH_EMPLOYEES_ALL_SUCCESS,
    payload: { employeesAll, count },
  }
}

const fetchAllEmployeesFailure = (message, code) => {
  return {
    type: FETCH_EMPLOYEES_ALL_FAILURE,
    payload: { message, code },
  }
}

const fetchMentorEmployeesRequest = () => {
  return {
    type: FETCH_EMPLOYEES_MENTOR_REQUEST,
  }
}

const fetchMentorEmployeesSuccess = (employeesMentor, count) => {
  return {
    type: FETCH_EMPLOYEES_MENTOR_SUCCESS,
    payload: { employeesMentor, count },
  }
}

const fetchMentorEmployeesFailure = (message, code) => {
  return {
    type: FETCH_EMPLOYEES_MENTOR_FAILURE,
    payload: { message, code },
  }
}

const fetchEmployeeCleanup = () => {
  return {
    type: FETCH_EMPLOYEE_CLEANUP,
  }
}

const fetchEmployeeRequest = () => {
  return {
    type: FETCH_EMPLOYEE_REQUEST,
  }
}

export const fetchEmployeeSuccess = (employee) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: employee,
  }
}

export const fetchEmployeeFailure = (message, code) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    payload: { message, code },
  }
}

export const fetchEmployeeUserSuccess = (employeeUser) => {
  return {
    type: FETCH_EMPLOYEE_USER_SUCCESS,
    payload: employeeUser,
  }
}

export const fetchEmployeeMvdSuccess = (employeeMvd) => {
  return {
    type: FETCH_EMPLOYEE_MVD_SUCCESS,
    payload: employeeMvd,
  }
}

export const updateEmployeeType = (type: EmployeeType) => {
  return {
    type: UPDATE_EMPLOYEE_TYPE,
    payload: type,
  }
}

export const updateEmployeeShowPassport = (value: boolean) => {
  return {
    type: UPDATE_EMPLOYEE_SHOW_PASSPORT,
    payload: value,
  }
}
