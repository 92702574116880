import { IPathParams } from '@app/constants/path-params'
import { IEducationGroup } from '@app/interfaces/education/education-groups'
import { IEmployee } from '@app/interfaces/employee/employee'
import { IOrganizationPosition } from '@app/interfaces/employee/organization-position'
import { IOrganizationPassport } from '@app/interfaces/organization-passport/organization-passport'
import { IOrganizationPupils } from '@app/interfaces/pupil/organization-pupils'
import { IPupil } from '@app/interfaces/pupil/pupils'
import { IUserGroupRoles } from '@app/interfaces/roles'
import { IUser } from '@app/interfaces/users'
import { read } from '@app/store/actions/form-state'
import { useGetOrganizationQuery } from '@app/store/rtk/api/organization/organization-api'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import './breadcrumbs.less'
import { ROUTE_NAMES } from '@app/routes'

interface props {
  role?: IUserGroupRoles
  pupilAll?: IPupil
  _user?: IUser
  _organizationPosition?: IOrganizationPosition
  _employee?: IEmployee
  _pupil?: IOrganizationPupils
  _pupils?: IOrganizationPupils[]
  _organizationPassport?: IOrganizationPassport
  _educationGroup?: IEducationGroup
}

const Breadcrumbs: React.FC<props> = ({
  pupilAll,
  role,
  _user,
  _organizationPosition,
  _employee,
  _pupil,
  _pupils,
  _organizationPassport,
  _educationGroup,
}) => {
  const params: IPathParams = useParams()
  const { data: organization, isSuccess } = useGetOrganizationQuery(
    Number(params?.id),
    { skip: !params?.id },
  )
  const { t } = useTranslation()
  const routes = [
    {
      path: 'indicators',
      breadcrumb: t('routes.indicators'),
    },
    {
      path: 'profile',
      breadcrumb: t('routes.profile'),
    },
    {
      path: 'accept',
      breadcrumb: t('routes.accept'),
    },
    {
      path: 'pupils',
      breadcrumb: t('routes.pupils'),
    },
    {
      path: 'pupils/:id',
      breadcrumb: `${t('routes.pupil')} : ${pupilAll?.fullName || ''}`,
    },
    {
      path: 'employees',
      breadcrumb: t('routes.employees'),
    },
    // {
    //   path: 'grows',
    //   breadcrumb: t('routes.grows'),
    // },
    // {
    //   path: 'attendance',
    //   breadcrumb: t('routes.attendance'),
    // },
    {
      path: 'general',
      breadcrumb: t('routes.general'),
    },
    // {
    //   path: 'passportization',
    //   breadcrumb: t('routes.passportization'),
    // },
    {
      path: 'groups',
      breadcrumb: t('routes.groups'),
    },
    {
      path: 'notifications',
      breadcrumb: t('routes.notifications'),
    },
    {
      path: 'reports',
      breadcrumb: t('routes.reports'),
    },
    {
      path: 'search',
      breadcrumb: t('routes.search'),
    },
    {
      path: 'settings/reference_book',
      breadcrumb: t('routes.references'),
    },
    {
      path: 'settings/reference_book/regions',
      breadcrumb: t('routes.reference_regions'),
    },
    {
      path: 'settings/reference_book/languages',
      breadcrumb: t('routes.reference_languages'),
    },
    {
      path: 'settings/reference_book/districts',
      breadcrumb: t('routes.reference_districts'),
    },
    {
      path: 'settings/reference_book/institutions-types',
      breadcrumb: t('routes.reference_doo-types'),
    },
    {
      path: 'settings/reference_book/ownerships',
      breadcrumb: t('routes.reference_ownerships'),
    },
    {
      path: 'settings/reference_book/stay-duration',
      breadcrumb: t('routes.reference_stay-duration'),
    },
    {
      path: 'settings/reference_book/medical-staff',
      breadcrumb: t('routes.reference_medical-staff'),
    },
    {
      path: 'settings/reference_book/water-sources',
      breadcrumb: t('routes.reference_water-sources'),
    },
    {
      path: 'settings/reference_book/drinking-water-available-during-year',
      breadcrumb: t('routes.reference_drinking-water-available-during-year'),
    },
    {
      path: 'settings/reference_book/water-cleaning-methods',
      breadcrumb: t('routes.reference_water-cleaning-methods'),
    },
    {
      path: 'settings/reference_book/toilet-types',
      breadcrumb: t('routes.reference_toilet-types'),
    },
    {
      path: 'settings/reference_book/toilet-locations',
      breadcrumb: t('routes.reference_toilet-locations'),
    },
    {
      path: 'settings/reference_book/toilet-cleanings',
      breadcrumb: t('routes.reference_toilet-cleanings'),
    },
    {
      path: 'settings/reference_book/solid-waste-removings',
      breadcrumb: t('routes.reference_solid-waste-removings'),
    },
    {
      path: 'settings/reference_book/hot-water-sources',
      breadcrumb: t('routes.reference_hot-water-sources'),
    },
    {
      path: 'settings/reference_book/heating-system-sources',
      breadcrumb: t('routes.reference_heating-system-sources'),
    },
    {
      path: 'settings/reference_book/electricitys',
      breadcrumb: t('routes.reference_electricitys'),
    },
    {
      path: 'settings/reference_book/food-preparing-places',
      breadcrumb: t('routes.reference_food-preparing-places'),
    },
    {
      path: 'settings/reference_book/institution-schedules',
      breadcrumb: t('institutionSchedule.title'),
    },
    {
      path: 'settings/reference_book/organization-statuses',
      breadcrumb: t('organizationStatus.title'),
    },
    {
      path: 'settings/reference_book/organization-locality',
      breadcrumb: t('organizationLocality.title'),
    },
    {
      path: 'settings/reference_book/employee-rates',
      breadcrumb: t('employeeRate.title'),
    },
    {
      path: 'settings/reference_book/employee-statuses',
      breadcrumb: t('employeeStatus.title'),
    },
    {
      path: 'settings/reference_book/employee-educations',
      breadcrumb: t('employeeEducation.title'),
    },
    {
      path: 'settings/reference_book/education-grades',
      breadcrumb: t('educationGrade.title'),
    },
    {
      path: 'settings/reference_book/education-languages',
      breadcrumb: t('educationLanguage.title'),
    },
    {
      path: 'settings/reference_book/employee-positions',
      breadcrumb: t('employeePosition.title'),
    },
    {
      path: 'settings/reference_book/organization_types',
      breadcrumb: t('organizationType.title'),
    },
    {
      path: 'settings/reference_book/nationalities',
      breadcrumb: t('nationality.title'),
    },
    {
      path: 'settings/reference_book/holidays',
      breadcrumb: t('holiday.title'),
    },
    {
      path: 'settings/reference_book/reasons',
      breadcrumb: t('reason.title'),
    },
    {
      path: 'settings/reference_book/education_degree',
      breadcrumb: t('educationDegree.title'),
    },
    {
      path: 'manage-users/roles',
      breadcrumb: t('routes.manage-users__roles'),
    },
    {
      path: 'manage-users/roles/add',
      breadcrumb: `${t('userRoles.addRole')}`,
    },
    {
      path: 'manage-users/roles/:id/:edit',
      breadcrumb: `${t('userRoles.editRole')}: ${role?.id || ''}`,
    },
    {
      path: 'manage-users/users',
      breadcrumb: t('routes.users'),
    },
    {
      path: 'manage-users/users/user/:id',
      breadcrumb: `${t('users.user')}: ${_user?.lastName ?? ''} ${
        _user?.firstName ?? ''
      } ${_user?.middleName ?? ''}`,
    },
    {
      path: 'manage-users/users/add',
      breadcrumb: t('users.addUser'),
    },
    {
      path: 'manage-users/users/user/:id/:edit',
      breadcrumb: `${t('users.editUser')}: ${_user?.lastName ?? ''} ${
        _user?.firstName ?? ''
      } ${_user?.middleName ?? ''}`,
    },
    {
      path: 'organizations',
      breadcrumb: t('organization.title'),
    },
    {
      path: 'institution/:addInstitution',
      breadcrumb: t('organization.addInstitution'),
    },
    {
      path: 'gov-agency/:addGov',
      breadcrumb: t('organization.addGovAgency'),
    },

    {
      path: 'organization/:id/contacts',
      breadcrumb: t('organizationContacts.title'),
    },
    {
      path: 'organization/:id/passport',
      breadcrumb: t('institutionPassport.title'),
    },
    {
      path: 'organization/:id/workforce',
      breadcrumb: t('employeeWorkRateList.title'),
    },
    {
      path: 'organization/:id/employees',
      breadcrumb: t('employee.title'),
    },
    {
      path: 'organization/:id/mobile-users',
      breadcrumb: t('mobileUsers.title'),
    },
    {
      path: 'organization/:id/employees-positions',
      breadcrumb: t('employeePosition.title'),
    },
    {
      path: 'organization/:id/groups',
      breadcrumb: t('educationGroup.title'),
    },
    {
      path: 'organization/:id/groups/:groupId',
      breadcrumb: `${t('educationGroup.educationGroup')}: ${
        _educationGroup?.name || ''
      }`,
    },
    {
      path: 'organization/:id/pupils',
      breadcrumb: t('pupils.title'),
    },
    {
      path: 'organization/:id/pupils-queue',
      breadcrumb: t('pupils.accept'),
    },
    {
      path: 'organization/:id/contract-subsidy',
      breadcrumb: t('contractSubsidy.title'),
    },
    {
      path: 'organization/:id/queue-list',
      breadcrumb: t('pupils.queueList.title'),
    },
    {
      path: 'organization/:id/page',
      breadcrumb: `${t('organization.organization')}: ${
        isSuccess ? organization?.name : ''
      }`,
    },
    {
      path: 'organization/:id/employees-positions/:posId/assign-employee',
      breadcrumb: `${t('employeePosition.assignAnEmployeeToPosition')} ${
        _organizationPosition?.id || ''
      }`,
    },
    {
      path: 'organization/:id/edit',
      breadcrumb: `${t('organization.edit')}`,
    },
    {
      path: 'organization/:id/pupils/manual-add',
      breadcrumb: `${t('pupils.adding')}`,
    },
    {
      path: 'organization/:id/employees/employee/:employeeId',
      breadcrumb: `${t('employee.employee')}: ${_employee?.lastName ?? ''} ${
        _employee?.firstName ?? ''
      } ${_employee?.middleName ?? ''}`,
    },
    {
      path: 'organization/:id/cameras',
      breadcrumb: t('cameras.title'),
    },
    {
      path: `organization/:id/${ROUTE_NAMES.child_stats}`,
      breadcrumb: t('routes.childStats'),
    },
    {
      path: 'organization/:id/employees/employee/:employeeId/edit',
      breadcrumb: `${t('employee.edit')}: ${_employee?.lastName ?? ''} ${
        _employee?.firstName ?? ''
      } ${_employee?.middleName ?? ''}`,
    },
    {
      path: 'organization/:id/pupils/pupil/:pupilId',
      breadcrumb: `${t('pupils.pupil')}: ${
        _pupil?.pupil?.fullName ??
        _pupils?.find((pupil) => pupil.pupilId === _pupil?.pupilId)?.pupil
          .fullName ??
        ''
      }`,
    },
    {
      path: 'organization/:id/passport/add',
      breadcrumb: t('institutionPassport.add'),
    },
    {
      path: 'organization/:id/passport/:passportId/:edit',
      breadcrumb: `${t('institutionPassport.edit')}: ${
        _organizationPassport?.id || ''
      }`,
    },
    {
      path: 'organization/:id/contracts',
      breadcrumb: t('routes.contracts'),
    },
    {
      path: 'organization/:id/contracts/add',
      breadcrumb: t('contracts.add'),
    },
    {
      path: 'organization/:id/menu/menu-select',
      breadcrumb: t('routes.menu'),
    },
    {
      path: 'organization/:id/attendance',
      breadcrumb: t('routes.attendance'),
    },
    {
      path: 'organization/:id/timesheet',
      breadcrumb: t('routes.timesheet'),
    },
    {
      path: 'organization/:id/menu/menu-organizer',
      breadcrumb: t('routes.menu'),
    },
    {
      path: 'organization/:id/warehouse',
      breadcrumb: t('routes.warehouse'),
    },
    {
      path: 'organization/:id/rooms',
      breadcrumb: t('routes.rooms'),
    },
    {
      path: 'organization/:id/growth',
      breadcrumb: t('routes.growth'),
    },
    {
      path: 'growth-statistics',
      breadcrumb: t('routes.growthStatistics'),
    },
    {
      path: 'furniture',
      breadcrumb: t('routes.furniture'),
    },
    {
      path: 'admin',
      breadcrumb: t('routes.admin'),
    },
    {
      path: 'admin/pupil-reject',
      breadcrumb: t('routes.rejectPupil'),
    },
    {
      path: 'wizard',
      breadcrumb: t('routes.wizard'),
    },
    {
      path: 'sitemap',
      breadcrumb: t('routes.sitemap'),
    },
  ]

  const dispatch = useDispatch()

  const resetFunction = () => {
    dispatch(read())
  }

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true })

  console.log('breadcrumbs',breadcrumbs)

  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        return (
          <div
            onClick={resetFunction}
            key={match.pathname}
            className="breadcrumbs__item"
          >
            {index === 0 &&
              !match.pathname.includes(
                `/organization/${organization?.id}/page`,
              ) &&
              match.pathname.includes('/organization/') && (
                <NavLink
                  className={`breadcrumbs__item--title`}
                  to={`organization/${organization?.id}/page`}
                >
                  {`${t('organization.organization')}: ${
                    !isEmpty(organization) ? organization?.name : ''
                  }`}
                </NavLink>
              )}
            <NavLink className={`breadcrumbs__item--title`} to={match.pathname}>
              {breadcrumb}
            </NavLink>
          </div>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    _user: state.users.user,
    _organizationPosition: state.organizationPositions.organizationPosition,
    _employee: state.organizationEmployee.employee,
    _pupil: state.organizationPupils.pupil,
    _pupils: state.organizationPupils.pupils,
    _organizationPassport: state.organizationPassport.institution_passport,
    _educationGroup: state.educationGroups.educationGroup,
    role: state.roles.rolesGroup,
    pupilAll: state.pupils.pupil,
  }
}

export default connect(mapStateToProps)(Breadcrumbs)
