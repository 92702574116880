import { useTranslation } from 'react-i18next'
import { Tabs, TabsProps } from 'antd'
import { useMemo } from 'react'
import OrganizationGrowth from '@app/pages/organizations/components/organization-growth/organization-growth'
import ChildDevelopment from '@app/pages/child-stats/components/child-development'
import DocumentTitle from '@app/layout/components/document-title'
import { AUTHORITIES } from '@app/constants/authorities'
import { rolesChecker } from '@app/utils/roles-checker/rolesChecker'

const TABS = [
  {
    key: '1',
    label: 'growth.title',
    Component: OrganizationGrowth,
    roles: [
      AUTHORITIES.ADMIN,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_ALL_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_REGION_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_DISTRICT_VIEW,
      AUTHORITIES.ROLE_HEIGHT_WEIGHT_CURRENT_VIEW,
    ],
  },
  {
    key: '2',
    label: 'pageTitle.readinessMap',
    Component: ChildDevelopment,
    roles: true,
  },
]

const ChildStats = () => {
  const [t] = useTranslation()
  const items: TabsProps['items'] = useMemo(
    () =>
      TABS.filter(({ roles }) =>
        Array.isArray(roles) ? rolesChecker(roles) : true,
      ).map(({ Component, key, label }) => ({
        key,
        children: <Component />,
        label: t(label),
      })),
    [t],
  )

  return (
    <>
      <DocumentTitle headerName="routes.childStats" />
      <Tabs size="large" items={items} />
    </>
  )
}

export default ChildStats
