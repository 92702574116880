import useLocalization from '@app/hooks/useLocalization'
import { Card } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './manual.less'

const Manual: React.FC = () => {
  const [t] = useTranslation()
  const [tutorials, setTutorials] = useState<any>([])
  const { localizeStrapi } = useLocalization()

  useEffect(() => {
    axios
      .get(
        'https://strapi.bbit.uz/api/tutorials?populate=linkYoutube&locale=all',
      )
      .then((res) => setTutorials(localizeStrapi(res.data.data)))
  }, [t])

  return (
    <div className="tutorials">
      {tutorials.map((tutorial, index) => {
        return (
          <Card key={index} className="tutorial">
            <p className="tutorial__title">{tutorial.attributes.title}</p>
            <p className="tutorial__description">
              {tutorial.attributes.description}
            </p>
            <div className="tutorial__video">
              <iframe
                allowFullScreen={true}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                id="player"
                src={tutorial.attributes.link_youtube}
                frameBorder="0"
              ></iframe>
            </div>
          </Card>
        )
      })}
    </div>
  )
}

export default Manual
