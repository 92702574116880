import { IEmployeePosition } from '@app/interfaces/employee/employee-position'
import { IData } from '@app/interfaces/index'
import { EmployeeType } from '@app/store/reducers/employee-reducer'
import { IEducationDegree } from '../education/education-degree'
import { IMvdUserV2 } from '../mvd-user'
import { IUser } from '../users'
import { IEmployeeRate } from './employee-rate'
import { IEmployeeRetraining } from './employee-retraining'
import { IEmployeeStatus } from './employee-status'

export interface IEmployee {
  id: number
  firstName: string
  lastName: string
  middleName: string
  email: string
  phone: string
  address: string
  tin: string
  pin: string
  passportNumber: string
  fileId: number | null
  gender: string
  education?: EmployeeEducation | null | undefined
  degreeEducation: IEducationDegree
  employeePositions?: IEmployeeOrganizationPosition[]
  organizationPositionId: number
  orderNo: string
  orderDate: string
  securityUserId: number
}

export interface IOrganizationEmployee {
  employee: IEmployee
  id: number
  organizationPosition: IEmployeeOrganizationPosition
  position: IEmployeePosition
  rate: IEmployeeRate
  status: IEmployeeStatus
}

export interface IEmployeeOrganizationPosition {
  id: number
  organizationId: number
  regionId: number
  districtId: number
  organizationName: string
}

export interface IEmployeeChangeStatus {
  employeeId: number
  employeePositionId: number
  orderNo: string
}

export interface IEmployeeChangeRate extends IEmployeeChangeStatus {
  employeeRateDictionaryId: number
}

export interface IEmployeeChangeStatusWithDate extends IEmployeeChangeStatus {
  startDate: string
  endDate: string
}

export interface IGetOneEmployee {
  id: number
  firstName: string
  lastName: string
  middleName: string
  email: string
  phone: string
  address: string
  tin: string
  pin: string
  passportNumber: string
  gender: string
  userId: number
  education: EmployeeEducation | null | undefined
  retraining: IEmployeeRetraining[]
  fileId: number | null
  employeePositions: [
    {
      id: number
      organizationPosition: {
        id: number
        organizationId: number
        organizationName: string
      }
      position: {
        id: number
        data: IData[]
      }
      rate: {
        id: number
        data: IData[]
      }
      status: {
        id: number
        data: IData[]
        type: string
      }
    },
  ]
}

export interface IEmployeeCount {
  total: number
  male: number
  female: number
}

export interface IEmployeeCreate {
  id: null
  firstName: string
  lastName: string
  middleName: string
  email: string
  phone: string
  address: string
  tin: string
  pin: string
  passportNumber: string
  gender: string
  education: EmployeeEducation | null | undefined
  organizationPositionId: number
  orderNo: string
  orderDate: string
}

export interface IEmployeeAppointment {
  employeeId: number
  organizationPositionId: number
  orderNo: string
  orderDate: string
}

export interface IEmployeeState {
  employeesMentor: IEmployee[]
  employeesAll: IEmployee[]
  employee: IEmployee
  employeeUser: IUser
  employeeMvd: IMvdUserV2
  refresh: boolean
  employeesCount: IEmployeeCount
  state: string
  message: string
  code: number
  count: string
  employeeType: EmployeeType
  showPassport: boolean
}

export interface IEmployeeActions {
  type: string
  payload?: any
}

export class EmployeeEducation {
  public placeStudy: string | undefined
  public specialty: string | undefined
  public educationDictionary: EducationDictionary | undefined | null
}

export class EducationDictionary {
  public id: number | undefined | null

  constructor(id: number) {
    this.id = id
  }
}

export interface IGetEmployeeInfo {
  pin: string
  birthDate: string
}
