import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { IPathParams } from '@app/constants/path-params'
import { LOADING } from '@app/constants/redux-state'
import usePagination from '@app/hooks/usePagination'
import { IOrganizationMobileUser } from '@app/interfaces/organization-mobile-users'
import Pagination from '@app/shared/pagination/pagination'
import {
  clearOrganizationMobileUsers,
  getOrganizationMobileUsers,
} from '@app/store/actions/organization-mobile-users-actions'
import { setDocumentTitle } from '@app/utils/set-document-title/set-document-title'
import { Button, Card, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AddMobileUser } from './components/add-mobile-user/add-mobile-user'
import { AlertRemoveMobileUser } from './components/alert-remove-mobile-user/alert-remove-mobile-user'
import { UpdateMobileUser } from './components/update-mobile-user/update-mobile-user'
import { UpdatePasswordMobileUser } from './components/update-password-mobile-user/update-password-mobile-user'

const OrganizationMobileUsersTable: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params: IPathParams = useParams()
  const { mobileUsers, state, count } = useSelector(
    (state: any) => state.mobileUsers,
  )
  const currentOrganizationId = params.id

  const { searchParams, requestFunction, handleTableChange } = usePagination(
    `page=0&size=10&organizationId=${currentOrganizationId}`,
    {
      page: 1,
      size: 10,
    },
  )

  console.log(searchParams)

  const [addModalVisible, setAddModalVisible] = useState(false)
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)
  const [removeModalVisible, setRemoveModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] =
    useState<IOrganizationMobileUser | null>(null)

  setDocumentTitle(t('mobileUsers.title'))

  useEffect(() => {
    const abortController = new AbortController()
    dispatch(getOrganizationMobileUsers(searchParams, abortController.signal))
    return () => {
      dispatch(clearOrganizationMobileUsers())
      abortController.abort()
    }
  }, [searchParams, dispatch])

  const columns = [
    {
      title: t('mobileUsers.fullName'),
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: t('mobileUsers.login'),
      dataIndex: 'username',
      key: 'login',
    },
    {
      title: t('general.action'),
      key: 'actions',
      render: (_, record: IOrganizationMobileUser) => (
        <Space size="middle">
          <Tooltip title={t('general.edit')} placement="bottom">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedUser(record)
                setUpdateModalVisible(true)
              }}
            />
          </Tooltip>
          <Tooltip title={t('mobileUsers.changePassword')} placement="bottom">
            <Button
              type="text"
              icon={<LockOutlined />}
              onClick={() => {
                setSelectedUser(record)
                setPasswordModalVisible(true)
              }}
            />
          </Tooltip>
          <Tooltip title={t('mobileUsers.deleteUser')} placement="bottom">
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setSelectedUser(record)
                setRemoveModalVisible(true)
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Card style={{ padding: 20, marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => setAddModalVisible(true)}>
          {t('general.add')}
        </Button>
      </Card>

      <Card title={t('mobileUsers.title')} style={{ paddingBottom: 30 }}>
        <Table
          pagination={false}
          columns={columns}
          loading={state === LOADING}
          dataSource={mobileUsers}
          rowKey="id"
          rowClassName="table-row"
          onChange={handleTableChange}
          footer={() => (
            <Pagination
              request={requestFunction}
              totalCount={count}
              searchParams={searchParams}
            />
          )}
        />
      </Card>

      <AddMobileUser
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        currentOrganizationId={Number(currentOrganizationId)}
      />

      {selectedUser && (
        <>
          <UpdateMobileUser
            visible={updateModalVisible}
            onCancel={() => {
              setUpdateModalVisible(false)
              setSelectedUser(null)
            }}
            user={selectedUser}
          />

          <UpdatePasswordMobileUser
            visible={passwordModalVisible}
            onCancel={() => {
              setPasswordModalVisible(false)
              setSelectedUser(null)
            }}
            userId={selectedUser.id}
          />

          <AlertRemoveMobileUser
            visible={removeModalVisible}
            onCancel={() => {
              setRemoveModalVisible(false)
              setSelectedUser(null)
            }}
            userId={selectedUser.id}
          />
        </>
      )}
    </>
  )
}

export default OrganizationMobileUsersTable
